import { assert, isDefined } from '@rhim/utils';
import { useMemo } from 'react';

import { MeasurementTuple } from './useMeasurementSequenceTuples';

export function useMeasurementsTonnageKilograms(measurementTuples: MeasurementTuple[]) {
  const tonnages: number[] = useMemo(() => {
    const ret: number[] = [];
    for (const measurementTuple of measurementTuples) {
      if (isDefined(measurementTuple.workingLiningMeasurement)) {
        ret.push(measurementTuple.workingLiningMeasurement.tonnage);
      } else {
        assert(
          isDefined(measurementTuple.afterRepairMeasurement),
          `Measurement tuple has null values for both workingLiningMeasurement and afterRepairMeasurement.`
        );
        ret.push(measurementTuple.afterRepairMeasurement.tonnage);
      }
    }
    return ret;
  }, [measurementTuples]);

  return tonnages;
}
