import { VesselType } from '@rhim/rest';
import { useMeasurementviewSettings } from '@rhim/sdk/measurementService';
import { isDefined } from '@rhim/utils';
import { useContext, useMemo } from 'react';

import AppContext from '../../../app/AppContext';
import { useSelectedVessel } from '../../../hooks/useSelectedVessel';
import { SIDE_0_LINE_COLOR, SIDE_1_LINE_COLOR } from '../components/constants';
import { GraphLegendProps } from '../components/GraphLegend';

export function useSidesLegends() {
  const { selectedCustomer, vessels } = useContext(AppContext);
  const { selectedVesselId } = useSelectedVessel(vessels);

  const customerId = selectedCustomer.customerId as UUID;
  const { data: measurementViewSettings } = useMeasurementviewSettings(customerId, VesselType.Bfr, selectedVesselId, { configuration: { suspense: false } });

  const sidesLegends: undefined | GraphLegendProps[] = useMemo(() => {
    if (!isDefined(measurementViewSettings)) {
      return undefined;
    }
    return [
      {
        label: measurementViewSettings.side0DisplayName,
        color: SIDE_0_LINE_COLOR,
        isSolidLine: true,
      },
      {
        label: measurementViewSettings.side1DisplayName,
        color: SIDE_1_LINE_COLOR,
        isSolidLine: true,
      },
    ];
  }, [measurementViewSettings]);

  return sidesLegends;
}
