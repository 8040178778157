import { scaleLinear } from '@visx/scale';
import { useMemo } from 'react';

export const Y_AXIS_TICK_COUNT_HINT = 5;

export function useScaleY(graphAreaHeight: number, domainMin: number, domainMax: number) {
  const yScale = useMemo(() => {
    return scaleLinear({
      range: [graphAreaHeight, 0],
      domain: [domainMin, domainMax],
    }).nice(Y_AXIS_TICK_COUNT_HINT);
  }, [graphAreaHeight, domainMin, domainMax]);

  return yScale;
}
