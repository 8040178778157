/* tslint:disable */
/* eslint-disable */
/**
 * Fleet Overview API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * @type GetMeasurementsequenceMeasurementsVesselidCampaign200ResponseInner
 * @export
 */
export type GetMeasurementsequenceMeasurementsVesselidCampaign200ResponseInner = RHIMFleetOverviewServiceV1ControllersSequenceMeasurementDto | RHIMFleetOverviewServiceV1ControllersSequenceMeasurementReportDto;

/**
 * 
 * @export
 * @interface MicrosoftPowerBIApiModelsEmbedToken
 */
export interface MicrosoftPowerBIApiModelsEmbedToken {
    /**
     * 
     * @type {string}
     * @memberof MicrosoftPowerBIApiModelsEmbedToken
     */
    'token'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MicrosoftPowerBIApiModelsEmbedToken
     */
    'tokenId': string;
    /**
     * 
     * @type {string}
     * @memberof MicrosoftPowerBIApiModelsEmbedToken
     */
    'expiration': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const RHIMContractsFileType = {
    PostMortem: 'PostMortem',
    ProcessData: 'ProcessData',
    ProcessMetrixFiltered: 'ProcessMetrixFiltered',
    ProcessMetrixRaw: 'ProcessMetrixRaw',
    ProcessMetrixVertex: 'ProcessMetrixVertex',
    ProcessMetrixSummary: 'ProcessMetrixSummary',
    ProcessMetrixZip: 'ProcessMetrixZip',
    Stl: 'STL',
    HarmonizedSummary: 'HarmonizedSummary',
    ProcessDataSplit: 'ProcessDataSplit',
    Pts: 'PTS',
    Clinker: 'Clinker',
    KilnFeed: 'KilnFeed',
    HotMeal: 'HotMeal',
    Lignite: 'Lignite',
    StopLogs: 'StopLogs',
    IrScan: 'IRScan',
    KilnShellCooling: 'KilnShellCooling',
    KilnProcessData: 'KilnProcessData',
    FerrotronSummary: 'FerrotronSummary'
} as const;

export type RHIMContractsFileType = typeof RHIMContractsFileType[keyof typeof RHIMContractsFileType];


/**
 * 
 * @export
 * @enum {string}
 */

export const RHIMContractsVesselType = {
    Bof: 'BOF',
    Rh: 'RH',
    Eaf: 'EAF',
    Ladle: 'Ladle',
    Crk: 'CRK',
    Rhl: 'RHL',
    Aod: 'AOD',
    Bfr: 'BFR',
    Srf: 'SRF',
    Cou: 'COU',
    Hml: 'HML'
} as const;

export type RHIMContractsVesselType = typeof RHIMContractsVesselType[keyof typeof RHIMContractsVesselType];


/**
 * 
 * @export
 * @interface RHIMFleetOverviewServiceDataEmbedReport
 */
export interface RHIMFleetOverviewServiceDataEmbedReport {
    /**
     * Id of report to be embedded
     * @type {string}
     * @memberof RHIMFleetOverviewServiceDataEmbedReport
     */
    'reportId': string;
    /**
     * Name of the report
     * @type {string}
     * @memberof RHIMFleetOverviewServiceDataEmbedReport
     */
    'reportName': string;
    /**
     * Embed URL for the report
     * @type {string}
     * @memberof RHIMFleetOverviewServiceDataEmbedReport
     */
    'embedUrl': string;
}
/**
 * 
 * @export
 * @interface RHIMFleetOverviewServiceV1ControllersMeasurementSequenceDto
 */
export interface RHIMFleetOverviewServiceV1ControllersMeasurementSequenceDto {
    /**
     * 
     * @type {number}
     * @memberof RHIMFleetOverviewServiceV1ControllersMeasurementSequenceDto
     */
    'campaign': number;
    /**
     * 
     * @type {RHIMFleetOverviewServiceV1ControllersSequenceMeasurementReportDto}
     * @memberof RHIMFleetOverviewServiceV1ControllersMeasurementSequenceDto
     */
    'permanentLiningMeasurement': RHIMFleetOverviewServiceV1ControllersSequenceMeasurementReportDto | null;
    /**
     * 
     * @type {RHIMFleetOverviewServiceV1ControllersSequenceMeasurementReportDto}
     * @memberof RHIMFleetOverviewServiceV1ControllersMeasurementSequenceDto
     */
    'wearLiningInitialMeasurement': RHIMFleetOverviewServiceV1ControllersSequenceMeasurementReportDto | null;
    /**
     * 
     * @type {Array<RHIMFleetOverviewServiceV1ControllersMeasurementSequenceTupleDto>}
     * @memberof RHIMFleetOverviewServiceV1ControllersMeasurementSequenceDto
     */
    'measurementTuples': Array<RHIMFleetOverviewServiceV1ControllersMeasurementSequenceTupleDto>;
}
/**
 * 
 * @export
 * @interface RHIMFleetOverviewServiceV1ControllersMeasurementSequenceTupleDto
 */
export interface RHIMFleetOverviewServiceV1ControllersMeasurementSequenceTupleDto {
    /**
     * 
     * @type {number}
     * @memberof RHIMFleetOverviewServiceV1ControllersMeasurementSequenceTupleDto
     */
    'tonnage': number;
    /**
     * 
     * @type {RHIMFleetOverviewServiceV1ControllersSequenceMeasurementReportDto}
     * @memberof RHIMFleetOverviewServiceV1ControllersMeasurementSequenceTupleDto
     */
    'workingLiningMeasurement': RHIMFleetOverviewServiceV1ControllersSequenceMeasurementReportDto | null;
    /**
     * 
     * @type {RHIMFleetOverviewServiceV1ControllersSequenceMeasurementReportDto}
     * @memberof RHIMFleetOverviewServiceV1ControllersMeasurementSequenceTupleDto
     */
    'afterRepairMeasurement': RHIMFleetOverviewServiceV1ControllersSequenceMeasurementReportDto | null;
    /**
     * 
     * @type {string}
     * @memberof RHIMFleetOverviewServiceV1ControllersMeasurementSequenceTupleDto
     */
    'repairMixMaterialId': string | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMFleetOverviewServiceV1ControllersMeasurementSequenceTupleDto
     */
    'repairMixAmount': number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMFleetOverviewServiceV1ControllersMeasurementSequenceTupleDto
     */
    'repairMixVolume': number | null;
}
/**
 * 
 * @export
 * @interface RHIMFleetOverviewServiceV1ControllersSequenceMeasurementDto
 */
export interface RHIMFleetOverviewServiceV1ControllersSequenceMeasurementDto {
    /**
     * 
     * @type {string}
     * @memberof RHIMFleetOverviewServiceV1ControllersSequenceMeasurementDto
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof RHIMFleetOverviewServiceV1ControllersSequenceMeasurementDto
     */
    'campaign': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMFleetOverviewServiceV1ControllersSequenceMeasurementDto
     */
    'tonnage': number;
    /**
     * 
     * @type {string}
     * @memberof RHIMFleetOverviewServiceV1ControllersSequenceMeasurementDto
     */
    'measurementTaken': string;
    /**
     * 
     * @type {string}
     * @memberof RHIMFleetOverviewServiceV1ControllersSequenceMeasurementDto
     */
    'vesselLining': string;
    /**
     * 
     * @type {boolean}
     * @memberof RHIMFleetOverviewServiceV1ControllersSequenceMeasurementDto
     */
    'isHotMeasurement': boolean;
    /**
     * 
     * @type {string}
     * @memberof RHIMFleetOverviewServiceV1ControllersSequenceMeasurementDto
     */
    'liningCondition': string;
    /**
     * 
     * @type {string}
     * @memberof RHIMFleetOverviewServiceV1ControllersSequenceMeasurementDto
     */
    'liningMaintenanceType': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RHIMFleetOverviewServiceV1ControllersSequenceMeasurementDto
     */
    'emptyVesselMeasurement': boolean;
}
/**
 * 
 * @export
 * @interface RHIMFleetOverviewServiceV1ControllersSequenceMeasurementReportDto
 */
export interface RHIMFleetOverviewServiceV1ControllersSequenceMeasurementReportDto {
    /**
     * 
     * @type {number}
     * @memberof RHIMFleetOverviewServiceV1ControllersSequenceMeasurementReportDto
     */
    'side0SlaglineWear': number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMFleetOverviewServiceV1ControllersSequenceMeasurementReportDto
     */
    'side0SlaglineWearRate': number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMFleetOverviewServiceV1ControllersSequenceMeasurementReportDto
     */
    'side1SlaglineWear': number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMFleetOverviewServiceV1ControllersSequenceMeasurementReportDto
     */
    'side1SlaglineWearRate': number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMFleetOverviewServiceV1ControllersSequenceMeasurementReportDto
     */
    'side0IronlineWear': number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMFleetOverviewServiceV1ControllersSequenceMeasurementReportDto
     */
    'side0IronlineWearRate': number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMFleetOverviewServiceV1ControllersSequenceMeasurementReportDto
     */
    'side1IronlineWear': number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMFleetOverviewServiceV1ControllersSequenceMeasurementReportDto
     */
    'side1IronlineWearRate': number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMFleetOverviewServiceV1ControllersSequenceMeasurementReportDto
     */
    'side0SlaglineRbl': number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMFleetOverviewServiceV1ControllersSequenceMeasurementReportDto
     */
    'side1SlaglineRbl': number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMFleetOverviewServiceV1ControllersSequenceMeasurementReportDto
     */
    'side0IronlineRbl': number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMFleetOverviewServiceV1ControllersSequenceMeasurementReportDto
     */
    'side1IronlineRbl': number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMFleetOverviewServiceV1ControllersSequenceMeasurementReportDto
     */
    'volume': number | null;
    /**
     * 
     * @type {number}
     * @memberof RHIMFleetOverviewServiceV1ControllersSequenceMeasurementReportDto
     */
    'volumeChangeRate': number | null;
    /**
     * 
     * @type {string}
     * @memberof RHIMFleetOverviewServiceV1ControllersSequenceMeasurementReportDto
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof RHIMFleetOverviewServiceV1ControllersSequenceMeasurementReportDto
     */
    'campaign': number;
    /**
     * 
     * @type {number}
     * @memberof RHIMFleetOverviewServiceV1ControllersSequenceMeasurementReportDto
     */
    'tonnage': number;
    /**
     * 
     * @type {string}
     * @memberof RHIMFleetOverviewServiceV1ControllersSequenceMeasurementReportDto
     */
    'measurementTaken': string;
    /**
     * 
     * @type {string}
     * @memberof RHIMFleetOverviewServiceV1ControllersSequenceMeasurementReportDto
     */
    'vesselLining': string;
    /**
     * 
     * @type {boolean}
     * @memberof RHIMFleetOverviewServiceV1ControllersSequenceMeasurementReportDto
     */
    'isHotMeasurement': boolean;
    /**
     * 
     * @type {string}
     * @memberof RHIMFleetOverviewServiceV1ControllersSequenceMeasurementReportDto
     */
    'liningCondition': string;
    /**
     * 
     * @type {string}
     * @memberof RHIMFleetOverviewServiceV1ControllersSequenceMeasurementReportDto
     */
    'liningMaintenanceType': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RHIMFleetOverviewServiceV1ControllersSequenceMeasurementReportDto
     */
    'emptyVesselMeasurement': boolean;
}
/**
 * 
 * @export
 * @interface RHIMFleetOverviewServiceV1ModelsCampaignDto
 */
export interface RHIMFleetOverviewServiceV1ModelsCampaignDto {
    /**
     * 
     * @type {number}
     * @memberof RHIMFleetOverviewServiceV1ModelsCampaignDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof RHIMFleetOverviewServiceV1ModelsCampaignDto
     */
    'displayText': string;
}
/**
 * 
 * @export
 * @interface RHIMFleetOverviewServiceV1ModelsEmbedParamsDto
 */
export interface RHIMFleetOverviewServiceV1ModelsEmbedParamsDto {
    /**
     * Type of the object to be embedded
     * @type {string}
     * @memberof RHIMFleetOverviewServiceV1ModelsEmbedParamsDto
     */
    'type': string;
    /**
     * Report to be embedded
     * @type {Array<RHIMFleetOverviewServiceDataEmbedReport>}
     * @memberof RHIMFleetOverviewServiceV1ModelsEmbedParamsDto
     */
    'embedReport': Array<RHIMFleetOverviewServiceDataEmbedReport>;
    /**
     * 
     * @type {MicrosoftPowerBIApiModelsEmbedToken}
     * @memberof RHIMFleetOverviewServiceV1ModelsEmbedParamsDto
     */
    'embedToken': MicrosoftPowerBIApiModelsEmbedToken;
}
/**
 * 
 * @export
 * @interface RHIMFleetOverviewServiceV1ModelsMeasurementVolumeDto
 */
export interface RHIMFleetOverviewServiceV1ModelsMeasurementVolumeDto {
    /**
     * The Id of the measurement.
     * @type {string}
     * @memberof RHIMFleetOverviewServiceV1ModelsMeasurementVolumeDto
     */
    'measurementId': string;
    /**
     * The number of the campaign.
     * @type {number}
     * @memberof RHIMFleetOverviewServiceV1ModelsMeasurementVolumeDto
     */
    'campaign': number;
    /**
     * The number of the heat.
     * @type {number}
     * @memberof RHIMFleetOverviewServiceV1ModelsMeasurementVolumeDto
     */
    'heat': number;
    /**
     * The time at which the measurement was taken.
     * @type {string}
     * @memberof RHIMFleetOverviewServiceV1ModelsMeasurementVolumeDto
     */
    'measurementTaken': string;
    /**
     * The lining of the vessel.
     * @type {string}
     * @memberof RHIMFleetOverviewServiceV1ModelsMeasurementVolumeDto
     */
    'vesselLining': string;
    /**
     * True if the vessel was hot during the measurement, otherwise false.
     * @type {boolean}
     * @memberof RHIMFleetOverviewServiceV1ModelsMeasurementVolumeDto
     */
    'isHotMeasurement': boolean;
    /**
     * The condition of the lining.
     * @type {string}
     * @memberof RHIMFleetOverviewServiceV1ModelsMeasurementVolumeDto
     */
    'liningCondition': string;
    /**
     * The type of the maintenance, this is optional.
     * @type {string}
     * @memberof RHIMFleetOverviewServiceV1ModelsMeasurementVolumeDto
     */
    'liningMaintenanceType'?: string | null;
    /**
     * The calculated volume for this measurement in m3.
     * @type {number}
     * @memberof RHIMFleetOverviewServiceV1ModelsMeasurementVolumeDto
     */
    'volume': number;
    /**
     * The depth value from zero height the calculation was started.
     * @type {number}
     * @memberof RHIMFleetOverviewServiceV1ModelsMeasurementVolumeDto
     */
    'volumeStartDepth': number;
    /**
     * The minimum remaining brick length in mm.
     * @type {number}
     * @memberof RHIMFleetOverviewServiceV1ModelsMeasurementVolumeDto
     */
    'rblMin': number;
    /**
     * The maximum remaining brick length in mm.
     * @type {number}
     * @memberof RHIMFleetOverviewServiceV1ModelsMeasurementVolumeDto
     */
    'rblMax': number;
    /**
     * The median remaining brick length in mm.
     * @type {number}
     * @memberof RHIMFleetOverviewServiceV1ModelsMeasurementVolumeDto
     */
    'rblMed': number;
    /**
     * The average remaining brick length in mm.
     * @type {number}
     * @memberof RHIMFleetOverviewServiceV1ModelsMeasurementVolumeDto
     */
    'rblAvg': number;
}
/**
 * 
 * @export
 * @interface RHIMFleetOverviewServiceV1ModelsReportInfoDto
 */
export interface RHIMFleetOverviewServiceV1ModelsReportInfoDto {
    /**
     * 
     * @type {string}
     * @memberof RHIMFleetOverviewServiceV1ModelsReportInfoDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof RHIMFleetOverviewServiceV1ModelsReportInfoDto
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof RHIMFleetOverviewServiceV1ModelsReportInfoDto
     */
    'reportName': string;
}
/**
 * 
 * @export
 * @interface RHIMFleetOverviewServiceV1ModelsVesselOverviewDataDto
 */
export interface RHIMFleetOverviewServiceV1ModelsVesselOverviewDataDto {
    /**
     * The Id of the vessel.
     * @type {string}
     * @memberof RHIMFleetOverviewServiceV1ModelsVesselOverviewDataDto
     */
    'vesselId': string;
    /**
     * The Id of the latest measurement for the vessel.
     * @type {string}
     * @memberof RHIMFleetOverviewServiceV1ModelsVesselOverviewDataDto
     */
    'latestMeasurementId': string;
    /**
     * The date and time of latest measurement for the vessel, otherwise null if there is no time of measurement information.
     * @type {string}
     * @memberof RHIMFleetOverviewServiceV1ModelsVesselOverviewDataDto
     */
    'latestMeasurementTakenOn'?: string | null;
    /**
     * The heat number of latest measurement for the vessel.
     * @type {number}
     * @memberof RHIMFleetOverviewServiceV1ModelsVesselOverviewDataDto
     */
    'latestMeasurementHeat': number;
    /**
     * The number of the campaign to which this latest measurement was assigned.
     * @type {number}
     * @memberof RHIMFleetOverviewServiceV1ModelsVesselOverviewDataDto
     */
    'latestMeasurementCampaign': number;
    /**
     * The minimum remaining brick length if a latest measurement with rbl data exists, otherwise null.
     * @type {number}
     * @memberof RHIMFleetOverviewServiceV1ModelsVesselOverviewDataDto
     */
    'minBrickLength'?: number | null;
    /**
     * The maximum remaining brick length if a latest measurement with rbl data exists, otherwise null.
     * @type {number}
     * @memberof RHIMFleetOverviewServiceV1ModelsVesselOverviewDataDto
     */
    'maxBrickLength'?: number | null;
}
/**
 * 
 * @export
 * @interface RHIMFleetOverviewServiceV1ModelsVolumesDashboardDto
 */
export interface RHIMFleetOverviewServiceV1ModelsVolumesDashboardDto {
    /**
     * 
     * @type {string}
     * @memberof RHIMFleetOverviewServiceV1ModelsVolumesDashboardDto
     */
    'vesselId': string;
    /**
     * 
     * @type {number}
     * @memberof RHIMFleetOverviewServiceV1ModelsVolumesDashboardDto
     */
    'campaign': number;
    /**
     * The volume growth rate in m3/heat
     * @type {number}
     * @memberof RHIMFleetOverviewServiceV1ModelsVolumesDashboardDto
     */
    'volumeGrowthRate': number;
    /**
     * The wear rate in mm/heat
     * @type {number}
     * @memberof RHIMFleetOverviewServiceV1ModelsVolumesDashboardDto
     */
    'wearRate': number;
    /**
     * The number of the maximum heat for the vessel.
     * @type {number}
     * @memberof RHIMFleetOverviewServiceV1ModelsVolumesDashboardDto
     */
    'maxHeat': number;
    /**
     * 
     * @type {Array<RHIMFleetOverviewServiceV1ModelsMeasurementVolumeDto>}
     * @memberof RHIMFleetOverviewServiceV1ModelsVolumesDashboardDto
     */
    'measurements': Array<RHIMFleetOverviewServiceV1ModelsMeasurementVolumeDto>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const RHIMMeasurementServiceContractsDataLiningCondition = {
    Initial: 'Initial',
    NormalUsed: 'NormalUsed',
    AfterRepair: 'AfterRepair'
} as const;

export type RHIMMeasurementServiceContractsDataLiningCondition = typeof RHIMMeasurementServiceContractsDataLiningCondition[keyof typeof RHIMMeasurementServiceContractsDataLiningCondition];


/**
 * 
 * @export
 * @enum {string}
 */

export const RHIMMeasurementServiceContractsDataLiningMaintenanceType = {
    BricksChanged: 'BricksChanged',
    Gunning: 'Gunning',
    Fettling: 'Fettling',
    SlagSplashing: 'SlagSplashing'
} as const;

export type RHIMMeasurementServiceContractsDataLiningMaintenanceType = typeof RHIMMeasurementServiceContractsDataLiningMaintenanceType[keyof typeof RHIMMeasurementServiceContractsDataLiningMaintenanceType];


/**
 * 
 * @export
 * @enum {string}
 */

export const RHIMMeasurementServiceContractsDataMeasurementFileType = {
    _3d: '3d',
    Stl: 'stl',
    Pcraw: 'pcraw',
    Pcaligned: 'pcaligned',
    Pcprerendered: 'pcprerendered',
    Pcreduced: 'pcreduced',
    Rbl: 'rbl',
    Wear: 'wear',
    Pctrans: 'pctrans',
    Pcfiltered: 'pcfiltered',
    Hrrbl: 'hrrbl',
    Hrwear: 'hrwear',
    Wallplot: 'wallplot',
    Bottomplot: 'bottomplot',
    Meta: 'meta',
    Statistics: 'statistics',
    Sectioncuts: 'sectioncuts',
    Distancesplot: 'distancesplot',
    Video: 'video',
    Image: 'image',
    Segmentation: 'segmentation',
    Hrsegmentation: 'hrsegmentation',
    Report: 'report',
    Lcsvertex: 'lcsvertex',
    Ferrotronv1pointdata: 'ferrotronv1pointdata',
    Ferrotronv2pointdata: 'ferrotronv2pointdata',
    Imsipointdata: 'imsipointdata',
    Latitudepointdata: 'latitudepointdata',
    Volumes: 'volumes',
    Watertightmesh: 'watertightmesh',
    Regionvalues: 'regionvalues',
    Regions: 'regions'
} as const;

export type RHIMMeasurementServiceContractsDataMeasurementFileType = typeof RHIMMeasurementServiceContractsDataMeasurementFileType[keyof typeof RHIMMeasurementServiceContractsDataMeasurementFileType];


/**
 * 
 * @export
 * @enum {string}
 */

export const RHIMMeasurementServiceContractsDataVesselLining = {
    Shell: 'Shell',
    ShellInsulation: 'ShellInsulation',
    PermanentLining: 'PermanentLining',
    WearLining: 'WearLining'
} as const;

export type RHIMMeasurementServiceContractsDataVesselLining = typeof RHIMMeasurementServiceContractsDataVesselLining[keyof typeof RHIMMeasurementServiceContractsDataVesselLining];



/**
 * FleetOverviewApi - axios parameter creator
 * @export
 */
export const FleetOverviewApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns a list of vessels with additional data for fleet overview.
         * @summary Returns a list of vessels with additional data for fleet overview.
         * @param {string} customerId Format - uuid. The Id of the customer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFleetoverviewCustomeridVessels: async (customerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getFleetoverviewCustomeridVessels', 'customerId', customerId)
            const localVarPath = `/FleetOverview/{customerId}/vessels`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FleetOverviewApi - functional programming interface
 * @export
 */
export const FleetOverviewApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FleetOverviewApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns a list of vessels with additional data for fleet overview.
         * @summary Returns a list of vessels with additional data for fleet overview.
         * @param {string} customerId Format - uuid. The Id of the customer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFleetoverviewCustomeridVessels(customerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RHIMFleetOverviewServiceV1ModelsVesselOverviewDataDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFleetoverviewCustomeridVessels(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FleetOverviewApi - factory interface
 * @export
 */
export const FleetOverviewApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FleetOverviewApiFp(configuration)
    return {
        /**
         * Returns a list of vessels with additional data for fleet overview.
         * @summary Returns a list of vessels with additional data for fleet overview.
         * @param {string} customerId Format - uuid. The Id of the customer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFleetoverviewCustomeridVessels(customerId: string, options?: any): AxiosPromise<Array<RHIMFleetOverviewServiceV1ModelsVesselOverviewDataDto>> {
            return localVarFp.getFleetoverviewCustomeridVessels(customerId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FleetOverviewApi - object-oriented interface
 * @export
 * @class FleetOverviewApi
 * @extends {BaseAPI}
 */
export class FleetOverviewApi extends BaseAPI {
    /**
     * Returns a list of vessels with additional data for fleet overview.
     * @summary Returns a list of vessels with additional data for fleet overview.
     * @param {string} customerId Format - uuid. The Id of the customer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FleetOverviewApi
     */
    public getFleetoverviewCustomeridVessels(customerId: string, options?: AxiosRequestConfig) {
        return FleetOverviewApiFp(this.configuration).getFleetoverviewCustomeridVessels(customerId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MeasurementSequenceApi - axios parameter creator
 * @export
 */
export const MeasurementSequenceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Campaigns
         * @summary Campaigns
         * @param {string} vesselId Format - uuid. The Id of the vessel.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeasurementsequenceCampaignsVesselid: async (vesselId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('getMeasurementsequenceCampaignsVesselid', 'vesselId', vesselId)
            const localVarPath = `/MeasurementSequence/campaigns/{vesselId}`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Measurements
         * @summary Measurements
         * @param {string} vesselId Format - uuid. The Id of the vessel.
         * @param {number} campaign Format - int32. The campaign.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeasurementsequenceMeasurementsVesselidCampaign: async (vesselId: string, campaign: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('getMeasurementsequenceMeasurementsVesselidCampaign', 'vesselId', vesselId)
            // verify required parameter 'campaign' is not null or undefined
            assertParamExists('getMeasurementsequenceMeasurementsVesselidCampaign', 'campaign', campaign)
            const localVarPath = `/MeasurementSequence/measurements/{vesselId}/{campaign}`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)))
                .replace(`{${"campaign"}}`, encodeURIComponent(String(campaign)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sequence
         * @summary Sequence
         * @param {string} vesselId Format - uuid. The Id of the vessel.
         * @param {number} campaign Format - int32. The campaign.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeasurementsequenceVesselidCampaign: async (vesselId: string, campaign: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('getMeasurementsequenceVesselidCampaign', 'vesselId', vesselId)
            // verify required parameter 'campaign' is not null or undefined
            assertParamExists('getMeasurementsequenceVesselidCampaign', 'campaign', campaign)
            const localVarPath = `/MeasurementSequence/{vesselId}/{campaign}`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)))
                .replace(`{${"campaign"}}`, encodeURIComponent(String(campaign)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MeasurementSequenceApi - functional programming interface
 * @export
 */
export const MeasurementSequenceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MeasurementSequenceApiAxiosParamCreator(configuration)
    return {
        /**
         * Campaigns
         * @summary Campaigns
         * @param {string} vesselId Format - uuid. The Id of the vessel.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMeasurementsequenceCampaignsVesselid(vesselId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RHIMFleetOverviewServiceV1ModelsCampaignDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMeasurementsequenceCampaignsVesselid(vesselId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Measurements
         * @summary Measurements
         * @param {string} vesselId Format - uuid. The Id of the vessel.
         * @param {number} campaign Format - int32. The campaign.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMeasurementsequenceMeasurementsVesselidCampaign(vesselId: string, campaign: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetMeasurementsequenceMeasurementsVesselidCampaign200ResponseInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMeasurementsequenceMeasurementsVesselidCampaign(vesselId, campaign, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sequence
         * @summary Sequence
         * @param {string} vesselId Format - uuid. The Id of the vessel.
         * @param {number} campaign Format - int32. The campaign.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMeasurementsequenceVesselidCampaign(vesselId: string, campaign: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RHIMFleetOverviewServiceV1ControllersMeasurementSequenceDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMeasurementsequenceVesselidCampaign(vesselId, campaign, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MeasurementSequenceApi - factory interface
 * @export
 */
export const MeasurementSequenceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MeasurementSequenceApiFp(configuration)
    return {
        /**
         * Campaigns
         * @summary Campaigns
         * @param {string} vesselId Format - uuid. The Id of the vessel.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeasurementsequenceCampaignsVesselid(vesselId: string, options?: any): AxiosPromise<Array<RHIMFleetOverviewServiceV1ModelsCampaignDto>> {
            return localVarFp.getMeasurementsequenceCampaignsVesselid(vesselId, options).then((request) => request(axios, basePath));
        },
        /**
         * Measurements
         * @summary Measurements
         * @param {string} vesselId Format - uuid. The Id of the vessel.
         * @param {number} campaign Format - int32. The campaign.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeasurementsequenceMeasurementsVesselidCampaign(vesselId: string, campaign: number, options?: any): AxiosPromise<Array<GetMeasurementsequenceMeasurementsVesselidCampaign200ResponseInner>> {
            return localVarFp.getMeasurementsequenceMeasurementsVesselidCampaign(vesselId, campaign, options).then((request) => request(axios, basePath));
        },
        /**
         * Sequence
         * @summary Sequence
         * @param {string} vesselId Format - uuid. The Id of the vessel.
         * @param {number} campaign Format - int32. The campaign.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeasurementsequenceVesselidCampaign(vesselId: string, campaign: number, options?: any): AxiosPromise<RHIMFleetOverviewServiceV1ControllersMeasurementSequenceDto> {
            return localVarFp.getMeasurementsequenceVesselidCampaign(vesselId, campaign, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MeasurementSequenceApi - object-oriented interface
 * @export
 * @class MeasurementSequenceApi
 * @extends {BaseAPI}
 */
export class MeasurementSequenceApi extends BaseAPI {
    /**
     * Campaigns
     * @summary Campaigns
     * @param {string} vesselId Format - uuid. The Id of the vessel.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeasurementSequenceApi
     */
    public getMeasurementsequenceCampaignsVesselid(vesselId: string, options?: AxiosRequestConfig) {
        return MeasurementSequenceApiFp(this.configuration).getMeasurementsequenceCampaignsVesselid(vesselId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Measurements
     * @summary Measurements
     * @param {string} vesselId Format - uuid. The Id of the vessel.
     * @param {number} campaign Format - int32. The campaign.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeasurementSequenceApi
     */
    public getMeasurementsequenceMeasurementsVesselidCampaign(vesselId: string, campaign: number, options?: AxiosRequestConfig) {
        return MeasurementSequenceApiFp(this.configuration).getMeasurementsequenceMeasurementsVesselidCampaign(vesselId, campaign, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sequence
     * @summary Sequence
     * @param {string} vesselId Format - uuid. The Id of the vessel.
     * @param {number} campaign Format - int32. The campaign.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeasurementSequenceApi
     */
    public getMeasurementsequenceVesselidCampaign(vesselId: string, campaign: number, options?: AxiosRequestConfig) {
        return MeasurementSequenceApiFp(this.configuration).getMeasurementsequenceVesselidCampaign(vesselId, campaign, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ReportsApi - axios parameter creator
 * @export
 */
export const ReportsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns the url and token for a report.
         * @summary Returns the url and token for a report.
         * @param {string} customerId Format - uuid. The Id of the customer
         * @param {string} vesselType The type of the vessel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportsEmbedCustomeridVesseltypeInfo: async (customerId: string, vesselType: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getReportsEmbedCustomeridVesseltypeInfo', 'customerId', customerId)
            // verify required parameter 'vesselType' is not null or undefined
            assertParamExists('getReportsEmbedCustomeridVesseltypeInfo', 'vesselType', vesselType)
            const localVarPath = `/Reports/embed/{customerId}/{vesselType}/info`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"vesselType"}}`, encodeURIComponent(String(vesselType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of ReportAssignments for a customer.
         * @summary Returns a list of ReportAssignments for a customer.
         * @param {string} customerId Format - uuid. The Id of the customer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportsListCustomerid: async (customerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getReportsListCustomerid', 'customerId', customerId)
            const localVarPath = `/Reports/list/{customerId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportsApi - functional programming interface
 * @export
 */
export const ReportsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportsApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns the url and token for a report.
         * @summary Returns the url and token for a report.
         * @param {string} customerId Format - uuid. The Id of the customer
         * @param {string} vesselType The type of the vessel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReportsEmbedCustomeridVesseltypeInfo(customerId: string, vesselType: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RHIMFleetOverviewServiceV1ModelsEmbedParamsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReportsEmbedCustomeridVesseltypeInfo(customerId, vesselType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a list of ReportAssignments for a customer.
         * @summary Returns a list of ReportAssignments for a customer.
         * @param {string} customerId Format - uuid. The Id of the customer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReportsListCustomerid(customerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RHIMFleetOverviewServiceV1ModelsReportInfoDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReportsListCustomerid(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportsApi - factory interface
 * @export
 */
export const ReportsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportsApiFp(configuration)
    return {
        /**
         * Returns the url and token for a report.
         * @summary Returns the url and token for a report.
         * @param {string} customerId Format - uuid. The Id of the customer
         * @param {string} vesselType The type of the vessel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportsEmbedCustomeridVesseltypeInfo(customerId: string, vesselType: string, options?: any): AxiosPromise<RHIMFleetOverviewServiceV1ModelsEmbedParamsDto> {
            return localVarFp.getReportsEmbedCustomeridVesseltypeInfo(customerId, vesselType, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of ReportAssignments for a customer.
         * @summary Returns a list of ReportAssignments for a customer.
         * @param {string} customerId Format - uuid. The Id of the customer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportsListCustomerid(customerId: string, options?: any): AxiosPromise<Array<RHIMFleetOverviewServiceV1ModelsReportInfoDto>> {
            return localVarFp.getReportsListCustomerid(customerId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReportsApi - object-oriented interface
 * @export
 * @class ReportsApi
 * @extends {BaseAPI}
 */
export class ReportsApi extends BaseAPI {
    /**
     * Returns the url and token for a report.
     * @summary Returns the url and token for a report.
     * @param {string} customerId Format - uuid. The Id of the customer
     * @param {string} vesselType The type of the vessel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public getReportsEmbedCustomeridVesseltypeInfo(customerId: string, vesselType: string, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).getReportsEmbedCustomeridVesseltypeInfo(customerId, vesselType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a list of ReportAssignments for a customer.
     * @summary Returns a list of ReportAssignments for a customer.
     * @param {string} customerId Format - uuid. The Id of the customer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public getReportsListCustomerid(customerId: string, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).getReportsListCustomerid(customerId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * VolumesDashboardApi - axios parameter creator
 * @export
 */
export const VolumesDashboardApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * CampaignsHavingVolumes returns a list of campaign names for the specified vessel that contain measurements with a volume calculation.
         * @summary CampaignsHavingVolumes returns a list of campaign names for the specified vessel that contain measurements with a volume calculation.
         * @param {string} vesselId Format - uuid. The Id of the vessel.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVolumesdashboardCampaignsVesselid: async (vesselId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('getVolumesdashboardCampaignsVesselid', 'vesselId', vesselId)
            const localVarPath = `/VolumesDashboard/campaigns/{vesselId}`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * DetailsForCampaign returns the overview data and the list of measurements with volume and rbl data.
         * @summary DetailsForCampaign returns the overview data and the list of measurements with volume and rbl data.
         * @param {string} vesselId Format - uuid. The Id of the vessel.
         * @param {number} campaign Format - int32. The number of the campaign for this request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVolumesdashboardDetailsVesselidCampaign: async (vesselId: string, campaign: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vesselId' is not null or undefined
            assertParamExists('getVolumesdashboardDetailsVesselidCampaign', 'vesselId', vesselId)
            // verify required parameter 'campaign' is not null or undefined
            assertParamExists('getVolumesdashboardDetailsVesselidCampaign', 'campaign', campaign)
            const localVarPath = `/VolumesDashboard/details/{vesselId}/{campaign}`
                .replace(`{${"vesselId"}}`, encodeURIComponent(String(vesselId)))
                .replace(`{${"campaign"}}`, encodeURIComponent(String(campaign)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VolumesDashboardApi - functional programming interface
 * @export
 */
export const VolumesDashboardApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VolumesDashboardApiAxiosParamCreator(configuration)
    return {
        /**
         * CampaignsHavingVolumes returns a list of campaign names for the specified vessel that contain measurements with a volume calculation.
         * @summary CampaignsHavingVolumes returns a list of campaign names for the specified vessel that contain measurements with a volume calculation.
         * @param {string} vesselId Format - uuid. The Id of the vessel.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVolumesdashboardCampaignsVesselid(vesselId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RHIMFleetOverviewServiceV1ModelsCampaignDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVolumesdashboardCampaignsVesselid(vesselId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * DetailsForCampaign returns the overview data and the list of measurements with volume and rbl data.
         * @summary DetailsForCampaign returns the overview data and the list of measurements with volume and rbl data.
         * @param {string} vesselId Format - uuid. The Id of the vessel.
         * @param {number} campaign Format - int32. The number of the campaign for this request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVolumesdashboardDetailsVesselidCampaign(vesselId: string, campaign: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RHIMFleetOverviewServiceV1ModelsVolumesDashboardDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVolumesdashboardDetailsVesselidCampaign(vesselId, campaign, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VolumesDashboardApi - factory interface
 * @export
 */
export const VolumesDashboardApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VolumesDashboardApiFp(configuration)
    return {
        /**
         * CampaignsHavingVolumes returns a list of campaign names for the specified vessel that contain measurements with a volume calculation.
         * @summary CampaignsHavingVolumes returns a list of campaign names for the specified vessel that contain measurements with a volume calculation.
         * @param {string} vesselId Format - uuid. The Id of the vessel.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVolumesdashboardCampaignsVesselid(vesselId: string, options?: any): AxiosPromise<Array<RHIMFleetOverviewServiceV1ModelsCampaignDto>> {
            return localVarFp.getVolumesdashboardCampaignsVesselid(vesselId, options).then((request) => request(axios, basePath));
        },
        /**
         * DetailsForCampaign returns the overview data and the list of measurements with volume and rbl data.
         * @summary DetailsForCampaign returns the overview data and the list of measurements with volume and rbl data.
         * @param {string} vesselId Format - uuid. The Id of the vessel.
         * @param {number} campaign Format - int32. The number of the campaign for this request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVolumesdashboardDetailsVesselidCampaign(vesselId: string, campaign: number, options?: any): AxiosPromise<RHIMFleetOverviewServiceV1ModelsVolumesDashboardDto> {
            return localVarFp.getVolumesdashboardDetailsVesselidCampaign(vesselId, campaign, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VolumesDashboardApi - object-oriented interface
 * @export
 * @class VolumesDashboardApi
 * @extends {BaseAPI}
 */
export class VolumesDashboardApi extends BaseAPI {
    /**
     * CampaignsHavingVolumes returns a list of campaign names for the specified vessel that contain measurements with a volume calculation.
     * @summary CampaignsHavingVolumes returns a list of campaign names for the specified vessel that contain measurements with a volume calculation.
     * @param {string} vesselId Format - uuid. The Id of the vessel.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VolumesDashboardApi
     */
    public getVolumesdashboardCampaignsVesselid(vesselId: string, options?: AxiosRequestConfig) {
        return VolumesDashboardApiFp(this.configuration).getVolumesdashboardCampaignsVesselid(vesselId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * DetailsForCampaign returns the overview data and the list of measurements with volume and rbl data.
     * @summary DetailsForCampaign returns the overview data and the list of measurements with volume and rbl data.
     * @param {string} vesselId Format - uuid. The Id of the vessel.
     * @param {number} campaign Format - int32. The number of the campaign for this request.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VolumesDashboardApi
     */
    public getVolumesdashboardDetailsVesselidCampaign(vesselId: string, campaign: number, options?: AxiosRequestConfig) {
        return VolumesDashboardApiFp(this.configuration).getVolumesdashboardDetailsVesselidCampaign(vesselId, campaign, options).then((request) => request(this.axios, this.basePath));
    }
}



