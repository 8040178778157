import { i18nReact } from '@rhim/i18n';
import { Progress, Tabs } from '@rhim/react';
import { GetMeasurementsequenceMeasurementsVesselidCampaign200ResponseInner } from '@rhim/rest/fleetOverview';
import { assert, isDefined } from '@rhim/utils';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import AppContext from '../../app/AppContext';
import {
  useCampaignsOfVesselFleetOverview,
  useMeasurementsOfCampaignFleetOverview,
  useSelectedCampaign,
  useSelectedMeasurement,
  useSelectedVessel,
  useVessels,
} from '../../hooks';
import { Feature } from '../../settings';
import BlastFurnaceRunnerReportContext, { IBlastFurnaceRunnerReportContext } from './BlastFurnaceRunnerReportContext';
import CampaignReportPanel from './CampaignReportPanel';
import MeasurementsTimelinePanel from './components/MeasurementsTimeLinePanel/MeasurementsTimelinePanel';
import MeasurementStrip from './components/MeasurementStrip';
import { useMeasurementSequence } from './hooks/useMeasurementSequence';
import MeasurementReportPanel from './MeasurementReportPanel';
import { SContainer } from './styles';
import { apiTonnageForDisplay } from './utils';

const BlastFurnaceRunnerReportPage: React.ChildlessComponent = () => {
  const { t } = i18nReact.useTranslation(['blastFurnaceRunner-report']);
  const { selectedCustomer } = React.useContext(AppContext);
  const customerId = selectedCustomer.customerId as UUID;

  // LOAD VESSELS OF SELECTED CUSTOMER
  const { data: vessels } = useVessels(customerId, undefined, Feature.BlastFurnaceRunnerReport);
  assert(isDefined(vessels), 'In Suspense mode data should always be defined, according to react-query docs.');
  const { selectedVesselId, navigateToSelectedVesselId } = useSelectedVessel(vessels);

  // LOAD CAMPAIGNS OF SELECTED VESSEL
  const { data: campaigns } = useCampaignsOfVesselFleetOverview(selectedVesselId ?? undefined, {
    configuration: {
      suspense: false,
    },
  });
  const [selectedCampaignId, setSelectedCampaignId] = useSelectedCampaign(campaigns ?? []);

  // LOAD MEASUREMENTS OF SELECTED CAMPAIGN
  const { data: apiMeasurements } = useMeasurementsOfCampaignFleetOverview(selectedVesselId ?? undefined, selectedCampaignId, {
    configuration: {
      suspense: false,
    },
  });
  /**
   * The API uses "tonnage" which is equivelant to "heat" in our case.
   * Also, the API's tonnage is in kilograms but we convert it to kilotons when we display it.
   */
  const transformedMeasurements = useMemo(() => {
    if (!isDefined(apiMeasurements)) {
      return undefined;
    }
    return apiMeasurements.map((measurement) => ({ ...measurement, heat: apiTonnageForDisplay(measurement.tonnage, 'kiloTons') }));
  }, [apiMeasurements]);
  const [selectedMeasurementId, setSelectedMeasurementId] = useSelectedMeasurement(transformedMeasurements);

  const { data: measurementSequence } = useMeasurementSequence(selectedVesselId ?? undefined, selectedCampaignId, {
    configuration: {
      suspense: false,
    },
  });

  const selectedMeasurement: undefined | GetMeasurementsequenceMeasurementsVesselidCampaign200ResponseInner = useMemo(() => {
    if (!isDefined(apiMeasurements) || !isDefined(selectedMeasurementId)) {
      return undefined;
    }
    const associatedMeasurement = apiMeasurements.find((measurement) => measurement.id === selectedMeasurementId);
    return associatedMeasurement;
  }, [apiMeasurements, selectedMeasurementId]);

  const contextValue: IBlastFurnaceRunnerReportContext = useMemo(() => {
    return {
      selectedMeasurement,
    };
  }, [selectedMeasurement]);

  return (
    <SWrapper>
      <BlastFurnaceRunnerReportContext.Provider value={contextValue}>
        <SStickyContainer>
          <MeasurementStrip
            vessels={vessels}
            selectedVesselId={selectedVesselId}
            campaigns={campaigns}
            selectedCampaignId={selectedCampaignId}
            measurements={transformedMeasurements}
            selectedMeasurementId={selectedMeasurementId}
            onVesselSelected={navigateToSelectedVesselId}
            onCampaignSelected={setSelectedCampaignId}
            onMeasurementSelected={setSelectedMeasurementId}
          />
          {isDefined(measurementSequence) && isDefined(apiMeasurements) && (
            <MeasurementsTimelinePanel
              measurementSequence={measurementSequence}
              measurements={apiMeasurements}
              selectedMeasurementId={selectedMeasurementId}
              onMeasurementSelected={setSelectedMeasurementId}
            />
          )}
        </SStickyContainer>
        {isDefined(measurementSequence) && (
          <SContainer>
            <Tabs>
              <Tabs.TabPane tab={t('blastFurnaceRunner-report:tabMeasurementReport')} key={1}>
                <MeasurementReportPanel measurementSequence={measurementSequence} />
              </Tabs.TabPane>
              <Tabs.TabPane tab={t('blastFurnaceRunner-report:tabCampaignReport')} key={2}>
                <CampaignReportPanel measurementSequence={measurementSequence} />
              </Tabs.TabPane>
            </Tabs>
          </SContainer>
        )}
        {!isDefined(measurementSequence) && <Progress />}
      </BlastFurnaceRunnerReportContext.Provider>
    </SWrapper>
  );
};

const SWrapper = styled.div`
  height: 100%;
  position: relative;
  overflow: auto;
`;

const SStickyContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
`;

BlastFurnaceRunnerReportPage.whyDidYouRender = true;
export default React.memo(BlastFurnaceRunnerReportPage);
