import { isDefined } from '@rhim/utils';
import React, { useState } from 'react';

import { NearestDatumRadial, Position, TooltipHandlersRadial, TooltipStateRadial } from '../types';

const TOOLTIP_CURSOR_OFFSET_X = 15;

export type UseTooltip<Datum> = [ref: React.RefObject<HTMLDivElement>, state: TooltipStateRadial<Datum>, handlers: TooltipHandlersRadial<Datum>];

interface UseTooltipRadialProps<Datum> {
  stickyMode?: boolean;
  onSticky?: (nearestDatum: NearestDatumRadial<Datum> | null) => void;
}

export default function useTooltipRadial<Datum>(props?: UseTooltipRadialProps<Datum>): UseTooltip<Datum> {
  const [position, setPosition] = useState<Position | null>(null);
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [nearestDatum, setNearestDatum] = useState<NearestDatumRadial<Datum> | null>(null);
  const [isSticky, setIsSticky] = useState<boolean>(false);

  const handleBisectXRef = React.useRef<((x: number, y: number) => NearestDatumRadial<Datum>) | null>(null);

  const ref = React.useRef<HTMLDivElement>(null);
  const tooltipWidth = ref.current?.getBoundingClientRect().width;

  const handlePositionFromEvent = React.useCallback(
    (e: React.MouseEvent) => {
      const { left, top, width } = e.currentTarget.getBoundingClientRect();
      const pointerX = e.clientX - left;
      const pointerY = e.clientY - top;

      let _nearestDatum: NearestDatumRadial<Datum> | null = null;
      if (typeof handleBisectXRef.current === 'function') {
        _nearestDatum = handleBisectXRef.current(pointerX, pointerY);
        setNearestDatum(_nearestDatum);
      }

      setPosition((currentPosition) => {
        const _tooltipWidth = isDefined(tooltipWidth) ? tooltipWidth : currentPosition?.tooltipWidth;

        return {
          pointerX,
          pointerY,
          tooltipWidth: _tooltipWidth,
          tooltipX: (x) => {
            const offset = x > width / 2 ? TOOLTIP_CURSOR_OFFSET_X : -(tooltipWidth ?? 0) - TOOLTIP_CURSOR_OFFSET_X;
            const tooltipX = x + offset;
            return tooltipX;
          },
          tooltipY: (y) => {
            return y;
          },
        };
      });
      return _nearestDatum;
    },
    [tooltipWidth]
  );

  const onMouseMove = React.useCallback(
    (e: React.MouseEvent) => {
      if (isSticky) return;

      handlePositionFromEvent(e);
    },
    [handlePositionFromEvent, isSticky]
  );

  const onMouseEnter = React.useCallback(() => {
    setIsHovered(true);
  }, []);

  const handleClose = React.useCallback(() => {
    setIsHovered(false);
    setPosition(null);
    setNearestDatum(null);
    setIsSticky(false);
  }, []);

  const onMouseLeave = React.useCallback(() => {
    if (isSticky) return;

    handleClose();
  }, [handleClose, isSticky]);

  const onClick = React.useCallback(
    (e: React.MouseEvent) => {
      const _nearestDatum = handlePositionFromEvent(e);
      if (props?.stickyMode === true) {
        setIsSticky(true);
        props.onSticky?.(_nearestDatum);
      }
    },
    [handlePositionFromEvent, props]
  );

  const state = {
    position,
    isHovered,
    nearestDatum,
    isSticky,
  };

  const handlers = {
    mouseEvents: {
      onClick,
      onMouseMove,
      onMouseEnter,
      onMouseLeave,
    },
    handleBisectXRef,
    handleClose,
  };

  return [ref, state, handlers];
}
