import { settings } from '@rhim/design';
import { i18nReact } from '@rhim/i18n';
import { getFormattedDate } from '@rhim/react';
import { RHIMFleetOverviewServiceV1ControllersMeasurementSequenceDto } from '@rhim/rest/fleetOverview';
import { isDefined } from '@rhim/utils';
import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';

import BlastFurnaceRunnerReportContext from './BlastFurnaceRunnerReportContext';
import { FLOOR_WALLPLOT_DATA, LEFT_WALLPLOT_DATA, RIGHT_WALLPLOT_DATA, SIDE_0_LINE_COLOR, SIDE_1_LINE_COLOR } from './components/constants';
import Heading, { FontSize } from './components/Heading';
import ReportHeader from './components/ReportHeader';
import { SectionCut } from './components/sectionCuts';
import Wallplot from './components/Wallplot';
import { useLinesDepth } from './hooks/useLinesDepth';
import MeasurementReportSummary from './MeasurementReportSummary/MeasurementReportSummary';
import { VesselLine } from './types';
import { apiTonnageForDisplay } from './utils';

interface Props {
  measurementSequence: RHIMFleetOverviewServiceV1ControllersMeasurementSequenceDto;
}
const MeasurementReportPanel: React.ChildlessComponent<Props> = ({ measurementSequence }) => {
  const { t } = i18nReact.useTranslation(['blastFurnaceRunner-report']);
  const { selectedMeasurement } = useContext(BlastFurnaceRunnerReportContext);
  const linesDepth = useLinesDepth(measurementSequence);

  const measurementInfo = useMemo(() => {
    if (!isDefined(selectedMeasurement)) {
      return '';
    }
    return `${apiTonnageForDisplay(selectedMeasurement.tonnage, 'tons')}t (${getFormattedDate({
      datetime: selectedMeasurement.measurementTaken as Date.ISO_8601,
    })})`;
  }, [selectedMeasurement]);

  if (!isDefined(selectedMeasurement)) {
    return null;
  }

  return (
    <SWrapper>
      <ReportHeader title={t('blastFurnaceRunner-report:tabMeasurementReport')} metadata={measurementInfo} />
      <MeasurementReportSummary measurementSequence={measurementSequence} selectedTonnageKilograms={selectedMeasurement.tonnage} />
      <Heading
        fontSize={FontSize.Size25}
        label={t('blastFurnaceRunner-report:measurementReport.headingSequenceMeasurement')}
        subLabel={<span>{`(${apiTonnageForDisplay(selectedMeasurement.tonnage, 'tons')}t)`}</span>}
      />
      <Heading fontSize={FontSize.Size20} label={t('blastFurnaceRunner-report:slagLine')} subLabel={linesDepth.slagLineDepth} />
      <SectionCut line={VesselLine.Slagline} />
      <Heading fontSize={FontSize.Size20} label={t('blastFurnaceRunner-report:ironLine')} subLabel={linesDepth.ironLineDepth} />
      <SectionCut line={VesselLine.Ironline} />
      <Heading fontSize={FontSize.Size25} label={t('blastFurnaceRunner-report:measurementReport.headingWallsAndFloor')} />
      <Heading fontSize={FontSize.Size16} label={t('blastFurnaceRunner-report:measurementReport.subHeadingRightWall')} />
      <Wallplot
        yAxisLabel={`${t('blastFurnaceRunner-report:height')} [cm]`}
        plotHeight={90}
        wallplotData={RIGHT_WALLPLOT_DATA}
        xAxisRange={[0, 10]}
        yAxisRange={[0, 100]}
        indicators={[
          { label: t('blastFurnaceRunner-report:slagLine'), y: 20, color: SIDE_0_LINE_COLOR },
          { label: t('blastFurnaceRunner-report:ironLine'), y: 50, color: SIDE_1_LINE_COLOR },
        ]}
      />
      <Heading fontSize={FontSize.Size16} label={t('blastFurnaceRunner-report:measurementReport.subHeadingLeftWall')} />
      <Wallplot
        yAxisLabel={`${t('blastFurnaceRunner-report:height')} [cm]`}
        plotHeight={90}
        wallplotData={LEFT_WALLPLOT_DATA}
        xAxisRange={[0, 10]}
        yAxisRange={[0, 100]}
        indicators={[
          { label: t('blastFurnaceRunner-report:slagLine'), y: 20, color: SIDE_0_LINE_COLOR },
          { label: t('blastFurnaceRunner-report:ironLine'), y: 50, color: SIDE_1_LINE_COLOR },
        ]}
      />
      <Heading fontSize={FontSize.Size16} label={t('blastFurnaceRunner-report:measurementReport.subHeadingFloor')} />
      <Wallplot
        yAxisLabel={`${t('blastFurnaceRunner-report:width')} [cm]`}
        plotHeight={135}
        wallplotData={FLOOR_WALLPLOT_DATA}
        xAxisRange={[0, 10]}
        yAxisRange={[-120, 120]}
        indicators={[]}
      />
    </SWrapper>
  );
};

const SWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${settings.Spacing.Spacing_300};
`;

MeasurementReportPanel.whyDidYouRender = true;
export default React.memo(MeasurementReportPanel);
