import { assert, isDefined } from '@rhim/utils';
import { ScaleLinear } from 'd3-scale';
import { useMemo } from 'react';

import { GRAPH_TOOLTIP_REGION_FILL, GRAPH_TOOLTIP_REGION_OPACITY, GRAPH_TOOLTIP_VERTICAL_LINE_COLOR } from '../components/constants';
import { GraphType } from '../components/GraphTooltip';
import { MeasurementTuple } from './useMeasurementSequenceTuples';

export function useTooltipIndicator(
  xScale: ScaleLinear<number, number>,
  graphAreaHeightPx: undefined | number,
  graphType: GraphType,
  previousMeasurementTuple: undefined | MeasurementTuple,
  hoveredMeasurementTuple: undefined | MeasurementTuple
) {
  const indicator = useMemo(() => {
    if (!isDefined(graphAreaHeightPx) || !isDefined(hoveredMeasurementTuple)) {
      return null;
    }
    const indicatorType = graphType === 'wearRateSlagline' || graphType === 'wearRateIronline' ? 'region' : 'line';

    switch (indicatorType) {
      case 'line': {
        const lineIndicatorX = xScale(hoveredMeasurementTuple.tonnageKilograms);
        return <line x1={lineIndicatorX} y1={0} x2={lineIndicatorX} y2={graphAreaHeightPx} stroke={GRAPH_TOOLTIP_VERTICAL_LINE_COLOR} />;
      }
      case 'region': {
        assert(isDefined(previousMeasurementTuple), 'Unexpected flow : previousMeasurementTuple expected to be set');
        const regionIndicatorStartX = xScale(previousMeasurementTuple.tonnageKilograms);
        const regionIndicatorEndX = xScale(hoveredMeasurementTuple.tonnageKilograms);
        const regionWidth = regionIndicatorEndX - regionIndicatorStartX;
        return (
          <>
            <rect
              x={regionIndicatorStartX}
              width={regionWidth}
              height={graphAreaHeightPx}
              fill={GRAPH_TOOLTIP_REGION_FILL}
              opacity={GRAPH_TOOLTIP_REGION_OPACITY}
            />
            <line x1={regionIndicatorStartX} y1={0} x2={regionIndicatorStartX} y2={graphAreaHeightPx} stroke={GRAPH_TOOLTIP_VERTICAL_LINE_COLOR} />;
            <line x1={regionIndicatorEndX} y1={0} x2={regionIndicatorEndX} y2={graphAreaHeightPx} stroke={GRAPH_TOOLTIP_VERTICAL_LINE_COLOR} />;
          </>
        );
      }
    }
  }, [xScale, graphAreaHeightPx, graphType, previousMeasurementTuple, hoveredMeasurementTuple]);

  return indicator;
}
