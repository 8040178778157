import { settings } from '@rhim/design';
import { Line } from '@vx/shape';
import styled from 'styled-components';

import { CONTAINER_HORIZONTAL_PADDING_PX } from './components/constants';

export const SSVGContainer = styled.svg<{ height: number }>`
  overflow: visible;
  width: 100%;
  height: ${(props) => props.height}px;
  shape-rendering: crispedges;

  * {
    pointer-events: none;
  }
`;

export const SContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 ${CONTAINER_HORIZONTAL_PADDING_PX}px;
`;

export const SDashedLine = styled(Line)`
  stroke-width: 1;
  stroke-dasharray: 2;
  pointer-events: none;
`;

export const AXIS_LABEL_LINE_HEIGHT_HEIGHT_PX = 12;
export const AXIS_LABEL_VERTICAL_MARGIN_PX = 2;
export const TOTAL_AXIS_LABEL_HEIGHT_PX = AXIS_LABEL_LINE_HEIGHT_HEIGHT_PX + 2 * AXIS_LABEL_VERTICAL_MARGIN_PX;

export const SAxisLabel = styled.span<{ mode: 'yAxis' | 'xAxis' }>`
  font-family: ${settings.typography.FontFamily.Regular};
  font-size: ${settings.typography.FontSize.X_Small};
  color: ${settings.colors.Primary.Grey_8};
  line-height: ${AXIS_LABEL_LINE_HEIGHT_HEIGHT_PX}px;
  margin: ${AXIS_LABEL_VERTICAL_MARGIN_PX}px 0;
  align-self: ${(props) => (props.mode === 'yAxis' ? 'flex-start' : 'flex-end')};
`;
