import { isDefined } from './is-defined';

export const sortAlphabeticallyBy =
  <K extends string>(field: K, backupField?: K) =>
  <T extends { [P in K]?: string | null }>(left: T, right: T) => {
    const leftValue = isDefined(left[field]) ? left[field] ?? '' : isDefined(backupField) ? left[backupField] ?? '' : '';
    const rightValue = isDefined(right[field]) ? right[field] ?? '' : isDefined(backupField) ? right[backupField] ?? '' : '';

    return leftValue.localeCompare(rightValue, undefined, {
      numeric: true,
      sensitivity: 'base',
    });
  };

export const sortNumericallyBy =
  <K extends string>(field: K) =>
  <T extends Partial<Record<K, number | null>>>(left: T, right: T) =>
    (left[field] ?? 0) - (right[field] ?? 0);
