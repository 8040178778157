import { AXIS_BOTTOM_STYLES, AXIS_STYLES, TICK_LABEL_PROPS } from '@rhim/chart/v2/axis';
import { settings } from '@rhim/design';
import { i18nReact } from '@rhim/i18n';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { ScaleLinear } from 'd3-scale';
import React, { useContext, useMemo } from 'react';
import { useMeasure } from 'react-use';
import styled from 'styled-components';

import BlastFurnaceRunnerReportContext from '../BlastFurnaceRunnerReportContext';
import { Y_AXIS_TICK_COUNT_HINT } from '../hooks/useScaleY';
import { SAxisLabel, SSVGContainer } from '../styles';
import { xAxisTickFormat } from '../utils';
import { DEFAULT_GRAPH_X_AXIS_HEIGHT_PX } from './constants';
import GraphHeader from './GraphHeader';
import { GraphLegendProps } from './GraphLegend';
import SelectedXTick from './SelectedXTick';
import SVGVerticalLine from './SVGVerticalLine';

interface Referable {
  ref?: React.Ref<HTMLTableRowElement>;
}

interface Props {
  graphHeightTotal: number;
  xScale: ScaleLinear<number, number>;
  yScale: ScaleLinear<number, number>;
  yAxisLabel: string;
  graphLegends: undefined | GraphLegendProps[];
  measurementsAtKilograms: number[];
  tooltipPanel?: React.ReactNode;
  children: React.Node;
}

const Graph = React.forwardRef<SVGRectElement, Props & Referable>(function Graph(
  { graphHeightTotal, xScale, yScale, yAxisLabel, graphLegends, measurementsAtKilograms, tooltipPanel, children },
  forwaredRef
) {
  const { t } = i18nReact.useTranslation(['blastFurnaceRunner-report']);
  const { selectedMeasurement } = useContext(BlastFurnaceRunnerReportContext);
  const [svgContainerRef, { width }] = useMeasure<SVGSVGElement>();
  const graphAreaHeight = graphHeightTotal - DEFAULT_GRAPH_X_AXIS_HEIGHT_PX;

  const yAxisTicks = useMemo(() => {
    return yScale.ticks(Y_AXIS_TICK_COUNT_HINT);
  }, [yScale]);

  return (
    <SWrapper>
      <GraphHeader yAxisLabel={yAxisLabel} graphLegends={graphLegends} />
      <SGraphContainer>
        <SSVGContainer ref={svgContainerRef} height={graphHeightTotal}>
          <rect
            ref={forwaredRef}
            style={{ pointerEvents: 'visible' }}
            x={0}
            y={0}
            width={width}
            height={graphAreaHeight}
            fill="none"
            stroke={settings.colors.Primary.Grey_3}
          />
          <AxisLeft scale={yScale} tickValues={yAxisTicks} {...AXIS_STYLES} />
          <AxisBottom
            top={graphAreaHeight}
            scale={xScale}
            tickValues={measurementsAtKilograms}
            tickFormat={xAxisTickFormat}
            tickLength={AXIS_STYLES.tickLength}
            stroke={settings.colors.Primary.Grey_4}
            tickStroke={settings.colors.Primary.Grey_6}
            tickLabelProps={() => {
              const props = {
                ...TICK_LABEL_PROPS,
                textAnchor: 'middle',
                dy: AXIS_BOTTOM_STYLES.tickLabelProps.dy,
              } as const;
              return props;
            }}
          />
          {/* VERTICAL LINE FOR EACH DATA POINT ACROSS THE WHOLE Y-AREA  */}
          <g id="group-vertical-lines">
            {measurementsAtKilograms.map((measurementAtKilograms, index) => (
              <React.Fragment key={index}>
                <SVGVerticalLine
                  graphAreaHeight={graphAreaHeight}
                  xScale={xScale}
                  value={measurementAtKilograms}
                  isSelected={measurementAtKilograms === selectedMeasurement?.tonnage}
                />
              </React.Fragment>
            ))}
          </g>
          {children}
        </SSVGContainer>
        <SelectedXTick graphBottomPadding={DEFAULT_GRAPH_X_AXIS_HEIGHT_PX} xScale={xScale} />
        {tooltipPanel}
      </SGraphContainer>
      <SAxisLabel mode="xAxis">{`${t('blastFurnaceRunner-report:campaignReport.xAxisLabel')} [kt]→`}</SAxisLabel>
    </SWrapper>
  );
});

const SWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const SGraphContainer = styled.div`
  display: flex;
  position: relative;
`;

Graph.whyDidYouRender = true;
export default React.memo(Graph);
