import { AXIS_STYLES, TICK_LABEL_PROPS } from '@rhim/chart/v2/axis';
import { settings } from '@rhim/design';
import { i18nReact } from '@rhim/i18n';
import { HorizontalToggleButtonsCluster } from '@rhim/react';
import { AxisBottom } from '@visx/axis';
import { scaleLinear } from '@visx/scale';
import React, { useCallback, useMemo, useState } from 'react';
import { useMeasure } from 'react-use';
import styled, { css } from 'styled-components';

import { useSidesLabels } from '../../hooks/useSidesLabels';
import { SAxisLabel } from '../../styles';
import { VesselLine } from '../../types';
import GraphLegend from '../GraphLegend';
import {
  GRAPH_HEIGHT,
  GRAPH_MARGIN_VERTICAL,
  SHAPE_HORIZONTAL_MARGIN,
  SHAPE_VERTICAL_MARGIN,
  TOTAL_GRAPH_HEIGHT,
  VERTICAL_SECTIONS_CONTAINER_HORIZONTAL_MARGIN,
  VERTICAL_SECTIONS_COUNT,
} from './constants';
import GraphBarCharts from './GraphBarCharts';
import VerticalSections, { THICKNESS_SIDE_0, THICKNESS_SIDE_1 } from './VerticalSections';

const INITIAL_LINE: [number, number][] = [
  [2, 56],
  [27, 51],
  [339, 47],
  [477, 41],
  [505, 33],
  [513, 24],
  [608, 17],
  [716, 18],
  [1029, 24],
  [1030, 129],
  [607, 135],
  [503, 125],
  [494, 115],
  [339, 105],
  [30, 102],
  [1, 98],
];

const SLAG_LINE_BEFORE_REPAIR: [number, number][] = [
  [25, 59],
  [73, 36],
  [129, 20],
  [186, 14],
  [247, 21],
  [333, 24],
  [404, 24],
  [445, 21],
  [486, 24],
  [538, 2],
  [615, 2],
  [698, 9],
  [950, 13],
  [1024, 9],
  [1035, 26],
  [1042, 76],
  [1034, 133],
  [871, 142],
  [644, 147],
  [416, 132],
  [326, 141],
  [197, 133],
  [124, 141],
  [72, 123],
  [16, 97],
];

const SLAG_LINE_AFTER_REPAIR: [number, number][] = [
  [22, 66],
  [30, 54],
  [97, 27],
  [161, 20],
  [241, 25],
  [357, 27],
  [445, 26],
  [512, 18],
  [547, 11],
  [690, 15],
  [1027, 15],
  [1035, 35],
  [1041, 67],
  [1038, 131],
  [1016, 139],
  [703, 142],
  [628, 139],
  [551, 144],
  [496, 133],
  [441, 127],
  [324, 135],
  [152, 131],
  [120, 137],
  [74, 122],
  [13, 95],
];

const INITIAL_LINE_COLOR = settings.colors.Primary.Grey_6;
const ALL_DATA = [...INITIAL_LINE, ...SLAG_LINE_BEFORE_REPAIR, ...SLAG_LINE_AFTER_REPAIR];

const MAX_X = (() => {
  let maxX = Number.NEGATIVE_INFINITY;
  for (const point of ALL_DATA) {
    maxX = Math.max(maxX, point[0]);
  }
  return maxX;
})();

const MAX_Y = (() => {
  let maxY = Number.NEGATIVE_INFINITY;
  for (const point of ALL_DATA) {
    maxY = Math.max(maxY, point[1]);
  }
  return maxY;
})();

const SLAG_LINE_LINE_COLOR = settings.colors.Miscellaneous.Purple_Deep;
const IRON_LINE_LINE_COLOR = settings.colors.Miscellaneous.Blue;

enum REPAIR_MODE {
  BEFORE = 'BEFORE',
  AFTER = 'AFTER',
}

interface Props {
  line: VesselLine;
}
const SectionCut: React.ChildlessComponent<Props> = ({ line }) => {
  const { t } = i18nReact.useTranslation(['blastFurnaceRunner-report']);
  const [svgContainerRef, { width }] = useMeasure<SVGSVGElement>();
  const [repairMode, setRepairMode] = useState<REPAIR_MODE>(REPAIR_MODE.BEFORE);
  const { side0Label, side1Label } = useSidesLabels();

  const verticalSectionsWidth = width - 2 * VERTICAL_SECTIONS_CONTAINER_HORIZONTAL_MARGIN;
  const verticalSectionWidth = verticalSectionsWidth / VERTICAL_SECTIONS_COUNT;

  const xAxisScale = scaleLinear({
    domain: [0, 10],
    range: [0, width],
  });

  const xScale = scaleLinear({
    domain: [0, MAX_X],
    range: [SHAPE_HORIZONTAL_MARGIN, SHAPE_HORIZONTAL_MARGIN + width - 2 * SHAPE_HORIZONTAL_MARGIN],
  });

  const yScale = scaleLinear({
    domain: [0, MAX_Y],
    range: [SHAPE_VERTICAL_MARGIN, GRAPH_HEIGHT - SHAPE_VERTICAL_MARGIN],
  });

  const initialLinePoints = useMemo(() => {
    const scaledPoints = INITIAL_LINE.map((point) => `${xScale(point[0])},${yScale(point[1])}`);
    const polygonString = scaledPoints.join(' ');
    return polygonString;
  }, [xScale, yScale]);

  const slagLineBeforeRepairPoints = useMemo(() => {
    const scaledPoints = SLAG_LINE_BEFORE_REPAIR.map((point) => `${xScale(point[0])},${yScale(point[1])}`);
    const polygonString = scaledPoints.join(' ');
    return polygonString;
  }, [xScale, yScale]);

  const slagLineAfterRepairPoints = useMemo(() => {
    const scaledPoints = SLAG_LINE_AFTER_REPAIR.map((point) => `${xScale(point[0])},${yScale(point[1])}`);
    const polygonString = scaledPoints.join(' ');
    return polygonString;
  }, [xScale, yScale]);

  const ticks = useCallback(
    (position: 'top' | 'bottom') => {
      const ret = [];
      ret.push(
        <text
          key={`thickness-${ret.length}`}
          x={VERTICAL_SECTIONS_CONTAINER_HORIZONTAL_MARGIN}
          y={position === 'top' ? 0 : TOTAL_GRAPH_HEIGHT}
          textAnchor="end"
          dominantBaseline={position === 'top' ? 'hanging' : 'bottom'}
          fontFamily={settings.typography.FontFamily.Regular}
          fontSize={settings.typography.FontSize.X_Small}
          fill={settings.colors.Primary.Grey_6}
        >
          {`${t('blastFurnaceRunner-report:thickness')} [mm]`}
        </text>
      );
      for (let index = 0; index < VERTICAL_SECTIONS_COUNT; index++) {
        const centerX = VERTICAL_SECTIONS_CONTAINER_HORIZONTAL_MARGIN + index * verticalSectionWidth + verticalSectionWidth / 2;
        ret.push(
          <React.Fragment key={`vertical-${index}`}>
            <text
              x={centerX}
              y={position === 'top' ? 0 : TOTAL_GRAPH_HEIGHT}
              textAnchor="middle"
              dominantBaseline={position === 'top' ? 'hanging' : 'bottom'}
              fontFamily={settings.typography.FontFamily.Regular}
              fontSize={settings.typography.FontSize.X_Small}
              fill={settings.colors.Primary.Grey_6}
            >
              {position === 'top' ? THICKNESS_SIDE_1[index] : THICKNESS_SIDE_0[index]}
            </text>
            <circle cx={centerX} cy={position === 'top' ? 20 : TOTAL_GRAPH_HEIGHT - 20} r="3" fill={settings.colors.Primary.Grey_3} />
            <line
              x1={centerX}
              y1={position === 'top' ? 20 : TOTAL_GRAPH_HEIGHT - 20}
              x2={centerX}
              y2={position === 'top' ? 40 : TOTAL_GRAPH_HEIGHT - 40}
              stroke={settings.colors.Primary.Grey_3}
              strokeWidth={2}
            />
          </React.Fragment>
        );
      }
      return <g id={`thickness-ticks-${position}`}>{ret}</g>;
    },
    [t, verticalSectionWidth]
  );

  const lineColor = line === VesselLine.Slagline ? SLAG_LINE_LINE_COLOR : IRON_LINE_LINE_COLOR;
  const graphLegendLineText = line === VesselLine.Slagline ? t('blastFurnaceRunner-report:slagLine') : t('blastFurnaceRunner-report:ironLine');
  const graphLegendColor = line === VesselLine.Slagline ? settings.colors.Miscellaneous.Purple_Deep : settings.colors.Miscellaneous.Blue;

  return (
    <SWrapper>
      <SGraphBarCharts position="top" verticalSectionWidth={verticalSectionWidth} />
      <SSVGContainer>
        <SSvg ref={svgContainerRef} width="100%" height={TOTAL_GRAPH_HEIGHT}>
          {ticks('top')}
          {ticks('bottom')}
          <g id="inner-graph" transform={`translate(0 ${GRAPH_MARGIN_VERTICAL})`}>
            <rect id="graphBorder" x={0} y={0} width="100%" height={GRAPH_HEIGHT} stroke={settings.colors.Primary.Grey_4} fill="none" />
            <VerticalSections verticalSectionWidth={verticalSectionWidth} />
            <polygon points={slagLineAfterRepairPoints} fill={settings.colors.Primary.Grey_2} />
            <polygon points={initialLinePoints} fill={settings.colors.Primary.Grey_1} stroke={INITIAL_LINE_COLOR} strokeWidth={1} strokeDasharray="4" />
            <polygon points={slagLineBeforeRepairPoints} fill="none" stroke={lineColor} strokeWidth={2} strokeDasharray="4" />
            <polygon points={slagLineAfterRepairPoints} fill="none" stroke={lineColor} strokeWidth={2} />
            <text
              x={4}
              y={12}
              textAnchor="left"
              alignmentBaseline="middle"
              fontFamily={settings.typography.FontFamily.Regular}
              fontSize={settings.typography.FontSize.X_Small}
              fill={settings.colors.Primary.Grey_6}
            >
              {side1Label}
            </text>
            <line
              x1={0}
              y1={SHAPE_VERTICAL_MARGIN}
              x2={width}
              y2={SHAPE_VERTICAL_MARGIN}
              stroke={settings.colors.Operational.State_Alert_Red_2}
              strokeDasharray="2"
            />
            <text
              x={4}
              y={GRAPH_HEIGHT - 8}
              textAnchor="left"
              alignmentBaseline="middle"
              fontFamily={settings.typography.FontFamily.Regular}
              fontSize={settings.typography.FontSize.X_Small}
              fill={settings.colors.Primary.Grey_6}
            >
              {side0Label}
            </text>
            <line
              x1={0}
              y1={GRAPH_HEIGHT - SHAPE_VERTICAL_MARGIN}
              x2={width}
              y2={GRAPH_HEIGHT - SHAPE_VERTICAL_MARGIN}
              stroke={settings.colors.Operational.State_Alert_Red_2}
              strokeDasharray="2"
            />
          </g>
        </SSvg>
        <SLegendContainer>
          <GraphLegend
            legend={{ label: `${graphLegendLineText} ${t('blastFurnaceRunner-report:beforeRepair')}`, color: graphLegendColor, isSolidLine: false }}
          />
          <GraphLegend legend={{ label: `${graphLegendLineText} ${t('blastFurnaceRunner-report:afterRepair')}`, color: graphLegendColor, isSolidLine: true }} />
          <GraphLegend
            legend={{
              label: `${graphLegendLineText} ${t('blastFurnaceRunner-report:measurementReport.measurementReportPanel.headingInitial')}`,
              color: INITIAL_LINE_COLOR,
              isSolidLine: false,
            }}
          />
        </SLegendContainer>
        <SHorizontalToggleButtonsCluster selectedValue={repairMode} onButtonClicked={setRepairMode} size="small-32">
          <HorizontalToggleButtonsCluster.Button value={REPAIR_MODE.BEFORE} title={t('blastFurnaceRunner-report:beforeRepair')} />
          <HorizontalToggleButtonsCluster.Button value={REPAIR_MODE.AFTER} title={t('blastFurnaceRunner-report:afterRepair')} />
        </SHorizontalToggleButtonsCluster>
      </SSVGContainer>
      <SGraphBarCharts position="bottom" verticalSectionWidth={verticalSectionWidth} />
      <SSvgXAxis width="100%" height={18}>
        <AxisBottom top={0} scale={xAxisScale} tickValues={[0, 2, 4, 6, 8, 10]} {...AXIS_STYLES} {...TICK_LABEL_PROPS} />
      </SSvgXAxis>
      <SAxisLabel mode="xAxis">{`${t('blastFurnaceRunner-report:length')} [m]→`}</SAxisLabel>
    </SWrapper>
  );
};

const SWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const SSvg = styled.svg`
  overflow: visible;
  shape-rendering: geometricprecision;
`;

const SSvgXAxis = styled(SSvg)`
  margin-top: ${settings.Spacing.Spacing_300};
`;

const SGraphBarCharts = styled(GraphBarCharts)<{ position: 'top' | 'bottom' }>`
  ${(props) =>
    props.position === 'top' &&
    css`
      margin-bottom: ${settings.Spacing.Spacing_300};
    `}

  ${(props) =>
    props.position === 'bottom' &&
    css`
      margin-top: ${settings.Spacing.Spacing_300};
    `}
`;

const SSVGContainer = styled.div`
  display: flex;
  position: relative;
`;

const SHorizontalToggleButtonsCluster = styled(HorizontalToggleButtonsCluster<REPAIR_MODE>)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const SLegendContainer = styled.div`
  position: absolute;
  top: 50%;
  right: 15%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: end;
`;

SectionCut.whyDidYouRender = true;
export default React.memo(SectionCut);
