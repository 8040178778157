import { getFormattedDate } from '@rhim/react';
import { RHIMFleetOverviewServiceV1ControllersSequenceMeasurementReportDto } from '@rhim/rest/fleetOverview';
import { hasElements, head, last } from '@rhim/utils';

import { apiTonnageForDisplay } from '../utils';

export function useCampaignInfoSummary(allMeasurements: RHIMFleetOverviewServiceV1ControllersSequenceMeasurementReportDto[]) {
  if (!hasElements(allMeasurements)) {
    return '';
  }

  const measurementFirst = head(allMeasurements);
  const measurementLast = last(allMeasurements);
  const tonnageStartTons = apiTonnageForDisplay(measurementFirst.tonnage, 'tons');
  const tonnageEndTons = apiTonnageForDisplay(measurementLast.tonnage, 'tons');
  const dateStart = getFormattedDate({ datetime: measurementFirst.measurementTaken as Date.ISO_8601 });
  const dateEnd = getFormattedDate({ datetime: measurementLast.measurementTaken as Date.ISO_8601 });

  return `${tonnageStartTons} – ${tonnageEndTons}t (${dateStart} – ${dateEnd})`;
}
