import { i18nReact } from '@rhim/i18n';
import { RHIMFleetOverviewServiceV1ControllersMeasurementSequenceDto } from '@rhim/rest/fleetOverview';
import { isDefined } from '@rhim/utils';
import { ScaleLinear } from 'd3-scale';
import React, { useMemo, useRef } from 'react';

import { useMeasurementTupleMaxValue } from '../hooks/useMaxYValue';
import { MeasurementTuple, useMeasurementSequenceTuples } from '../hooks/useMeasurementSequenceTuples';
import { useMeasurementsTonnageKilograms } from '../hooks/useMeasurementsTonnage';
import { useScaleY } from '../hooks/useScaleY';
import { useTooltip } from '../hooks/useTooltip';
import { DEFAULT_GRAPH_X_AXIS_HEIGHT_PX, REPAIR_MIX_AMOUNT_LINE_COLOR } from './constants';
import Graph from './Graph';
import { getBars } from './utils';

const GRAPH_HEIGHT_TOTAL = 90;

interface Props {
  xScale: ScaleLinear<number, number>;
  measurementSequence: RHIMFleetOverviewServiceV1ControllersMeasurementSequenceDto;
}
const GraphRepairMixAmount: React.ChildlessComponent<Props> = ({ xScale, measurementSequence }) => {
  const { t } = i18nReact.useTranslation(['blastFurnaceRunner-report']);
  const propertyName = 'repairMixAmountKilograms';
  const measurementTuples = useMeasurementSequenceTuples(measurementSequence);
  const maxYValueKilograms = useMeasurementTupleMaxValue(measurementTuples, propertyName);
  const maxYValueTons = maxYValueKilograms / 1000;
  const graphAreaHeight = GRAPH_HEIGHT_TOTAL - DEFAULT_GRAPH_X_AXIS_HEIGHT_PX;
  const yScale = useScaleY(graphAreaHeight, 0, maxYValueTons);
  const measurementsAtKilograms = useMeasurementsTonnageKilograms(measurementTuples);
  const svgGraphAreaRef = useRef<SVGRectElement>(null);
  const { tooltipIndicator, tooltipPanel } = useTooltip(svgGraphAreaRef.current, xScale, measurementSequence, `repairMixAmount`);

  const repairMixAmountBars = useMemo(
    () =>
      getBars(
        'repairMixAmount',
        measurementTuples,
        (measurementTuple: MeasurementTuple) => {
          return isDefined(measurementTuple[propertyName]) ? measurementTuple[propertyName] / 1000 : null;
        },
        'center',
        xScale,
        yScale,
        REPAIR_MIX_AMOUNT_LINE_COLOR
      ),
    [measurementTuples, propertyName, xScale, yScale]
  );

  return (
    <Graph
      ref={svgGraphAreaRef}
      graphHeightTotal={GRAPH_HEIGHT_TOTAL}
      xScale={xScale}
      yScale={yScale}
      yAxisLabel={`${t('blastFurnaceRunner-report:campaignReport.graphRepairMixAmount.yAxisLabel')} [t]`}
      graphLegends={[]}
      measurementsAtKilograms={measurementsAtKilograms}
      tooltipPanel={tooltipPanel}
    >
      {tooltipIndicator}
      {repairMixAmountBars}
    </Graph>
  );
};

GraphRepairMixAmount.whyDidYouRender = true;
export default React.memo(GraphRepairMixAmount);
