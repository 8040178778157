import { i18nReact } from '@rhim/i18n';
import React from 'react';
import styled from 'styled-components';

import {
  HORIZONTAL_DIVIDER_STANDARD_HEIGHT_PX,
  ROW_HEIGHT_PX,
  SCellVolume as CellVolume,
  SHeadingBold,
  SHorizontalDividerBold,
  SHorizontalDividerStandard,
  VALUE_LINE_HEIGHT_PX,
} from './styles';

interface Props {
  showSlagLineRow: boolean;
  showIronLineRow: boolean;
  showVolumeRow: boolean;
}
const ColumnFirst: React.ChildlessComponent<Props> = ({ showSlagLineRow, showIronLineRow, showVolumeRow }) => {
  const { t } = i18nReact.useTranslation(['blastFurnaceRunner-report']);

  return (
    <SWrapper>
      <SHorizontalDividerStandard />
      {showSlagLineRow && (
        <SCell>
          <SHeadingBold>{t('blastFurnaceRunner-report:slagLine')}</SHeadingBold>
        </SCell>
      )}
      {showIronLineRow && (
        <>
          {showSlagLineRow && <SHorizontalDividerBold />}
          <SCell>
            <SHeadingBold>{t('blastFurnaceRunner-report:ironLine')}</SHeadingBold>
          </SCell>
        </>
      )}
      {showVolumeRow && (
        <>
          {(showSlagLineRow || showIronLineRow) && <SHorizontalDividerStandard />}
          <SCellVolume>
            <SHeadingVolume>{t('blastFurnaceRunner-report:volume')}</SHeadingVolume>
          </SCellVolume>
        </>
      )}
    </SWrapper>
  );
};

const SWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${ROW_HEIGHT_PX}px;
`;

const SCell = styled.div`
  display: flex;
  align-items: center;
  height: ${2 * ROW_HEIGHT_PX + HORIZONTAL_DIVIDER_STANDARD_HEIGHT_PX}px;
`;

const SHeadingVolume = styled(SHeadingBold)`
  line-height: ${VALUE_LINE_HEIGHT_PX}px;
`;

const SCellVolume = styled(CellVolume)`
  padding: 0;
`;

ColumnFirst.whyDidYouRender = true;
export default React.memo(ColumnFirst);
