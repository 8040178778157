import { Vector3 } from '@babylonjs/core';

import { CameraOrientation, CameraState } from './types';

const cameraStates = new Map<CameraOrientation, { alpha: number; beta: number }>();
cameraStates.set('FRONT', { alpha: -Math.PI / 2, beta: Math.PI / 2 });
cameraStates.set('RIGHT', { alpha: 0, beta: Math.PI / 2 });
cameraStates.set('BACK', { alpha: Math.PI / 2, beta: Math.PI / 2 });
cameraStates.set('LEFT', { alpha: Math.PI, beta: Math.PI / 2 });
cameraStates.set('TOP', { alpha: -Math.PI / 2, beta: 0 });
cameraStates.set('BOTTOM', { alpha: -Math.PI / 2, beta: Math.PI });

export const CAMERA_CONFIG = {
  arcRotate: {
    betaLowerLimit: -Infinity,
    betaUpperLimit: Infinity,
  },
  animation: {
    fps: 60, // frames per second
    duration: 0.5, // in seconds
  },
  cameraStates: cameraStates,
};

export function getDefaultCameraState(isRectangular = false): CameraState {
  const alpha = isRectangular ? Math.PI : -Math.PI / 2;
  return {
    target: Vector3.Zero(),
    radius: 1,
    alpha: alpha,
    beta: 0,
  };
}
