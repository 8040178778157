import { settings } from '@rhim/design';
import React from 'react';
import styled from 'styled-components';

import { useSidesLabels } from '../hooks/useSidesLabels';
import {
  HORIZONTAL_DIVIDER_STANDARD_HEIGHT_PX,
  ROW_HEIGHT_PX,
  SCellVolume as CellVolume,
  SHeadingRegular,
  SHorizontalDividerBold,
  SHorizontalDividerStandard,
  VALUE_LINE_HEIGHT_PX,
} from './styles';

interface Props {
  showSlagLineRow: boolean;
  showIronLineRow: boolean;
  showVolumeRow: boolean;
}
const ColumnSecond: React.ChildlessComponent<Props> = ({ showSlagLineRow, showIronLineRow, showVolumeRow }) => {
  const { side0Label, side1Label } = useSidesLabels();

  return (
    <SWrapper>
      <SHorizontalDividerStandard />
      {showSlagLineRow && (
        <>
          <SCell>
            <SSubCell>
              <SHeadingRegular>{side0Label}</SHeadingRegular>
            </SSubCell>
            <SHorizontalDivider />
            <SSubCell>
              <SHeadingRegular>{side1Label}</SHeadingRegular>
            </SSubCell>
          </SCell>
        </>
      )}
      {showIronLineRow && (
        <>
          {showSlagLineRow && <SHorizontalDividerBold />}
          <SCell>
            <SSubCell>
              <SHeadingRegular>{side0Label}</SHeadingRegular>
            </SSubCell>
            <SHorizontalDivider />
            <SSubCell>
              <SHeadingRegular>{side1Label}</SHeadingRegular>
            </SSubCell>
          </SCell>
        </>
      )}
      {showVolumeRow && (
        <>
          {(showSlagLineRow || showIronLineRow) && <SHorizontalDividerStandard />}
          <SCellVolume>
            <SHeadingVolume>{`h<750mm`} </SHeadingVolume>
          </SCellVolume>
        </>
      )}
    </SWrapper>
  );
};

const SWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${ROW_HEIGHT_PX}px;
`;

const MARGIN_LEFT = settings.Spacing.Spacing_300;
const SCell = styled.div`
  margin-left: ${MARGIN_LEFT};
  display: flex;
  flex-direction: column;
  height: ${2 * ROW_HEIGHT_PX + HORIZONTAL_DIVIDER_STANDARD_HEIGHT_PX}px;
`;

const SCellVolume = styled(CellVolume)`
  margin-left: ${MARGIN_LEFT};
  padding: 0;
`;

const SSubCell = styled.div`
  display: flex;
  align-items: center;
  height: ${ROW_HEIGHT_PX}px;
  flex-shrink: 0;
`;

const SHorizontalDivider = styled(SHorizontalDividerStandard)`
  align-self: stretch;
  flex-shrink: 0;
`;

const SHeadingVolume = styled(SHeadingRegular)`
  line-height: ${VALUE_LINE_HEIGHT_PX}px;
`;

ColumnSecond.whyDidYouRender = true;
export default React.memo(ColumnSecond);
