import { settings } from '@rhim/design';
import { Spinner } from '@rhim/react';
import { getColorUsingScale, isDefined } from '@rhim/utils';
import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';

import { useColorScale } from '../hooks/useColorScale';
import { SSubHeading, VALUE_LINE_HEIGHT_PX } from './styles';
import { ValueAlignment } from './types';

interface Props {
  className?: string;
  label?: string;
  value: undefined | null | number;
  unit: string;
  showColor?: boolean;
  valueAlignment?: ValueAlignment;
}
const ValueWithUnit: React.ChildlessComponent<Props> = ({ className, label, value, unit, showColor = false, valueAlignment = ValueAlignment.Start }) => {
  const colorScale = useColorScale();

  const colorIndicator = useMemo(() => {
    if (showColor && (!isDefined(colorScale) || value === undefined)) {
      return <Spinner size="24" />;
    }
    const color = isDefined(value) && isDefined(colorScale) ? getColorUsingScale(value, colorScale) : null;
    return <SColor fill={showColor ? color : null} />;
  }, [showColor, colorScale, value]);

  return (
    <SWrapper className={className}>
      {isDefined(label) && <SSubHeading>{label}</SSubHeading>}
      <SValueWithUnitContainer valueAlignment={valueAlignment}>
        <SValue>{value ?? '—'}</SValue>
        <SUnit>{unit}</SUnit>
        {colorIndicator}
      </SValueWithUnitContainer>
    </SWrapper>
  );
};

const SWrapper = styled.div`
  display: flex;
  flex-direction: column;
  column-gap: ${settings.Spacing.Spacing_50};
`;

const SValueWithUnitContainer = styled.div<{ valueAlignment: ValueAlignment }>`
  align-self: ${(props) => props.valueAlignment};
  display: flex;
  align-items: baseline;
  column-gap: ${settings.Spacing.Spacing_50};
`;

const SValue = styled.span`
  font-family: ${settings.typography.FontFamily.Bold};
  font-size: ${settings.typography.FontSize.XX_Large};
  color: ${settings.colors.Primary.Grey_8};
  line-height: ${VALUE_LINE_HEIGHT_PX}px;
`;

const SUnit = styled.span`
  font-family: ${settings.typography.FontFamily.Regular};
  font-size: ${settings.typography.FontSize.Medium};
`;

const SColor = styled.span<{ fill: undefined | null | string }>`
  --size: 25px;

  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  ${(props) =>
    isDefined(props.fill) &&
    css`
      background-color: ${props.fill};
      border: 2px solid settings.colors.Monochromatic.White;
    `}
`;

ValueWithUnit.whyDidYouRender = true;
export default React.memo(ValueWithUnit);
