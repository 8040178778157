import { GetMeasurementsequenceMeasurementsVesselidCampaign200ResponseInner } from '@rhim/rest/fleetOverview';
import { specific } from '@rhim/utils';
import { assert, isDefined } from '@rhim/utils';
import { QueryFunctionContext, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { ExpressiveQueryKey } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';

import { API } from '../api/fleetOverview';
import { CAMPAIGN_ID_NOT_SET_ID } from './useSelectedCampaign';

type Payload = GetMeasurementsequenceMeasurementsVesselidCampaign200ResponseInner[];

export function useMeasurementsOfCampaignFleetOverview(
  vesselId?: string,
  campaignId?: number,
  options?: {
    configuration?: Omit<UseQueryOptions<Payload, unknown, Payload, ReturnType<typeof getKey>>, 'queryKey' | 'queryFn'>;
    axiosConfiguration?: AxiosRequestConfig;
  }
) {
  return useQuery(getKey(vesselId, campaignId), (context) => queryFn(context, options?.axiosConfiguration), {
    ...options?.configuration,
    /**
     * Dependent query : to get the Measurements of a Campaign of a Vessel, we have to have :
     * 1) a defined vesselId.
     * 2) a defined campaignId
     */
    enabled: isDefined(vesselId) && isDefined(campaignId) && campaignId !== CAMPAIGN_ID_NOT_SET_ID,
  });
}

const getKey = (vesselId?: string, campaignId?: number) =>
  specific<ExpressiveQueryKey>()([
    {
      domain: 'measurementService',
      scope: 'measurements',
      entity: 'list',
      vesselId,
      campaignId,
    },
  ]);

const queryFn = (
  { queryKey: [{ vesselId, campaignId }], signal }: QueryFunctionContext<ReturnType<typeof getKey>>,
  axiosConfiguration?: AxiosRequestConfig
) => {
  assert(isDefined(vesselId), 'This should be called only when `vesselId` is defined. See the `enabled` option of useMeasurementOfCampaigns');
  assert(isDefined(campaignId), 'This should be called only when `campaignId` is defined. See the `enabled` option of useMeasurementOfCampaigns');

  return API.measurementSequenceApi
    .getMeasurementsequenceMeasurementsVesselidCampaign(vesselId, campaignId, { signal, ...axiosConfiguration })
    .then((response) => response.data);
};

useMeasurementsOfCampaignFleetOverview.getKey = getKey;
useMeasurementsOfCampaignFleetOverview.queryFn = queryFn;
