import { settings } from '@rhim/design';
import { toggleButtonMeasurementView } from '@rhim/test-ids';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import { Spinner } from '../Spinner';

export const SELECTED_BACKGROUND_COLOR = settings.colors.Primary.Blue_9;

interface ToggleButtonProps<T> {
  value: T;
  title: string;
  isInProgress?: boolean;
}
interface InjectedToggleButtonProps {
  size?: number;
  isSelected?: boolean;
  onClick?: () => void;
}
type MergedToggleButtonProps<T> = ToggleButtonProps<T> & InjectedToggleButtonProps;
function ToggleButton<T>(props: MergedToggleButtonProps<T>) {
  const { title, isInProgress = false, isSelected = false, size = 40, onClick } = props;

  return (
    <SButton type="button" isSelected={isSelected} onClick={onClick} size={size}>
      {isInProgress ? <Spinner size="24" rotationDuration={2.5} inverted={true} /> : title}
    </SButton>
  );
}

interface HorizontalToggleButtonsClusterProps<T> {
  className?: string;
  selectedValue: T;
  onButtonClicked: (value: T) => void;
  size?: 'small-32' | 'large-40';
  children: React.ReactNode;
}
function HorizontalToggleButtonsCluster<T>(props: HorizontalToggleButtonsClusterProps<T>) {
  const { className, selectedValue, onButtonClicked, size = 'large-40', children } = props;

  const sizePx = useMemo(() => {
    switch (size) {
      case 'small-32':
        return 32;
      case 'large-40':
        return 40;
    }
  }, [size]);

  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement<MergedToggleButtonProps<T>>(child)) {
      return React.cloneElement<MergedToggleButtonProps<T>>(child, {
        size: sizePx,
        isSelected: child.props.value === selectedValue,
        onClick: () => {
          onButtonClicked(child.props.value);
        },
      });
    }
    return child;
  });

  return (
    <SWrapper className={className} data-test-id={toggleButtonMeasurementView}>
      {childrenWithProps}
    </SWrapper>
  );
}

const SWrapper = styled.div`
  display: flex;
`;

const SButton = styled.button<{ isSelected: boolean; size: number }>`
  display: flex;
  align-items: center;
  line-height: inherit;
  cursor: pointer;
  height: ${(props) => props.size}px;
  padding: ${settings.Spacing.Spacing_100} ${settings.Spacing.Spacing_200};
  color: ${(props) => (props.isSelected ? 'white' : settings.colors.Primary.Grey_6)};
  background-color: ${(props) => (props.isSelected ? SELECTED_BACKGROUND_COLOR : 'white')};
  font-family: ${settings.typography.FontFamily.Medium};
  font-size: ${settings.typography.FontSize.Small};
  border: 1px solid ${(props) => (props.isSelected ? SELECTED_BACKGROUND_COLOR : settings.colors.Primary.Grey_3)};
  z-index: ${(props) => (props.isSelected ? 1 : 0)};
  white-space: nowrap;
  user-select: none;

  --borderRadius: 3px;

  &:first-child {
    border-top-left-radius: var(--borderRadius);
    border-bottom-left-radius: var(--borderRadius);
  }

  &:last-child {
    border-top-right-radius: var(--borderRadius);
    border-bottom-right-radius: var(--borderRadius);
  }

  &:not(:first-of-type) {
    margin-left: -1px;
  }
`;

HorizontalToggleButtonsCluster.whyDidYouRender = true;
HorizontalToggleButtonsCluster.Button = React.memo(ToggleButton) as typeof ToggleButton;
export default HorizontalToggleButtonsCluster;
