import { settings } from '@rhim/design';
import { RHIMFleetOverviewServiceV1ControllersMeasurementSequenceDto } from '@rhim/rest/fleetOverview';
import { isDefined } from '@rhim/utils';
import React from 'react';
import styled from 'styled-components';

import ColumnAfterRepair from './ColumnAfterRepair';
import ColumnBeforeRepair from './ColumnBeforeRepair';
import ColumnFirst from './ColumnFirst';
import ColumnInitial from './ColumnInitial';
import ColumnLast from './ColumnLast';
import ColumnSecond from './ColumnSecond';

interface Props {
  measurementSequence: RHIMFleetOverviewServiceV1ControllersMeasurementSequenceDto;
  selectedTonnageKilograms: number;
}
const MeasurementReportSummary: React.ChildlessComponent<Props> = ({ measurementSequence, selectedTonnageKilograms }) => {
  const initialMeasurement = measurementSequence.wearLiningInitialMeasurement ?? measurementSequence.permanentLiningMeasurement;
  const measurementTupple = measurementSequence.measurementTuples.find((measurementTuple) => measurementTuple.tonnage === selectedTonnageKilograms);

  // INITIAL MEASUREMENT (WEAR-LINING-INITIAL OR PERMANENT-LINING)
  const initialSlagLineSide0Rbl = initialMeasurement?.side0SlaglineRbl;
  const initialSlagLineSide1Rbl = initialMeasurement?.side1SlaglineRbl;
  const initialIronLineSide0Rbl = initialMeasurement?.side0IronlineRbl;
  const initialIronLineSide1Rbl = initialMeasurement?.side1IronlineRbl;
  const initialVolume = initialMeasurement?.volume;

  // WORKING-LINING MEASUREMENT
  const workingLiningSlagLineSide0Rbl = measurementTupple?.workingLiningMeasurement?.side0SlaglineRbl;
  const workingLiningSlagLineSide1Rbl = measurementTupple?.workingLiningMeasurement?.side1SlaglineRbl;
  const workingLiningIronLineSide0Rbl = measurementTupple?.workingLiningMeasurement?.side0IronlineRbl;
  const workingLiningIronLineSide1Rbl = measurementTupple?.workingLiningMeasurement?.side1IronlineRbl;
  const workingLiningVolume = measurementTupple?.workingLiningMeasurement?.volume;
  const workingLiningSlagLineSide0WearRate = measurementTupple?.workingLiningMeasurement?.side0SlaglineWearRate;
  const workingLiningSlagLineSide1WearRate = measurementTupple?.workingLiningMeasurement?.side1SlaglineWearRate;
  const workingLiningIronLineSide0WearRate = measurementTupple?.workingLiningMeasurement?.side0IronlineWearRate;
  const workingLiningIronLineSide1WearRate = measurementTupple?.workingLiningMeasurement?.side1IronlineWearRate;
  const workingLiningVolumeChangeRate = measurementTupple?.workingLiningMeasurement?.volumeChangeRate;

  // AFTER-REPAIR MEASUREMENT
  const afterRepairSlagLineSide0Rbl = measurementTupple?.afterRepairMeasurement?.side0SlaglineRbl;
  const afterRepairSlagLineSide1Rbl = measurementTupple?.afterRepairMeasurement?.side1SlaglineRbl;
  const afterRepairIronLineSide0Rbl = measurementTupple?.afterRepairMeasurement?.side0IronlineRbl;
  const afterRepairIronLineSide1Rbl = measurementTupple?.afterRepairMeasurement?.side1IronlineRbl;
  const afterRepairVolume = measurementTupple?.afterRepairMeasurement?.volume;

  const showSlagLineRow =
    isDefined(initialSlagLineSide0Rbl) ||
    isDefined(initialSlagLineSide1Rbl) ||
    isDefined(workingLiningSlagLineSide0Rbl) ||
    isDefined(workingLiningSlagLineSide1Rbl) ||
    isDefined(afterRepairSlagLineSide0Rbl) ||
    isDefined(afterRepairSlagLineSide1Rbl) ||
    isDefined(workingLiningSlagLineSide0WearRate);
  const showIronLineRow =
    isDefined(initialIronLineSide0Rbl) ||
    isDefined(initialIronLineSide1Rbl) ||
    isDefined(workingLiningIronLineSide0Rbl) ||
    isDefined(workingLiningIronLineSide1Rbl) ||
    isDefined(afterRepairIronLineSide0Rbl) ||
    isDefined(afterRepairIronLineSide1Rbl) ||
    isDefined(workingLiningIronLineSide0WearRate);
  const showVolumeRow = isDefined(initialVolume) || isDefined(workingLiningVolume) || isDefined(afterRepairVolume) || isDefined(workingLiningVolumeChangeRate);

  const hasWorkingLiningColumn = isDefined(measurementTupple) && isDefined(measurementTupple.workingLiningMeasurement);
  const hasAfterRepairColumn = isDefined(measurementTupple) && isDefined(measurementTupple.afterRepairMeasurement);

  return (
    <SWrapper>
      <ColumnFirst showSlagLineRow={showSlagLineRow} showIronLineRow={showIronLineRow} showVolumeRow={showVolumeRow} />
      <ColumnSecond showSlagLineRow={showSlagLineRow} showIronLineRow={showIronLineRow} showVolumeRow={showVolumeRow} />
      <ColumnInitial
        slagLineSide0Rbl={initialSlagLineSide0Rbl}
        slagLineSide1Rbl={initialSlagLineSide1Rbl}
        ironLineSide0Rbl={initialIronLineSide0Rbl}
        ironLineSide1Rbl={initialIronLineSide1Rbl}
        volume={initialVolume}
        showSlagLineRow={showSlagLineRow}
        showIronLineRow={showIronLineRow}
        showVolumeRow={showVolumeRow}
      />
      {(hasWorkingLiningColumn || hasAfterRepairColumn) && (
        <SContainer>
          {hasWorkingLiningColumn && (
            <ColumnBeforeRepair
              slagLineSide0Rbl={workingLiningSlagLineSide0Rbl}
              slagLineSide1Rbl={workingLiningSlagLineSide1Rbl}
              ironLineSide0Rbl={workingLiningIronLineSide0Rbl}
              ironLineSide1Rbl={workingLiningIronLineSide1Rbl}
              volume={workingLiningVolume}
              showSlagLineRow={showSlagLineRow}
              showIronLineRow={showIronLineRow}
              showVolumeRow={showVolumeRow}
            />
          )}
          {hasAfterRepairColumn && (
            <ColumnAfterRepair
              slagLineSide0Rbl={afterRepairSlagLineSide0Rbl}
              slagLineSide1Rbl={afterRepairSlagLineSide1Rbl}
              ironLineSide0Rbl={afterRepairIronLineSide0Rbl}
              ironLineSide1Rbl={afterRepairIronLineSide1Rbl}
              volume={afterRepairVolume}
              showSlagLineRow={showSlagLineRow}
              showIronLineRow={showIronLineRow}
              showVolumeRow={showVolumeRow}
              showDeltaColumn={hasWorkingLiningColumn}
              workingLiningSlagLineSide0Rbl={workingLiningSlagLineSide0Rbl}
              workingLiningSlagLineSide1Rbl={workingLiningSlagLineSide1Rbl}
              workingLiningIronLineSide0Rbl={workingLiningIronLineSide0Rbl}
              workingLiningIronLineSide1Rbl={workingLiningIronLineSide1Rbl}
              workingLiningVolume={workingLiningVolume}
            />
          )}
        </SContainer>
      )}
      {hasWorkingLiningColumn && (
        <ColumnLast
          slagLineSide0WearRate={workingLiningSlagLineSide0WearRate}
          slagLineSide1WearRate={workingLiningSlagLineSide1WearRate}
          ironLineSide0WearRate={workingLiningIronLineSide0WearRate}
          ironLineSide1WearRate={workingLiningIronLineSide1WearRate}
          volumeChangeRate={workingLiningVolumeChangeRate}
          showSlagLineRow={showSlagLineRow}
          showIronLineRow={showIronLineRow}
          showVolumeRow={showVolumeRow}
        />
      )}
    </SWrapper>
  );
};

const SWrapper = styled.div`
  background-color: ${settings.colors.Monochromatic.White};
  border-radius: 5px;
  padding: ${settings.Spacing.Spacing_200};
  display: flex;
`;

const SContainer = styled.div`
  display: flex;
  outline: 1px solid ${settings.colors.Primary.Grey_3};
  border-radius: 3px;
`;

MeasurementReportSummary.whyDidYouRender = true;
export default React.memo(MeasurementReportSummary);
