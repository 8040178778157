import { i18nReact } from '@rhim/i18n';
import { RHIMFleetOverviewServiceV1ControllersMeasurementSequenceDto } from '@rhim/rest/fleetOverview';
import { assert, ensure, isDefined } from '@rhim/utils';
import { ScaleLinear } from 'd3-scale';
import React, { useMemo, useRef } from 'react';

import { MeasurementPropertyName } from '../hooks/useMaxYValue';
import { MeasurementTuple, useMeasurementSequenceTuples } from '../hooks/useMeasurementSequenceTuples';
import { useMeasurementsTonnageKilograms } from '../hooks/useMeasurementsTonnage';
import { useScaleY } from '../hooks/useScaleY';
import { useSidesLegends } from '../hooks/useSidesLegends';
import { useTooltip } from '../hooks/useTooltip';
import { VesselLine } from '../types';
import { DEFAULT_GRAPH_AREA_HEIGHT, DEFAULT_GRAPH_HEIGHT_TOTAL, SIDE_0_LINE_COLOR, SIDE_1_LINE_COLOR } from './constants';
import Graph from './Graph';
import { getRBLPropertyName } from './GraphRemainingThickness';
import { getBars } from './utils';

function valueAccessor(propertyName: MeasurementPropertyName) {
  return (measurementTuple: MeasurementTuple) => {
    const afterRepairRBL = ensure(measurementTuple.afterRepairMeasurement)[propertyName];
    const workingLiningRBL = ensure(measurementTuple.workingLiningMeasurement)[propertyName];
    const deltaRBL = ensure(afterRepairRBL) - ensure(workingLiningRBL);
    return deltaRBL;
  };
}

interface Props {
  xScale: ScaleLinear<number, number>;
  measurementSequence: RHIMFleetOverviewServiceV1ControllersMeasurementSequenceDto;
  line: VesselLine;
}
const GraphDeltaRemainingBrickLength: React.ChildlessComponent<Props> = ({ xScale, measurementSequence, line }) => {
  const { t } = i18nReact.useTranslation(['blastFurnaceRunner-report']);
  const sidesLegends = useSidesLegends();
  const measurementTuples = useMeasurementSequenceTuples(measurementSequence);
  const side0PropertyName = getRBLPropertyName('side0', line);
  const side1PropertyName = getRBLPropertyName('side1', line);

  const filteredMeasurementTuples = useMemo(() => {
    return measurementTuples.filter((measurementTuple) => {
      // Ignore tuple unless we have both a workingLining & afterRepair measurements defined
      if (!isDefined(measurementTuple.workingLiningMeasurement) || !isDefined(measurementTuple.afterRepairMeasurement)) {
        return false;
      }
      // Ignore tuple unless we have non-null values for both workingLining & afterRepair
      if (
        !(isDefined(measurementTuple.workingLiningMeasurement[side0PropertyName]) && isDefined(measurementTuple.afterRepairMeasurement[side0PropertyName])) &&
        !(isDefined(measurementTuple.workingLiningMeasurement[side1PropertyName]) && isDefined(measurementTuple.afterRepairMeasurement[side1PropertyName]))
      ) {
        return false;
      }
      return true;
    });
  }, [measurementTuples, side0PropertyName, side1PropertyName]);

  const deltaRBLDomain = useMemo(() => {
    const ret: [number, number] = [Number.POSITIVE_INFINITY, Number.NEGATIVE_INFINITY];
    const propertyNames = [side0PropertyName, side1PropertyName];
    for (const measurementTuple of filteredMeasurementTuples) {
      for (const propertyName of propertyNames) {
        assert(isDefined(measurementTuple.afterRepairMeasurement), `AfterRepair measurement in tuple at tonnage ${measurementTuple} not set`);
        assert(isDefined(measurementTuple.workingLiningMeasurement), `WorkingLining measurement in tuple at tonnage ${measurementTuple} not set`);
        const afterRepairRBL = measurementTuple.afterRepairMeasurement[propertyName];
        const workingLiningRBL = measurementTuple.workingLiningMeasurement[propertyName];
        assert(isDefined(afterRepairRBL), `AfterRepair measurement's ${propertyName}'s value not set`);
        assert(isDefined(workingLiningRBL), `WorkingLining measurement's ${propertyName}'s value not set`);
        const deltaRBL = afterRepairRBL - workingLiningRBL;
        ret[0] = Math.min(deltaRBL, ret[0]);
        ret[1] = Math.max(deltaRBL, ret[1]);
      }
    }
    return ret;
  }, [filteredMeasurementTuples, side0PropertyName, side1PropertyName]);

  const yScale = useScaleY(DEFAULT_GRAPH_AREA_HEIGHT, deltaRBLDomain[0], deltaRBLDomain[1]);
  const measurementsAtKilograms = useMeasurementsTonnageKilograms(measurementTuples);
  const svgGraphAreaRef = useRef<SVGRectElement>(null);
  const { tooltipIndicator, tooltipPanel } = useTooltip(svgGraphAreaRef.current, xScale, measurementSequence, `thicknessDifference${line}`);

  const side0Bar = useMemo(
    () => getBars('deltaRBLSide0', filteredMeasurementTuples, valueAccessor(side0PropertyName), 'leftAligned', xScale, yScale, SIDE_0_LINE_COLOR),
    [filteredMeasurementTuples, side0PropertyName, xScale, yScale]
  );
  const side1Bar = useMemo(
    () => getBars('deltaRBLSide1', filteredMeasurementTuples, valueAccessor(side1PropertyName), 'rightAligned', xScale, yScale, SIDE_1_LINE_COLOR),
    [filteredMeasurementTuples, side1PropertyName, xScale, yScale]
  );

  return (
    <Graph
      ref={svgGraphAreaRef}
      graphHeightTotal={DEFAULT_GRAPH_HEIGHT_TOTAL}
      xScale={xScale}
      yScale={yScale}
      yAxisLabel={`↑${t('blastFurnaceRunner-report:campaignReport.graphRBLDelta.yAxisLabel')} [mm]`}
      graphLegends={sidesLegends}
      measurementsAtKilograms={measurementsAtKilograms}
      tooltipPanel={tooltipPanel}
    >
      {tooltipIndicator}
      {side0Bar}
      {side1Bar}
    </Graph>
  );
};

GraphDeltaRemainingBrickLength.whyDidYouRender = true;
export default React.memo(GraphDeltaRemainingBrickLength);
