import { isDefined } from '@rhim/utils';
import React from 'react';
import styled from 'styled-components';

import { SHeadingBold, SHeadingBoldLarge, SSubHeading } from './styles';

interface Props {
  className?: string;
  heading?: string;
  isHeadingLarge?: boolean;
  subHeading?: string;
}
const Heading: React.ChildlessComponent<Props> = ({ className, heading, isHeadingLarge = false, subHeading }) => {
  return (
    <SWrapper className={className}>
      {isDefined(heading) && !isHeadingLarge && <SHeadingBold>{heading}</SHeadingBold>}
      {isDefined(heading) && isHeadingLarge && <SHeadingBoldLarge>{heading}</SHeadingBoldLarge>}
      {isDefined(subHeading) && <SSubHeading>{subHeading}</SSubHeading>}
    </SWrapper>
  );
};

const SWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

Heading.whyDidYouRender = true;
export default React.memo(Heading);
