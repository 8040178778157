import { settings } from '@rhim/design';
import { i18nReact } from '@rhim/i18n';
import { isDefined } from '@rhim/utils';
import React from 'react';
import styled from 'styled-components';

import { UNIT_CUBIC_METERS, UNIT_MM } from '../components/constants';
import Heading from './Heading';
import { COLUMN_HORIZONTAL_PADDING_PX, ROW_HEIGHT_PX, SCellVolume, SHorizontalDividerBold, SHorizontalDividerStandard } from './styles';
import { ColumnRblAndVolume, ValueAlignment } from './types';
import ValueWithUnit from './ValueWithUnit';

interface EntryProps {
  valueLabel?: string;
  value: undefined | null | number;
  showDeltaColumn: boolean;
  deltaLabel?: string;
  delta: undefined | number;
  unit: string;
  showColor?: boolean;
  valueAlignment?: ValueAlignment;
}
const Entry: React.ChildlessComponent<EntryProps> = ({ valueLabel, value, showDeltaColumn, deltaLabel, delta, unit, showColor = true, valueAlignment }) => {
  return (
    <SEntryWrapper>
      <ValueWithUnit label={valueLabel} value={value} unit={unit} showColor={showColor} />
      <SHorizontalSpring />
      {showDeltaColumn && <SValueWithUnit label={deltaLabel} value={delta} unit={unit} valueAlignment={valueAlignment} />}
    </SEntryWrapper>
  );
};

interface Props extends ColumnRblAndVolume {
  showDeltaColumn: boolean;
  workingLiningSlagLineSide0Rbl: undefined | null | number;
  workingLiningSlagLineSide1Rbl: undefined | null | number;
  workingLiningIronLineSide0Rbl: undefined | null | number;
  workingLiningIronLineSide1Rbl: undefined | null | number;
  workingLiningVolume: undefined | null | number;
}
const ColumnAfterRepair: React.ChildlessComponent<Props> = ({
  slagLineSide0Rbl,
  slagLineSide1Rbl,
  ironLineSide0Rbl,
  ironLineSide1Rbl,
  volume,
  showSlagLineRow,
  showIronLineRow,
  showVolumeRow,
  showDeltaColumn,
  workingLiningSlagLineSide0Rbl,
  workingLiningSlagLineSide1Rbl,
  workingLiningIronLineSide0Rbl,
  workingLiningIronLineSide1Rbl,
  workingLiningVolume,
}) => {
  const { t } = i18nReact.useTranslation(['blastFurnaceRunner-report']);

  const slagLineSide0DeltaRBL =
    showDeltaColumn && isDefined(slagLineSide0Rbl) && isDefined(workingLiningSlagLineSide0Rbl) ? slagLineSide0Rbl - workingLiningSlagLineSide0Rbl : undefined;
  const slagLineSide1DeltaRBL =
    showDeltaColumn && isDefined(slagLineSide1Rbl) && isDefined(workingLiningSlagLineSide1Rbl) ? slagLineSide1Rbl - workingLiningSlagLineSide1Rbl : undefined;
  const ironLineSide0DeltaRBL =
    showDeltaColumn && isDefined(ironLineSide0Rbl) && isDefined(workingLiningIronLineSide0Rbl) ? ironLineSide0Rbl - workingLiningIronLineSide0Rbl : undefined;
  const ironLineSide1DeltaRBL =
    showDeltaColumn && isDefined(ironLineSide1Rbl) && isDefined(workingLiningIronLineSide1Rbl) ? ironLineSide1Rbl - workingLiningIronLineSide1Rbl : undefined;
  const volumeDelta = showDeltaColumn && isDefined(volume) && isDefined(workingLiningVolume) ? volume - workingLiningVolume : undefined;

  return (
    <SWrapper>
      <SContainer>
        <SCell>
          <SHeaderContainer>
            <Heading
              heading={t('blastFurnaceRunner-report:afterRepair')}
              isHeadingLarge={false}
              subHeading={t('blastFurnaceRunner-report:measurementReport.measurementReportPanel.subHeadingMinimumRBL')}
            />
            {showDeltaColumn && <Heading subHeading={t('blastFurnaceRunner-report:measurementReport.measurementReportPanel.subHeadingThicknessDelta')} />}
          </SHeaderContainer>
        </SCell>
        <SHorizontalDividerStandard />
        {showSlagLineRow && (
          <>
            <SCell>
              <Entry value={slagLineSide0Rbl} showDeltaColumn={showDeltaColumn} delta={slagLineSide0DeltaRBL} unit={UNIT_MM} />
            </SCell>
            <SHorizontalDividerStandard />
            <SCell>
              <Entry value={slagLineSide1Rbl} showDeltaColumn={showDeltaColumn} delta={slagLineSide1DeltaRBL} unit={UNIT_MM} />
            </SCell>
          </>
        )}
        {showIronLineRow && (
          <>
            {showSlagLineRow && <SHorizontalDividerBold />}
            <SCell>
              <Entry value={ironLineSide0Rbl} showDeltaColumn={showDeltaColumn} delta={ironLineSide0DeltaRBL} unit={UNIT_MM} />
            </SCell>
            <SHorizontalDividerStandard />
            <SCell>
              <Entry value={ironLineSide1Rbl} showDeltaColumn={showDeltaColumn} delta={ironLineSide1DeltaRBL} unit={UNIT_MM} />
            </SCell>
          </>
        )}
        {showVolumeRow && (
          <>
            {(showSlagLineRow || showIronLineRow) && <SHorizontalDividerStandard />}
            <SCellVolume>
              <Entry
                valueLabel={t('blastFurnaceRunner-report:volume')}
                value={volume}
                showDeltaColumn={showDeltaColumn}
                delta={volumeDelta}
                deltaLabel={`${t('blastFurnaceRunner-report:volume')} ∆`}
                unit={UNIT_CUBIC_METERS}
                showColor={false}
                valueAlignment={ValueAlignment.End}
              />
            </SCellVolume>
          </>
        )}
      </SContainer>
    </SWrapper>
  );
};

const SWrapper = styled.div`
  display: flex;
`;

const SContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SCell = styled.div`
  display: flex;
  align-items: center;
  height: ${ROW_HEIGHT_PX}px;
  padding: 0 ${COLUMN_HORIZONTAL_PADDING_PX}px;
`;

const SValueWithUnit = styled(ValueWithUnit)`
  margin-left: ${settings.Spacing.Spacing_600};
`;

const SEntryWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
`;

const SHeaderContainer = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: end;
  justify-content: space-between;
`;

const SHorizontalSpring = styled.span`
  flex-grow: 1;
`;

ColumnAfterRepair.whyDidYouRender = true;
export default React.memo(ColumnAfterRepair);
