import { settings } from '@rhim/design';
import styled from 'styled-components';

export const ROW_HEIGHT_PX = 60;
export const HORIZONTAL_DIVIDER_STANDARD_HEIGHT_PX = 1;
export const VOLUME_ROW_HEIGHT = 80;
export const VALUE_LINE_HEIGHT_PX = 50;
export const COLUMN_HORIZONTAL_PADDING_PX = 24;

export const SHeadingRegular = styled.span`
  font-family: ${settings.typography.FontFamily.Regular};
  font-size: ${settings.typography.FontSize.Medium};
  color: ${settings.colors.Primary.Grey_7};
  line-height: ${settings.typography.LineHeight.Line_Height_14};
`;

export const HEADING_BOLD_LINE_HEIGHT = settings.typography.LineHeight.Line_Height_24;
export const SHeadingBold = styled(SHeadingRegular)`
  font-family: ${settings.typography.FontFamily.Bold};
  line-height: ${HEADING_BOLD_LINE_HEIGHT};
`;

export const SHeadingRegularLarge = styled(SHeadingRegular)`
  font-size: ${settings.typography.FontSize.Large};
`;

export const SHeadingBoldLarge = styled(SHeadingBold)`
  font-size: ${settings.typography.FontSize.Large};
`;

export const SHorizontalDividerStandard = styled.div`
  align-self: stretch;
  height: ${HORIZONTAL_DIVIDER_STANDARD_HEIGHT_PX}px;
  background-color: ${settings.colors.Primary.Grey_3};
`;

export const SSubHeading = styled(SHeadingRegular)`
  font-family: ${settings.typography.FontFamily.Regular};
  font-size: ${settings.typography.FontSize.Small};
`;

export const SHorizontalDividerBold = styled.div`
  align-self: stretch;
  height: 2px;
  background-color: ${settings.colors.Primary.Grey_4};
`;

export const SCellVolume = styled.div`
  display: flex;
  align-items: end;
  height: ${VOLUME_ROW_HEIGHT}px;
  padding: 0 ${COLUMN_HORIZONTAL_PADDING_PX}px;
`;
