import { Scene } from '@babylonjs/core';
import { threeDCanvasContainerViewMeasurementView } from '@rhim/test-ids';
import { assert, isDefined } from '@rhim/utils';
import React, { FC, useContext, useEffect, useRef } from 'react';
import { useMeasure } from 'react-use';
import styled from 'styled-components';

import { Scene3dContext } from './Scene3d/Scene3dContext';

interface Props {
  // engineId: string;
  onSceneMount?(canvas: HTMLCanvasElement, isNewEngine: boolean, scene: Scene): void;
}

const BabylonScene: FC<React.PropsWithChildren<Props>> = ({ onSceneMount }) => {
  const [containerRef, size] = useMeasure<HTMLDivElement>();
  const domElementWrapper = useRef<HTMLDivElement>(null);
  const scene3DApi = useContext(Scene3dContext);

  // Resize the babylon engine whenever the component gets resized
  useEffect(() => {
    scene3DApi.resize();
  }, [scene3DApi, size]);

  useEffect(() => {
    if (domElementWrapper.current) {
      const canvas = scene3DApi.getEngine().getRenderingCanvas();
      const scene = scene3DApi.getScene();
      assert(isDefined(scene), 'Engine has no scene');
      assert(isDefined(canvas), 'Engine canvas not set');
      // append the engine's canvas to the DOM
      domElementWrapper.current.appendChild(canvas);
      onSceneMount?.(canvas, false, scene);
    }
  }, [onSceneMount, scene3DApi]);

  return (
    <SContainer data-test-id={threeDCanvasContainerViewMeasurementView} ref={containerRef}>
      <SWrapper ref={domElementWrapper} />
    </SContainer>
  );
};
BabylonScene.whyDidYouRender = true;
export default React.memo(BabylonScene);

const SContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const SWrapper = styled.div`
  width: 100%;
  height: 100%;

  canvas {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;

    &:focus {
      outline: none;
    }
  }
`;
