import { RHIMFleetOverviewServiceV1ModelsCampaignDto } from '@rhim/rest/fleetOverview';
import { specific } from '@rhim/utils';
import { isDefined } from '@rhim/utils';
import { QueryFunctionContext, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { ExpressiveQueryKey } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';

import { API } from '../api/fleetOverview';

type Payload = RHIMFleetOverviewServiceV1ModelsCampaignDto[];

export function useCampaignsOfVesselFleetOverview(
  vesselId?: string,
  options?: {
    configuration?: Omit<UseQueryOptions<Payload, unknown, Payload, ReturnType<typeof getKey>>, 'queryKey' | 'queryFn'>;
    axiosConfiguration?: AxiosRequestConfig;
  }
) {
  return useQuery(getKey(vesselId), (context) => queryFn(context, options?.axiosConfiguration), {
    ...options?.configuration,
    // Dependent query : to get the campaigns of a vessel, we have to have the vessel's id in question.
    // Do not execute until this is provided.
    enabled: isDefined(vesselId),
  });
}

const getKey = (vesselId?: string) =>
  specific<ExpressiveQueryKey>()([
    {
      domain: 'measurementService',
      scope: 'campaigns',
      entity: 'list',
      vesselId,
    },
  ]);

const queryFn = ({ queryKey: [{ vesselId }], signal }: QueryFunctionContext<ReturnType<typeof getKey>>, axiosConfiguration?: AxiosRequestConfig) => {
  return API.measurementSequenceApi.getMeasurementsequenceCampaignsVesselid(vesselId ?? '', { signal, ...axiosConfiguration }).then((response) => {
    const campaignsArray = response.data;
    campaignsArray.sort((campaignA, campaignB) => campaignB.id - campaignA.id);
    return campaignsArray;
  });
};

useCampaignsOfVesselFleetOverview.getKey = getKey;
useCampaignsOfVesselFleetOverview.queryFn = queryFn;
