import { i18nReact } from '@rhim/i18n';
import React from 'react';
import styled from 'styled-components';

import { UNIT_CUBIC_METERS, UNIT_MM_PER_TON, UNIT_TON } from '../components/constants';
import Heading from './Heading';
import {
  COLUMN_HORIZONTAL_PADDING_PX,
  HEADING_BOLD_LINE_HEIGHT,
  ROW_HEIGHT_PX,
  SCellVolume,
  SHorizontalDividerBold,
  SHorizontalDividerStandard,
} from './styles';
import { ColumnWearRateAndVolume } from './types';
import ValueWithUnit from './ValueWithUnit';

const ColumnLast: React.ChildlessComponent<ColumnWearRateAndVolume> = ({
  slagLineSide0WearRate,
  slagLineSide1WearRate,
  ironLineSide0WearRate,
  ironLineSide1WearRate,
  volumeChangeRate,
  showSlagLineRow,
  showIronLineRow,
  showVolumeRow,
}) => {
  const { t } = i18nReact.useTranslation(['blastFurnaceRunner-report']);

  return (
    <SWrapper>
      <SCell>
        <SHeaderContainer>
          <SPlaceholder />
          <Heading subHeading={t('blastFurnaceRunner-report:measurementReport.measurementReportPanel.subHeadingWearRate')} />
        </SHeaderContainer>
      </SCell>
      <SHorizontalDividerStandard />
      {showSlagLineRow && (
        <>
          <SCell>
            <ValueWithUnit value={slagLineSide0WearRate} unit={UNIT_MM_PER_TON} />
          </SCell>
          <SHorizontalDividerStandard />
          <SCell>
            <ValueWithUnit value={slagLineSide1WearRate} unit={UNIT_MM_PER_TON} />
          </SCell>
        </>
      )}
      {showIronLineRow && (
        <>
          {showSlagLineRow && <SHorizontalDividerBold />}
          <SCell>
            <ValueWithUnit value={ironLineSide0WearRate} unit={UNIT_MM_PER_TON} />
          </SCell>
          <SHorizontalDividerStandard />
          <SCell>
            <ValueWithUnit value={ironLineSide1WearRate} unit={UNIT_MM_PER_TON} />
          </SCell>
        </>
      )}
      {showVolumeRow && (
        <>
          {(showSlagLineRow || showIronLineRow) && <SHorizontalDividerStandard />}
          <SCellVolume>
            <ValueWithUnit
              label={t('blastFurnaceRunner-report:measurementReport.measurementReportPanel.subHeadingVolumeChangeRate')}
              value={volumeChangeRate}
              unit={`${UNIT_CUBIC_METERS}/${UNIT_TON}`}
            />
          </SCellVolume>
        </>
      )}
    </SWrapper>
  );
};

const SWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const SCell = styled.div`
  display: flex;
  align-items: center;
  height: ${ROW_HEIGHT_PX}px;
  padding: 0 ${COLUMN_HORIZONTAL_PADDING_PX}px;
`;

const SHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SPlaceholder = styled.div`
  height: ${HEADING_BOLD_LINE_HEIGHT};
`;

ColumnLast.whyDidYouRender = true;
export default React.memo(ColumnLast);
