import { useFocusRing } from '@react-aria/focus';
import { VisuallyHidden } from '@react-aria/visually-hidden';
import { ToggleState, useToggleState } from '@react-stately/toggle';
import type { AriaButtonProps } from '@react-types/button';
import type { ToggleProps } from '@react-types/checkbox';
import { settings } from '@rhim/design';
import { CheckmarkIcon as CheckmarkXSmallIcon } from '@rhim/icons/16';
import { CheckmarkIcon } from '@rhim/icons/24';
import { isDefined } from '@rhim/utils';
import React from 'react';
import { useButton, useCheckbox, useHover, useKeyboard } from 'react-aria';
import styled, { css } from 'styled-components';

interface Props extends Omit<ToggleProps, 'children'>, AriaButtonProps {
  dataTestId?: string;
  children?: string | React.ReactNode;
  className?: string;
  /**
   * @default 'small'
   */
  size?: Size;
}

type Size = 'x-small' | 'small' | 'medium';

type Mode = 'normal' | 'hover' | 'active' | 'focus' | 'readonly';

type State = 'checked' | 'unchecked';

const Checkbox: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ dataTestId, className, size = 'small', ...rest }) => {
  const inputRef: React.RefObject<HTMLInputElement> = React.useRef<HTMLInputElement>(null);
  const labelRef: React.RefObject<HTMLLabelElement> = React.useRef<HTMLLabelElement>(null);

  const state: ToggleState = useToggleState(rest);
  const { inputProps } = useCheckbox(rest, state, inputRef);
  const { isFocusVisible, focusProps } = useFocusRing({ autoFocus: rest.autoFocus });
  const { isReadOnly, isDisabled, children } = rest;
  const { hoverProps, isHovered } = useHover({ isDisabled });
  const { buttonProps, isPressed } = useButton(rest, labelRef);
  const [isSpacePressed, setIsSpacePressed] = React.useState(false);

  const currentState: State = state.isSelected ? 'checked' : 'unchecked';

  const { keyboardProps } = useKeyboard({
    onKeyDown: (event) => {
      if (event.key === ' ') {
        setIsSpacePressed(true);
      }
    },
    onKeyUp: (event) => {
      if (event.key === ' ') {
        setIsSpacePressed(false);
      }
    },
    isDisabled,
  });

  const mode: Mode = React.useMemo(() => {
    if ((isReadOnly ?? false) || (isDisabled ?? false)) {
      return 'readonly';
    } else if (isPressed || isSpacePressed) {
      return 'active';
    } else if (isFocusVisible) {
      return 'focus';
    } else if (isHovered) {
      return 'hover';
    }

    return 'normal';
  }, [isReadOnly, isDisabled, isHovered, isFocusVisible, isPressed, isSpacePressed]);

  const tick = React.useMemo(() => {
    const fill = style[currentState][mode].tick;

    switch (size) {
      case 'medium':
        return <CheckmarkIcon fill={fill} />;
      case 'small':
      case 'x-small':
        return <CheckmarkXSmallIcon fill={fill} />;
    }
  }, [size, mode, currentState]);

  return (
    <Label data-test-id={dataTestId} className={className} ref={labelRef} {...hoverProps} {...buttonProps} size={size}>
      <VisuallyHidden>
        <input {...inputProps} {...keyboardProps} {...focusProps} ref={inputRef} />
      </VisuallyHidden>
      <Background size={size} mode={mode} state={currentState} isFocused={isFocusVisible} aria-hidden="true">
        {(state.isSelected || isPressed || isSpacePressed) && tick}
      </Background>
      {isDefined(children) && <Text size={size}>{children}</Text>}
    </Label>
  );
};

/**
 * @see https://zpl.io/2vJgxNv
 */
const Label = styled.label<{ size: Size }>`
  display: flex;
  align-items: center;
  gap: ${(props) => (props.size === 'x-small' ? '4px' : '8px')};
`;

const Text = styled.span<{ size: Size }>((props) => {
  const fontSize: settings.typography.FontSize = (() => {
    switch (props.size) {
      case 'medium':
        return settings.typography.FontSize.Medium;
      case 'small':
        return settings.typography.FontSize.Small;
      case 'x-small':
        return settings.typography.FontSize.X_Small;
    }
  })();

  const lineHeight: settings.typography.LineHeight = (() => {
    switch (props.size) {
      case 'medium':
        return settings.typography.LineHeight.Line_Height_24;
      case 'small':
        return settings.typography.LineHeight.Line_Height_20;
      case 'x-small':
        return settings.typography.LineHeight.Line_Height_16;
    }
  })();

  return css`
    font-family: ${settings.typography.FontFamily.Regular};
    font-size: ${fontSize};
    line-height: ${lineHeight};
    color: ${settings.colors.Primary.Grey_8};
  `;
});

const Background = styled.div<{ size: Size; mode: Mode; state: State; isFocused: boolean }>((props) => {
  const { background: backgroundColor, border: borderColor } = style[props.state][props.mode];

  const size = (() => {
    switch (props.size) {
      case 'medium':
        return '24px';
      case 'small':
        return '20px';
      case 'x-small':
        return '16px';
    }
  })();

  return css`
    display: grid;
    place-items: center;
    background-color: ${backgroundColor};
    border-radius: 3px;
    width: ${size};
    height: ${size};
    position: relative;

    /* box-shadow is preferred over border because box-shadow doesn't affect the box model */
    box-shadow: 0 0 0 1px ${borderColor} inset;

    /* Focus ring */
    ::after {
      content: '';
      height: calc(100% + 4px);
      width: calc(100% + 4px);
      position: absolute;
      border: 1px solid ${props.isFocused ? settings.colors.Primary.Grey_4 : 'transparent'};
      border-radius: 4px;
    }
  `;
});

const style = {
  unchecked: {
    normal: {
      background: settings.colors.Monochromatic.White,
      border: settings.colors.Primary.Grey_4,
      tick: 'transparent',
    },
    hover: {
      background: settings.colors.Monochromatic.White,
      border: settings.colors.Primary.Blue_8,
      tick: 'transparent',
    },
    active: {
      background: settings.colors.Monochromatic.White,
      border: settings.colors.Primary.Blue_8,
      tick: settings.colors.Primary.Grey_4,
    },
    readonly: {
      background: settings.colors.Primary.Grey_1,
      border: settings.colors.Primary.Grey_3,
      tick: 'transparent',
    },
    focus: {
      background: settings.colors.Monochromatic.White,
      border: settings.colors.Primary.Grey_4,
      tick: 'transparent',
    },
  },
  checked: {
    normal: {
      background: settings.colors.Primary.Blue_9,
      border: settings.colors.Primary.Blue_9,
      tick: settings.colors.Primary.Blue_1,
    },
    hover: {
      background: settings.colors.Primary.Blue_8,
      border: settings.colors.Primary.Blue_8,
      tick: settings.colors.Primary.Blue_1,
    },
    active: {
      background: settings.colors.Primary.Blue_8,
      border: settings.colors.Primary.Blue_8,
      tick: settings.colors.Primary.Grey_4,
    },
    readonly: {
      background: settings.colors.Primary.Grey_4,
      border: settings.colors.Primary.Grey_4,
      tick: settings.colors.Primary.Blue_1,
    },
    focus: {
      background: settings.colors.Primary.Blue_9,
      border: settings.colors.Primary.Grey_4,
      tick: settings.colors.Primary.Blue_1,
    },
  },
} as const;

Checkbox.whyDidYouRender = true;

export default React.memo(Checkbox);
