import { i18nReact } from '@rhim/i18n';
import { RHIMFleetOverviewServiceV1ControllersMeasurementSequenceDto } from '@rhim/rest/fleetOverview';
import { isDefined } from '@rhim/utils';
import { ScaleLinear } from 'd3-scale';
import React, { useMemo, useRef } from 'react';

import { MeasurementPropertyName, useMeasurementMaxValue } from '../hooks/useMaxYValue';
import { useMeasurementSequenceTuples } from '../hooks/useMeasurementSequenceTuples';
import { useMeasurementsTonnageKilograms } from '../hooks/useMeasurementsTonnage';
import { useScaleY } from '../hooks/useScaleY';
import { useSidesLegends } from '../hooks/useSidesLegends';
import { useTooltip } from '../hooks/useTooltip';
import { SDashedLine } from '../styles';
import { VesselLine } from '../types';
import { CRITICAL_LINE_COLOR, DEFAULT_GRAPH_AREA_HEIGHT, DEFAULT_GRAPH_HEIGHT_TOTAL, SIDE_0_LINE_COLOR, SIDE_1_LINE_COLOR } from './constants';
import Graph from './Graph';
import { getLines } from './utils';

export function getRBLPropertyName(side: 'side0' | 'side1', line: 'Slagline' | 'Ironline'): MeasurementPropertyName {
  return `${side}${line}Rbl`;
}

interface Props {
  xScale: ScaleLinear<number, number>;
  measurementSequence: RHIMFleetOverviewServiceV1ControllersMeasurementSequenceDto;
  line: VesselLine;
  critical: number;
}
const GraphRemainingThickness: React.ChildlessComponent<Props> = ({ xScale, measurementSequence, line, critical }) => {
  const { t } = i18nReact.useTranslation(['blastFurnaceRunner-report']);
  const sidesLegends = useSidesLegends();
  const measurementTuples = useMeasurementSequenceTuples(measurementSequence);
  const side0PropertyName = getRBLPropertyName('side0', line);
  const side1PropertyName = getRBLPropertyName('side1', line);
  const maxYValue = useMeasurementMaxValue(measurementTuples, [side0PropertyName, side1PropertyName]);
  const yScale = useScaleY(DEFAULT_GRAPH_AREA_HEIGHT, 0, maxYValue);
  const measurementsAtKilograms = useMeasurementsTonnageKilograms(measurementTuples);
  const svgGraphAreaRef = useRef<SVGRectElement>(null);
  const { tooltipIndicator, tooltipPanel } = useTooltip(svgGraphAreaRef.current, xScale, measurementSequence, `remainingThickness${line}`);

  const side0Line = useMemo(
    () => getLines(measurementTuples, side0PropertyName, xScale, yScale, SIDE_0_LINE_COLOR),
    [measurementTuples, side0PropertyName, xScale, yScale]
  );
  const side1Line = useMemo(
    () => getLines(measurementTuples, side1PropertyName, xScale, yScale, SIDE_1_LINE_COLOR),
    [measurementTuples, side1PropertyName, xScale, yScale]
  );

  const graphLegends = useMemo(() => {
    if (!isDefined(sidesLegends)) {
      return undefined;
    }
    return [
      ...sidesLegends,
      {
        label: 'Critical',
        color: CRITICAL_LINE_COLOR,
        isSolidLine: false,
      },
    ];
  }, [sidesLegends]);

  return (
    <Graph
      ref={svgGraphAreaRef}
      graphHeightTotal={DEFAULT_GRAPH_HEIGHT_TOTAL}
      xScale={xScale}
      yScale={yScale}
      yAxisLabel={`↑${t('blastFurnaceRunner-report:campaignReport.graphRemainingThickness.yAxisLabel')} [mm]`}
      graphLegends={graphLegends}
      measurementsAtKilograms={measurementsAtKilograms}
      tooltipPanel={tooltipPanel}
    >
      {tooltipIndicator}
      {side0Line}
      {side1Line}
      <SDashedLine key="average-line" from={{ x: 0, y: yScale(critical) }} to={{ x: xScale.range()[1], y: yScale(critical) }} stroke={CRITICAL_LINE_COLOR} />
    </Graph>
  );
};

GraphRemainingThickness.whyDidYouRender = true;
export default React.memo(GraphRemainingThickness);
