import { i18nReact } from '@rhim/i18n';
import { RHIMFleetOverviewServiceV1ControllersMeasurementSequenceDto } from '@rhim/rest/fleetOverview';
import { isDefined } from '@rhim/utils';
import { ScaleLinear } from 'd3-scale';
import React, { useMemo, useRef } from 'react';

import { useMeasurementTupleMaxValue } from '../hooks/useMaxYValue';
import { MeasurementTuple, useMeasurementSequenceTuples } from '../hooks/useMeasurementSequenceTuples';
import { useMeasurementsTonnageKilograms } from '../hooks/useMeasurementsTonnage';
import { useScaleY } from '../hooks/useScaleY';
import { useTooltip } from '../hooks/useTooltip';
import { DEFAULT_GRAPH_AREA_HEIGHT, DEFAULT_GRAPH_HEIGHT_TOTAL, DELTA_VOLUME_BEFORE_AFTER_REPAIR_LINE_COLOR, REPAIR_MIX_VOLUME_LINE_COLOR } from './constants';
import Graph from './Graph';
import { GraphLegendProps } from './GraphLegend';
import { getBars } from './utils';

interface Props {
  xScale: ScaleLinear<number, number>;
  measurementSequence: RHIMFleetOverviewServiceV1ControllersMeasurementSequenceDto;
}
const GraphVolumeRepairDeltaVsMixVolume: React.ChildlessComponent<Props> = ({ xScale, measurementSequence }) => {
  const { t } = i18nReact.useTranslation(['blastFurnaceRunner-report']);
  const measurementTuples = useMeasurementSequenceTuples(measurementSequence);
  const volumePropertyName = 'volume';
  const repairMixVolumePropertyName = 'repairMixVolume';
  const repairMixVolumeMaxValueCubicMeters = useMeasurementTupleMaxValue(measurementTuples, repairMixVolumePropertyName);

  const measurementsAtKilograms = useMeasurementsTonnageKilograms(measurementTuples);
  const svgGraphAreaRef = useRef<SVGRectElement>(null);
  const { tooltipIndicator, tooltipPanel } = useTooltip(svgGraphAreaRef.current, xScale, measurementSequence, `deltaVolumeVsRepairMixVolume`);

  const GRAPH_LEGENDS: [GraphLegendProps, GraphLegendProps] = useMemo(() => {
    return [
      {
        label: t('blastFurnaceRunner-report:campaignReport.graphVolumeRepairDeltaVsMixVolume.graphBarLabelA'),
        color: DELTA_VOLUME_BEFORE_AFTER_REPAIR_LINE_COLOR,
        isSolidLine: true,
      },
      {
        label: t('blastFurnaceRunner-report:campaignReport.graphVolumeRepairDeltaVsMixVolume.graphBarLabelB'),
        color: REPAIR_MIX_VOLUME_LINE_COLOR,
        isSolidLine: true,
      },
    ];
  }, [t]);

  const deltaVolumeDomain = useMemo(() => {
    const ret: [number, number] = [Number.POSITIVE_INFINITY, Number.NEGATIVE_INFINITY];
    for (const measurementTuple of measurementTuples) {
      if (
        !isDefined(measurementTuple.workingLiningMeasurement) ||
        !isDefined(measurementTuple.workingLiningMeasurement[volumePropertyName]) ||
        !isDefined(measurementTuple.afterRepairMeasurement) ||
        !isDefined(measurementTuple.afterRepairMeasurement[volumePropertyName])
      ) {
        continue;
      }
      const afterRepairVolume = measurementTuple.afterRepairMeasurement[volumePropertyName];
      const workingLiningVolume = measurementTuple.workingLiningMeasurement[volumePropertyName];
      const deltaVolume = workingLiningVolume - afterRepairVolume;
      ret[0] = Math.min(deltaVolume, ret[0]);
      ret[1] = Math.max(deltaVolume, ret[1]);
    }
    return ret;
  }, [measurementTuples, volumePropertyName]);

  const overallDomain: [number, number] = [deltaVolumeDomain[0], Math.max(deltaVolumeDomain[1], repairMixVolumeMaxValueCubicMeters)];
  const yScale = useScaleY(DEFAULT_GRAPH_AREA_HEIGHT, overallDomain[0], overallDomain[1]);

  const deltaVolumeBars = useMemo(
    () =>
      getBars(
        'deltaVolumeBars',
        measurementTuples,
        (measurementTuple: MeasurementTuple) => {
          if (
            !isDefined(measurementTuple.workingLiningMeasurement) ||
            !isDefined(measurementTuple.workingLiningMeasurement[volumePropertyName]) ||
            !isDefined(measurementTuple.afterRepairMeasurement) ||
            !isDefined(measurementTuple.afterRepairMeasurement[volumePropertyName])
          ) {
            return null;
          }
          const afterRepairVolume = measurementTuple.afterRepairMeasurement[volumePropertyName];
          const workingLiningVolume = measurementTuple.workingLiningMeasurement[volumePropertyName];
          const deltaVolume = workingLiningVolume - afterRepairVolume;
          return deltaVolume;
        },
        'leftAligned',
        xScale,
        yScale,
        DELTA_VOLUME_BEFORE_AFTER_REPAIR_LINE_COLOR
      ),
    [measurementTuples, volumePropertyName, xScale, yScale]
  );

  const repairMixVolumeBars = useMemo(
    () =>
      getBars(
        'repairMixVolumeBars',
        measurementTuples,
        (measurementTuple: MeasurementTuple) => {
          return measurementTuple[repairMixVolumePropertyName];
        },
        'rightAligned',
        xScale,
        yScale,
        REPAIR_MIX_VOLUME_LINE_COLOR
      ),
    [measurementTuples, repairMixVolumePropertyName, xScale, yScale]
  );

  return (
    <Graph
      ref={svgGraphAreaRef}
      graphHeightTotal={DEFAULT_GRAPH_HEIGHT_TOTAL}
      xScale={xScale}
      yScale={yScale}
      yAxisLabel={`${GRAPH_LEGENDS[0].label} vs ${GRAPH_LEGENDS[1].label} [m3]`}
      graphLegends={GRAPH_LEGENDS}
      measurementsAtKilograms={measurementsAtKilograms}
      tooltipPanel={tooltipPanel}
    >
      {tooltipIndicator}
      {deltaVolumeBars}
      {repairMixVolumeBars}
    </Graph>
  );
};

GraphVolumeRepairDeltaVsMixVolume.whyDidYouRender = true;
export default React.memo(GraphVolumeRepairDeltaVsMixVolume);
