import { settings } from '@rhim/design';
import { HeatIcon } from '@rhim/icons/16';
import { MeasurementIcon } from '@rhim/icons/24';
import { ChevronRotatable, DropdownPanel, getFormattedDate, getFormattedDateWithOffset, Spinner, Tooltip } from '@rhim/react';
import { hasElements, isDefined } from '@rhim/utils';
import classNames from 'classnames';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { HeatLineConnector } from '../../pages/MeasurementView/MeasurementViewStrip/shared';
import { isSiblingMeasurementOfSameHeat } from '../../pages/MeasurementView/utils';
import MeasurementSelectionDropdown from './MeasurementSelectionDropdown';
import { getHeatLabel } from './MeasurementSelectionItem';
import { Measurement, Mode } from './types';

interface Props {
  mode?: Mode;
  isLoading: boolean;
  measurements: Measurement[] | undefined;
  selectedMeasurementId: string | null | undefined;
  customerTimezone: string;
  dataTestId?: string;
  onMeasurementSelected: (measurementID: string) => void;
}

const MeasurementSelection: React.ChildlessComponent<Props> = ({
  mode = 'heatMeasurement',
  customerTimezone,
  measurements,
  isLoading,
  selectedMeasurementId,
  onMeasurementSelected,
  dataTestId,
}) => {
  const [measurementDropdownExpandedState, setMeasurementDropdownExpandedState] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);
  const hasMeasurements = !isLoading && isDefined(measurements) && hasElements(measurements);
  const { t } = useTranslation(['shared', 'measurement-view']);

  const collapseDropdown = useCallback(() => {
    setMeasurementDropdownExpandedState(false);
  }, [setMeasurementDropdownExpandedState]);

  const handleMouseClick = useCallback(
    (event: MouseEvent) => {
      if (isDefined(ref.current) && !ref.current.contains(event.target as HTMLElement)) {
        collapseDropdown();
      }
    },
    [collapseDropdown]
  );

  useEffect(() => {
    document.addEventListener('click', handleMouseClick, true);
    return () => document.removeEventListener('click', handleMouseClick);
  }, [handleMouseClick]);

  const handleSelectorDropdownClicked = () => {
    setMeasurementDropdownExpandedState(!measurementDropdownExpandedState);
  };

  const handleMeasurementSelected = useCallback(
    (measurementId: string) => {
      collapseDropdown();
      onMeasurementSelected(measurementId);
    },
    [collapseDropdown, onMeasurementSelected]
  );

  const selectedMeasurement: Measurement | undefined = useMemo(() => {
    if (!isDefined(measurements)) {
      return undefined;
    }
    return measurements.find((measurement) => measurement.id === selectedMeasurementId);
  }, [measurements, selectedMeasurementId]);

  const dropdownPanelMeasurementSelector = useMemo(() => {
    if (!isDefined(measurements) || !isDefined(selectedMeasurementId)) {
      return null;
    }
    return (
      <SDropdownPanel isExpanded={measurementDropdownExpandedState}>
        <MeasurementSelectionDropdown
          mode={mode}
          datepickerPopupContainer={ref.current}
          measurements={measurements}
          selectedMeasurementId={selectedMeasurementId}
          onMeasurementSelected={handleMeasurementSelected}
          customerTimezone={customerTimezone}
        />
      </SDropdownPanel>
    );
  }, [mode, measurementDropdownExpandedState, measurements, selectedMeasurementId, handleMeasurementSelected, customerTimezone]);

  const loadingIndicator = useMemo(
    () => (
      <>
        <Spinner size="24" inverted={true} rotationDuration={2.5} />
        <SDropdownLabel>{t('shared:strip.loadingIndicator')}</SDropdownLabel>
      </>
    ),
    [t]
  );

  const measurementDropdownClasses = classNames({
    isDisabled: !hasMeasurements,
  });

  const formattedTimestamp = isDefined(selectedMeasurement)
    ? getFormattedDate({
        datetime: selectedMeasurement.measurementTaken as Date.ISO_8601,
        showTime: true,
      })
    : undefined;

  const formattedTooltip = isDefined(selectedMeasurement)
    ? getFormattedDateWithOffset(selectedMeasurement.measurementTaken as Date.ISO_8601, customerTimezone)
    : undefined;

  return (
    <SDropdownContainer ref={ref} data-test-id={dataTestId}>
      <SHoveredRegion onClick={handleSelectorDropdownClicked} className={measurementDropdownClasses}>
        {isLoading ? (
          loadingIndicator
        ) : (
          <>
            {isDefined(selectedMeasurement) && isDefined(measurements) ? (
              <>
                <MeasurementIcon fill={settings.colors.Primary.Grey_2} />
                {mode === 'heatMeasurement' && (
                  <SHeatIconContainer>
                    <HeatIcon fill={settings.colors.Primary.Grey_2} />
                    {isSiblingMeasurementOfSameHeat(selectedMeasurement, 'earlier', measurements) && <SHeatLineConnector isConnectingAbove={true} />}
                    {isSiblingMeasurementOfSameHeat(selectedMeasurement, 'later', measurements) && <SHeatLineConnector isConnectingAbove={false} />}
                  </SHeatIconContainer>
                )}
                <SDropdownLabel>{getHeatLabel(selectedMeasurement.heat, mode)}</SDropdownLabel>
                <SMeasurementCreatedOnLabel>
                  <Tooltip title={formattedTooltip ?? ''} isShowing={isDefined(formattedTimestamp)} overlayStyle={{ maxWidth: '500px' }}>
                    {formattedTimestamp ?? <span>-</span>}
                  </Tooltip>
                </SMeasurementCreatedOnLabel>
                <SChevronIconRotatable orientation={measurementDropdownExpandedState ? 'up' : 'down'} isDisabled={false} />
              </>
            ) : (
              <>
                <MeasurementIcon fill={settings.colors.Primary.Grey_8} />
                <SDropdownLabelDisabled>{t('measurement-view:strip.dropdownNoMeasurements')}</SDropdownLabelDisabled>
              </>
            )}
          </>
        )}
      </SHoveredRegion>
      {dropdownPanelMeasurementSelector}
    </SDropdownContainer>
  );
};

export default React.memo(MeasurementSelection);

const SDropdownContainer = styled.div`
  display: flex;
  position: relative;
`;

const SDropdownPanel = styled(DropdownPanel)`
  overflow-x: hidden;
  min-width: 100%;
  max-height: 400px;
`;

const SHoveredRegion = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 ${settings.Spacing.Spacing_200};
  cursor: pointer;
  border-right: 1px solid ${settings.colors.Primary.Grey_7};

  &.isDisabled {
    pointer-events: none;
  }

  &:hover:not(.isDisabled) {
    background-color: ${settings.colors.Primary.Grey_9};
    border-color: ${settings.colors.Primary.Grey_7};
    border-right-style: solid;

    &:active {
      background-color: ${settings.colors.Primary.Grey_8};
    }
  }
`;

const SHeatIconContainer = styled.div`
  margin-left: ${settings.Spacing.Spacing_150};
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
`;

export const SDropdownLabel = styled.span`
  margin-left: ${settings.Spacing.Spacing_100};
  white-space: nowrap;
  font-family: ${settings.typography.FontFamily.Medium};
  font-size: ${settings.typography.FontSize.Small};
  color: ${settings.colors.Primary.Grey_2};
`;

const SDropdownLabelDisabled = styled(SDropdownLabel)`
  color: ${settings.colors.Primary.Grey_8};
`;

const SMeasurementCreatedOnLabel = styled(SDropdownLabel)`
  margin-left: ${settings.Spacing.Spacing_150};
`;

const SChevronIconRotatable = styled<React.FunctionComponent<React.ComponentProps<typeof ChevronRotatable> & { isDisabled: boolean }>>(ChevronRotatable).attrs(
  (props) => ({
    fill: isDefined(props['fill']) ? props['fill'] : props.isDisabled ? settings.colors.Primary.Grey_8 : settings.colors.Primary.Blue_1,
  })
)`
  margin-left: ${settings.Spacing.Spacing_200};
`;

const SHeatLineConnector = styled(HeatLineConnector)`
  height: 6px;

  --yMargin: 6px;
`;
