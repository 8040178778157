import { settings } from '@rhim/design';
import React, { useMemo } from 'react';
import styled from 'styled-components';

const LINE_WIDTH_PX = 16;
const LINE_HEIGHT_PX = 2;
const SHAPE_SIZE_PX = 8;
const RECTANGLE_SIZE_PX = 10;

export enum PropertyType {
  Line = 'Line',
  WorkingLining = 'WorkingLining',
  AfterRepair = 'AfterRepair',
  Rectangle = 'Rectangle',
}

interface Props {
  propertyType: PropertyType;
  fill: string;
}

const GraphTooltipPropertyLegend: React.ChildlessComponent<Props> = ({ propertyType, fill }) => {
  const legend = useMemo(() => {
    switch (propertyType) {
      case PropertyType.Line:
        return <SHorizontalLine fill={fill} />;
      case PropertyType.Rectangle:
        return <SRectangle fill={fill} />;
      case PropertyType.WorkingLining:
        return (
          <SContainer>
            <SHorizontalLine fill={fill} />
            <SCircle fill={fill} />
          </SContainer>
        );
      case PropertyType.AfterRepair:
        return (
          <SContainer>
            <SHorizontalLine fill={fill} />
            <SBox fill={fill} />
          </SContainer>
        );
    }
  }, [propertyType, fill]);

  return legend;
};

const SHorizontalLine = styled.div<{ fill: string }>`
  width: ${LINE_WIDTH_PX}px;
  height: ${LINE_HEIGHT_PX}px;
  background-color: ${(props) => props.fill};
`;

const SRectangle = styled.div<{ fill: string }>`
  width: ${RECTANGLE_SIZE_PX}px;
  height: ${RECTANGLE_SIZE_PX}px;
  border-radius: 2px;
  background-color: ${(props) => props.fill};
`;

const SContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const SBox = styled.div<{ fill: string }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid ${settings.colors.Monochromatic.White};
  width: ${SHAPE_SIZE_PX}px;
  height: ${SHAPE_SIZE_PX}px;
  background-color: ${(props) => props.fill};
`;

const SCircle = styled(SBox)`
  border-radius: 50%;
`;

GraphTooltipPropertyLegend.whyDidYouRender = true;
export default React.memo(GraphTooltipPropertyLegend);
