import { settings } from '@rhim/design';
import { ensure, isDefined } from '@rhim/utils';
import { Line } from '@vx/shape';
import { ScaleLinear } from 'd3-scale';
import React from 'react';
import styled from 'styled-components';

import { MeasurementPropertyName } from '../hooks/useMaxYValue';
import { MeasurementTuple } from '../hooks/useMeasurementSequenceTuples';
import { SHAPE_RENDERING_GEOMETRIC_PRECISION } from './constants';

const REPAIR_INDICATOR_SIZE = 6;
const REPAIR_INDICATOR_SIZE_HALF = REPAIR_INDICATOR_SIZE / 2;

enum RepairType {
  BeforeRepair = 'BeforeRepair',
  AfterRepair = 'AfterRepair',
}

function getRepairIndicator(repairType: RepairType, x: number, y: number, color: string) {
  switch (repairType) {
    case RepairType.BeforeRepair:
      return (
        <circle
          cx={x}
          cy={y}
          r={REPAIR_INDICATOR_SIZE_HALF}
          fill={color}
          stroke={settings.colors.Monochromatic.White}
          strokeWidth={1}
          shapeRendering={SHAPE_RENDERING_GEOMETRIC_PRECISION}
        />
      );
    case RepairType.AfterRepair:
      return (
        <rect
          x={x - REPAIR_INDICATOR_SIZE_HALF}
          y={y - REPAIR_INDICATOR_SIZE_HALF}
          width={REPAIR_INDICATOR_SIZE}
          height={REPAIR_INDICATOR_SIZE}
          fill={color}
          stroke={settings.colors.Monochromatic.White}
          strokeWidth={1}
          shapeRendering={SHAPE_RENDERING_GEOMETRIC_PRECISION}
        />
      );
  }
}

export function getLines(
  measurementTuples: MeasurementTuple[],
  propertyName: MeasurementPropertyName,
  xScale: ScaleLinear<number, number>,
  yScale: ScaleLinear<number, number>,
  color: string
) {
  const ret = [];
  for (const [index, measurementTuple] of measurementTuples.entries()) {
    if (index === 0) {
      continue;
    }

    // If we do not have a workingLining measurement at all then we do not display anything for this tonnage entry
    const workingLiningMeasurement = measurementTuple.workingLiningMeasurement;
    if (!isDefined(workingLiningMeasurement)) {
      continue;
    }
    // If we do have a workingLining measurement, its property value might be null. Again do not display anything for this tonnage entry
    const workingLiningValue = workingLiningMeasurement[propertyName];
    if (!isDefined(workingLiningValue)) {
      continue;
    }

    /**
     * We have a workingLining measurement and its property value is not null.
     * We should then display :
     * a) a line connecting this measurement with the nearest measurement to its left that has either :
     *    a1) a non-null afterRepair measurement and its property value is also not null
     *    or
     *    a2) a non-null workingLining measurement and its property value is also not null
     *    NOTE 1: If the nearest measurement is adjacent to this measurement we use solid line. Otherwise we use a dashed line
     *    NOTE 2: If no such nearest measurement is found then we do not display the line at all
     * b) At the line's rightmost side a dot
     * c) Provided that there exists a nearest measurement, at the line's leftmost side one of:
     *    c1) a rectangle if its nearest measurement is an afterRepair measurement and has a value
     *    or
     *    c2) a circle if its nearest measurement is a beforeRepair measurement and has a value
     */

    const currentMeasumentPointX = xScale(workingLiningMeasurement.tonnage);
    const currentMeasurementPointY = yScale(workingLiningValue);

    const earliestMeasurementPointInfo = (() => {
      for (let i = index - 1; i >= 0; i--) {
        const measurementTuple = ensure(measurementTuples[i]);
        const isAdjacentMeasurement = index - i === 1;
        if (isDefined(measurementTuple.afterRepairMeasurement)) {
          const value = measurementTuple.afterRepairMeasurement[propertyName];
          if (isDefined(value)) {
            return {
              x: xScale(measurementTuple.afterRepairMeasurement.tonnage),
              y: yScale(value),
              repairType: RepairType.AfterRepair,
              isAdjacentMeasurement: isAdjacentMeasurement,
            };
          }
        }
        if (isDefined(measurementTuple.workingLiningMeasurement)) {
          const value = measurementTuple.workingLiningMeasurement[propertyName];
          if (isDefined(value)) {
            return {
              x: xScale(measurementTuple.workingLiningMeasurement.tonnage),
              y: yScale(value),
              repairType: RepairType.BeforeRepair,
              isAdjacentMeasurement: isAdjacentMeasurement,
            };
          }
        }
        return undefined;
      }
    })();

    const line = isDefined(earliestMeasurementPointInfo) ? (
      <SLine
        key={workingLiningMeasurement.tonnage}
        from={{ x: earliestMeasurementPointInfo.x, y: earliestMeasurementPointInfo.y }}
        to={{ x: currentMeasumentPointX, y: currentMeasurementPointY }}
        stroke={color}
        strokeDasharray={earliestMeasurementPointInfo.isAdjacentMeasurement ? undefined : 2}
      />
    ) : null;

    const currentMeasurementIndicator = getRepairIndicator(RepairType.BeforeRepair, currentMeasumentPointX, currentMeasurementPointY, color);
    const nearestMeasurementIndicator = isDefined(earliestMeasurementPointInfo)
      ? getRepairIndicator(earliestMeasurementPointInfo.repairType, earliestMeasurementPointInfo.x, earliestMeasurementPointInfo.y, color)
      : null;

    const measurementComponents = (
      <React.Fragment key={index}>
        {line}
        {currentMeasurementIndicator}
        {nearestMeasurementIndicator}
      </React.Fragment>
    );

    ret.push(measurementComponents);
  }

  const GROUP_ID = `group-lines-${propertyName}`;

  return (
    <g key={GROUP_ID} id={GROUP_ID}>
      {ret}
    </g>
  );
}

const SINGLE_BAR_WIDTH_PX = 14;
export function getBars(
  description: string,
  measurementTuples: MeasurementTuple[],
  valueAccessor: (measurementTuple: MeasurementTuple) => null | number,
  //propertyName: PropertyName,
  mode: 'leftAligned' | 'center' | 'rightAligned',
  xScale: ScaleLinear<number, number>,
  yScale: ScaleLinear<number, number>,
  color: string
) {
  const ret = [];

  const zeroY = yScale(0);
  let hasNegativeDeltaRBL = false;
  for (const measurementTuple of measurementTuples) {
    /**
     * We have both a workingLining & afterRepair measurements and with defined values.
     * We should then display those value's difference with bars :
     * a) a left-aligned bar for side0
     * b) a right-aligned bar for side1
     */
    const currentMeasumentPointX = xScale(measurementTuple.tonnageKilograms);

    const value = valueAccessor(measurementTuple);
    if (!isDefined(value)) {
      continue;
    }
    if (value < 0) {
      hasNegativeDeltaRBL = true;
    }
    const barStartY = value > 0 ? yScale(value) : zeroY;
    const barHeight = value > 0 ? zeroY - barStartY : yScale(value) - zeroY;

    /*
    const afterRepairRBL = ensure(measurementTuple.afterRepairMeasurement)[propertyName];
    const workingLiningRBL = ensure(measurementTuple.workingLiningMeasurement)[propertyName];
    const deltaRBL = ensure(afterRepairRBL) - ensure(workingLiningRBL);
    if (deltaRBL < 0) {
      hasNegativeDeltaRBL = true;
    }
    const zeroY = yScale(0);
    const barStartY = deltaRBL > 0 ? yScale(deltaRBL) : zeroY;
    const barHeight = deltaRBL > 0 ? zeroY - barStartY : yScale(deltaRBL) - zeroY;
    */
    let barStartX = 0;
    let barWidth = 0;
    switch (mode) {
      case 'leftAligned': {
        barStartX = currentMeasumentPointX - SINGLE_BAR_WIDTH_PX;
        barWidth = SINGLE_BAR_WIDTH_PX;
        break;
      }
      case 'center': {
        barStartX = currentMeasumentPointX - SINGLE_BAR_WIDTH_PX;
        barWidth = 2 * SINGLE_BAR_WIDTH_PX;
        break;
      }
      case 'rightAligned': {
        barStartX = currentMeasumentPointX;
        barWidth = SINGLE_BAR_WIDTH_PX;
        break;
      }
    }
    const bar = <rect x={barStartX} y={barStartY} width={barWidth} height={barHeight} fill={color} />;

    ret.push(bar);
  }

  const GROUP_ID = `group-bars-${description}`;

  const graphWidth = xScale.range()[1];
  const zeroValueY = yScale(0);

  return (
    <g key={GROUP_ID} id={GROUP_ID}>
      {hasNegativeDeltaRBL && <line x1={0} y1={zeroValueY} x2={graphWidth} y2={zeroValueY} stroke={settings.colors.Primary.Grey_5} strokeDasharray={4} />}
      {ret}
    </g>
  );
}

const SLine = styled(Line)`
  stroke-width: 2;
  shape-rendering: geometricprecision;
  pointer-events: none;
`;
