import { settings } from '@rhim/design';
import { isDefined } from '@rhim/utils';
import React, { useMemo } from 'react';
import styled from 'styled-components';

export enum FontSize {
  Size25 = '25px',
  Size20 = '20px',
  Size16 = '16px',
}

interface Props {
  fontSize: FontSize;
  label: string;
  subLabel?: JSX.Element;
}
const Heading: React.ChildlessComponent<Props> = ({ fontSize, label, subLabel }) => {
  const marginVertical = useMemo(() => {
    switch (fontSize) {
      case FontSize.Size25:
        return settings.Spacing.Spacing_400;
      case FontSize.Size20:
        return settings.Spacing.Spacing_200;
      case FontSize.Size16:
        return settings.Spacing.Spacing_150;
    }
  }, [fontSize]);

  return (
    <SWrapper marginVertical={marginVertical}>
      <SLabel fontSize={fontSize}>{label}</SLabel>
      {isDefined(subLabel) && <SSubLabel fontSize={fontSize}>{subLabel}</SSubLabel>}
    </SWrapper>
  );
};

const SWrapper = styled.div<{ marginVertical: string }>`
  display: flex;
  column-gap: ${settings.Spacing.Spacing_100};
  align-items: center;
  margin: ${(props) => props.marginVertical} 0;
`;

const SLabel = styled.div<{ fontSize: string }>`
  font-family: ${settings.typography.FontFamily.Bold};
  font-size: ${(props) => props.fontSize};
  color: ${settings.colors.Primary.Grey_8};
`;

const SSubLabel = styled(SLabel)`
  font-family: ${settings.typography.FontFamily.Regular};
  font-size: ${settings.typography.FontSize.Small};
  color: ${settings.colors.Primary.Grey_6};
  display: flex;
  align-items: center;
`;

Heading.whyDidYouRender = true;
export default React.memo(Heading);
