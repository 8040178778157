import { settings } from '@rhim/design';
import { XMiniIcon } from '@rhim/icons/16';
import styled from 'styled-components';

import { TOOLTIP_BORDER_RADIUS } from './constants';

const CloseButton = styled(XMiniIcon)`
  cursor: pointer;
  fill: ${settings.colors.Primary.Blue_9};

  &:hover {
    fill: ${settings.colors.Primary.Blue_9};
  }
`;

const Separator = styled.div`
  height: 1px;
  background-color: ${settings.colors.Primary.Grey_2};
  margin: 5px -${settings.Spacing.Spacing_100};
`;

const SectionBottom = styled.div`
  border-top: 1px solid ${settings.colors.Primary.Grey_3};
`;

const Wrapper = styled.div<{ sticky?: boolean }>`
  pointer-events: ${(props) => (props.sticky === true ? 'all' : 'none')};
  position: absolute;
  border: 1px solid ${(props) => (props.sticky === true ? settings.colors.Primary.Blue_9 : settings.colors.Primary.Grey_3)};
  border-radius: ${TOOLTIP_BORDER_RADIUS}px;
  font-family: ${settings.typography.FontFamily.Regular};
  font-size: ${settings.typography.FontSize.X_Small};
  line-height: ${settings.typography.LineHeight.Line_Height_14};
  background-color: ${settings.colors.Monochromatic.White};
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.05);
  ${(props) =>
    props.sticky === true &&
    `
    ${SectionBottom} { border-top-color: ${settings.colors.Primary.Blue_9};}`}
  overflow: hidden;
  ${(props) => props.sticky === true && 'z-index: 2'};
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${settings.Spacing.Spacing_50};
  padding: 5px ${settings.Spacing.Spacing_100};
  background-color: ${settings.colors.Primary.Grey_2};
  color: ${settings.colors.Primary.Grey_8};
  line-height: ${settings.typography.LineHeight.Line_Height_16};
`;

const Title = styled.span`
  font-family: ${settings.typography.FontFamily.Medium};
`;

const SubTitle = styled.span`
  font-family: ${settings.typography.FontFamily.Regular};
  margin-left: auto;
`;

const Body = styled.div`
  color: ${settings.colors.Primary.Grey_8};
  padding: ${settings.Spacing.Spacing_100};
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const Row = styled.div<{ noData?: boolean }>`
  display: flex;
  align-items: center;
  gap: ${settings.Spacing.Spacing_100};
  min-height: ${settings.Spacing.Spacing_200};
  line-height: ${settings.typography.LineHeight.Line_Height_14};
  font-size: ${settings.typography.FontSize.X_Small};
  color: ${(props) => (props.noData === true ? settings.colors.Primary.Grey_4 : settings.colors.Primary.Grey_8)};
`;

const Label = styled.div`
  white-space: nowrap;
`;

const Value = styled.div`
  margin-left: auto;
  white-space: nowrap;
`;

export default {
  Wrapper,
  Header,
  Title,
  SubTitle,
  Body,
  Row,
  Label,
  Value,
  Separator,
  CloseButton,
  SectionBottom,
};
