export interface ColumnRblAndVolume {
  slagLineSide0Rbl: undefined | null | number;
  slagLineSide1Rbl: undefined | null | number;
  ironLineSide0Rbl: undefined | null | number;
  ironLineSide1Rbl: undefined | null | number;
  volume: undefined | null | number;
  showSlagLineRow: boolean;
  showIronLineRow: boolean;
  showVolumeRow: boolean;
}

export interface ColumnWearRateAndVolume {
  slagLineSide0WearRate: undefined | null | number;
  slagLineSide1WearRate: undefined | null | number;
  ironLineSide0WearRate: undefined | null | number;
  ironLineSide1WearRate: undefined | null | number;
  volumeChangeRate: undefined | null | number;
  showSlagLineRow: boolean;
  showIronLineRow: boolean;
  showVolumeRow: boolean;
}

export enum ValueAlignment {
  Start = 'start',
  End = 'end',
}
