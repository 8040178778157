import { getColorUsingScale, isDefined } from '@rhim/utils';
import React, { useMemo } from 'react';

import { useColorScale } from '../../hooks/useColorScale';
import { GRAPH_HEIGHT, VERTICAL_SECTION_HORIZONTAL_MARGIN, VERTICAL_SECTIONS_CONTAINER_HORIZONTAL_MARGIN, VERTICAL_SECTIONS_COUNT } from './constants';

function getRandomThicknessArray() {
  const thickness: number[] = [];
  for (let i = 0; i < VERTICAL_SECTIONS_COUNT; i++) {
    thickness.push(Math.floor(Math.random() * 800));
  }
  return thickness;
}

export const THICKNESS_SIDE_0 = getRandomThicknessArray();
export const THICKNESS_SIDE_1 = getRandomThicknessArray();

interface Props {
  verticalSectionWidth: number;
}
const VerticalSections: React.ChildlessComponent<Props> = ({ verticalSectionWidth }) => {
  const colorScale = useColorScale();

  const verticalSections = useMemo(() => {
    const ret = [];
    for (let index = 0; index < VERTICAL_SECTIONS_COUNT; index++) {
      const centerX = index * verticalSectionWidth + verticalSectionWidth / 2;
      const width = verticalSectionWidth - 2 * VERTICAL_SECTION_HORIZONTAL_MARGIN;
      const side0Fill = isDefined(colorScale) ? getColorUsingScale(THICKNESS_SIDE_0[index]!, colorScale) : 'transparent';
      const side1Fill = isDefined(colorScale) ? getColorUsingScale(THICKNESS_SIDE_1[index]!, colorScale) : 'transparent';
      ret.push(<rect key={`side1-${index}`} x={centerX - width / 2} y={0} width={width} height={GRAPH_HEIGHT / 2} fill={side1Fill} />);
      ret.push(<rect key={`side0-${index}`} x={centerX - width / 2} y={GRAPH_HEIGHT / 2} width={width} height={GRAPH_HEIGHT / 2} fill={side0Fill} />);
    }
    return ret;
  }, [verticalSectionWidth, colorScale]);

  return (
    <g id="verticalSections" transform={`translate(${VERTICAL_SECTIONS_CONTAINER_HORIZONTAL_MARGIN} 0)`}>
      {verticalSections}
    </g>
  );
};

VerticalSections.whyDidYouRender = true;
export default React.memo(VerticalSections);
