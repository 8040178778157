import { settings } from '@rhim/design';

export const CONTAINER_HORIZONTAL_PADDING_PX = 48;
export const DEFAULT_GRAPH_HEIGHT_TOTAL = 200;
export const DEFAULT_GRAPH_X_AXIS_HEIGHT_PX = 22;
export const DEFAULT_GRAPH_AREA_HEIGHT = DEFAULT_GRAPH_HEIGHT_TOTAL - DEFAULT_GRAPH_X_AXIS_HEIGHT_PX;

export const SIDE_0_LINE_COLOR = settings.colors.Miscellaneous.Purple_Deep;
export const SIDE_1_LINE_COLOR = settings.colors.Miscellaneous.Blue;
export const VOLUME_LINE_COLOR = settings.colors.Primary.Blue_8;
export const AVERAGE_LINE_COLOR = settings.colors.Primary.Blue_8;
export const CRITICAL_LINE_COLOR = settings.colors.Operational.State_Alert_Orange_3;
export const REPAIR_MIX_AMOUNT_LINE_COLOR = settings.colors.Primary.Blue_8;
export const DELTA_VOLUME_BEFORE_AFTER_REPAIR_LINE_COLOR = settings.colors.Primary.Blue_8;
export const REPAIR_MIX_VOLUME_LINE_COLOR = settings.colors.Primary.Grey_6;
export const GRAPH_TOOLTIP_VERTICAL_LINE_COLOR = settings.colors.Primary.Grey_6;
export const GRAPH_TOOLTIP_REGION_FILL = settings.colors.Primary.Blue_8;
export const GRAPH_TOOLTIP_REGION_OPACITY = 0.1;

const WALLPLOT_COLUMN_COUNT = 30;
const LEFT_RIGHT_WALLPLOT_ROW_COUNT = 4;
const FLOOR_WALLPLOT_ROW_COUNT = 6;

function createMockWallplotData(rowCount: number, columnCount: number) {
  const ret: number[][] = [];
  for (let row = 0; row < rowCount; row++) {
    const rowData: number[] = [];
    for (let column = 0; column < columnCount; column++) {
      rowData.push(Math.floor(70 + Math.random() * 20));
    }
    ret.push(rowData);
  }
  return ret;
}

export const RIGHT_WALLPLOT_DATA: number[][] = createMockWallplotData(LEFT_RIGHT_WALLPLOT_ROW_COUNT, WALLPLOT_COLUMN_COUNT);
export const LEFT_WALLPLOT_DATA: number[][] = createMockWallplotData(LEFT_RIGHT_WALLPLOT_ROW_COUNT, WALLPLOT_COLUMN_COUNT);
export const FLOOR_WALLPLOT_DATA: number[][] = createMockWallplotData(FLOOR_WALLPLOT_ROW_COUNT, WALLPLOT_COLUMN_COUNT);

export const SHAPE_RENDERING_GEOMETRIC_PRECISION = 'geometricprecision';

export const UNIT_TON = 't';
export const UNIT_MM = 'mm';
export const UNIT_M = 'm';
export const UNIT_MM_PER_TON = 'mm/t';
export const UNIT_CUBIC_METERS = 'm³';
