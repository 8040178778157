import { settings } from '@rhim/design';
import { i18nReact } from '@rhim/i18n';
import { getFormattedDate } from '@rhim/react';
import { RHIMMeasurementServiceContractsDataLiningCondition } from '@rhim/rest/fleetOverview';
import { ensure, isDefined } from '@rhim/utils';
import { useMemo } from 'react';
import styled from 'styled-components';

import { getLiningConditionLabel } from '../../../../src/components/DataUploadPanel/QckLightPointCloudScanData/utils';
import {
  DELTA_VOLUME_BEFORE_AFTER_REPAIR_LINE_COLOR,
  REPAIR_MIX_VOLUME_LINE_COLOR,
  SIDE_0_LINE_COLOR,
  SIDE_1_LINE_COLOR,
  UNIT_CUBIC_METERS,
  UNIT_MM,
  UNIT_MM_PER_TON,
  UNIT_TON,
  VOLUME_LINE_COLOR,
} from '../components/constants';
import { GraphType, TooltipEntry } from '../components/GraphTooltip';
import GraphTooltipPropertyLegend, { PropertyType } from '../components/GraphTooltipPropertyLegend';
import { apiTonnageForDisplay } from '../utils';
import { MeasurementTuple } from './useMeasurementSequenceTuples';
import { useSidesLabels } from './useSidesLabels';

export function useTooltipContent(
  previousMeasurementTuple: undefined | MeasurementTuple,
  measurementTuple: undefined | MeasurementTuple,
  graphType: GraphType
) {
  const { t } = i18nReact.useTranslation(['blastFurnaceRunner-report']);
  const { t: tIngress } = i18nReact.useTranslation('ingress');
  const { side0Label, side1Label } = useSidesLabels();

  const headingPrimary = useMemo(() => {
    if (!isDefined(measurementTuple)) {
      return '';
    }
    switch (graphType) {
      case 'wearRateSlagline':
      case 'wearRateIronline': {
        const previousMeasurementTonnage = isDefined(previousMeasurementTuple)
          ? `${apiTonnageForDisplay(previousMeasurementTuple.tonnageKilograms, 'tons')}t`
          : '';
        const measurementTonnageInfo = `${apiTonnageForDisplay(measurementTuple.tonnageKilograms, 'tons')}t`;
        return `${previousMeasurementTonnage}${previousMeasurementTonnage !== '' ? ' - ' : ''}${measurementTonnageInfo}`;
      }
      case 'remainingThicknessSlagline':
      case 'remainingThicknessIronline':
      case 'thicknessDifferenceSlagline':
      case 'thicknessDifferenceIronline':
      case 'deltaVolumeVsRepairMixVolume':
      case 'repairMixAmount':
      case 'volume': {
        return `${apiTonnageForDisplay(measurementTuple.tonnageKilograms, 'tons')}t`;
      }
    }
  }, [previousMeasurementTuple, measurementTuple, graphType]);

  const headingSecondary = useMemo(() => {
    if (!isDefined(measurementTuple)) {
      return null;
    }
    switch (graphType) {
      case 'wearRateSlagline':
      case 'wearRateIronline': {
        return '';
      }
      case 'remainingThicknessSlagline':
      case 'remainingThicknessIronline':
      case 'thicknessDifferenceSlagline':
      case 'thicknessDifferenceIronline':
      case 'deltaVolumeVsRepairMixVolume':
      case 'repairMixAmount':
      case 'volume': {
        const measurement = measurementTuple.workingLiningMeasurement ?? ensure(measurementTuple.afterRepairMeasurement);
        return getFormattedDate({ datetime: measurement.measurementTaken as Date.ISO_8601 });
      }
    }
  }, [measurementTuple, graphType]);

  const content = useMemo(() => {
    if (!isDefined(measurementTuple)) {
      return null;
    }
    const afterRepairLabel = getLiningConditionLabel(tIngress, RHIMMeasurementServiceContractsDataLiningCondition.AfterRepair);
    const ret = [];

    switch (graphType) {
      case 'wearRateSlagline':
      case 'wearRateIronline': {
        const isSlagLine = graphType === 'wearRateSlagline';
        const line = isSlagLine ? 'Slagline' : 'Ironline';
        const workingLiningSide0Rbl = measurementTuple.workingLiningMeasurement?.[`side0${line}WearRate`];
        const workingLiningSide1Rbl = measurementTuple.workingLiningMeasurement?.[`side1${line}WearRate`];
        const lineLabel = isSlagLine ? t('blastFurnaceRunner-report:slagLine') : t('blastFurnaceRunner-report:ironLine');

        ret.push(
          <TooltipEntry
            propertyLegend={<GraphTooltipPropertyLegend propertyType={PropertyType.Line} fill={SIDE_0_LINE_COLOR} />}
            propertyName={`${lineLabel} ${side0Label}`}
            propertyValue={workingLiningSide0Rbl}
            propertyValueUnit={UNIT_MM_PER_TON}
          />
        );
        ret.push(<SHorizontalDivider />);
        ret.push(
          <TooltipEntry
            propertyLegend={<GraphTooltipPropertyLegend propertyType={PropertyType.Line} fill={SIDE_1_LINE_COLOR} />}
            propertyName={`${lineLabel} ${side1Label}`}
            propertyValue={workingLiningSide1Rbl}
            propertyValueUnit={UNIT_MM_PER_TON}
          />
        );
        break;
      }
      case 'remainingThicknessSlagline':
      case 'remainingThicknessIronline': {
        const isSlagLine = graphType === 'remainingThicknessSlagline';
        const line = isSlagLine ? 'Slagline' : 'Ironline';
        const workingLiningSide0Rbl = measurementTuple.workingLiningMeasurement?.[`side0${line}Rbl`];
        const workingLiningSide1Rbl = measurementTuple.workingLiningMeasurement?.[`side1${line}Rbl`];
        const afterRepairSide0Rbl = measurementTuple.afterRepairMeasurement?.[`side0${line}Rbl`];
        const afterRepairSide1Rbl = measurementTuple.afterRepairMeasurement?.[`side1${line}Rbl`];
        const lineLabel = isSlagLine ? t('blastFurnaceRunner-report:slagLine') : t('blastFurnaceRunner-report:ironLine');
        const showAfterRepairRow = isDefined(measurementTuple.afterRepairMeasurement);

        ret.push(
          <TooltipEntry
            propertyLegend={<GraphTooltipPropertyLegend propertyType={PropertyType.WorkingLining} fill={SIDE_0_LINE_COLOR} />}
            propertyName={`${lineLabel} ${side0Label}`}
            propertyValue={workingLiningSide0Rbl}
            propertyValueUnit={UNIT_MM}
          />
        );
        if (!showAfterRepairRow) {
          ret.push(<SHorizontalDivider />);
        }
        if (showAfterRepairRow) {
          ret.push(
            <TooltipEntry
              propertyLegend={<GraphTooltipPropertyLegend propertyType={PropertyType.AfterRepair} fill={SIDE_0_LINE_COLOR} />}
              propertyName={`${lineLabel} ${side0Label} (${afterRepairLabel})`}
              propertyValue={afterRepairSide0Rbl}
              propertyValueUnit={UNIT_MM}
            />
          );
        }
        if (showAfterRepairRow) {
          ret.push(<SHorizontalDivider />);
        }
        ret.push(
          <TooltipEntry
            propertyLegend={<GraphTooltipPropertyLegend propertyType={PropertyType.WorkingLining} fill={SIDE_1_LINE_COLOR} />}
            propertyName={`${lineLabel} ${side1Label}`}
            propertyValue={workingLiningSide1Rbl}
            propertyValueUnit={UNIT_MM}
          />
        );
        if (showAfterRepairRow) {
          ret.push(
            <TooltipEntry
              propertyLegend={<GraphTooltipPropertyLegend propertyType={PropertyType.AfterRepair} fill={SIDE_1_LINE_COLOR} />}
              propertyName={`${lineLabel} ${side1Label} (${afterRepairLabel})`}
              propertyValue={afterRepairSide1Rbl}
              propertyValueUnit={UNIT_MM}
            />
          );
        }
        break;
      }
      case 'thicknessDifferenceSlagline':
      case 'thicknessDifferenceIronline': {
        const isSlagLine = graphType === 'thicknessDifferenceSlagline';
        const line = isSlagLine ? 'Slagline' : 'Ironline';

        const afterRepairSide0Rbl = measurementTuple.afterRepairMeasurement?.[`side0${line}Rbl`];
        const workingLiningSide0Rbl = measurementTuple.workingLiningMeasurement?.[`side0${line}Rbl`];
        const side0DeltaRbl = isDefined(afterRepairSide0Rbl) && isDefined(workingLiningSide0Rbl) ? afterRepairSide0Rbl - workingLiningSide0Rbl : undefined;

        const afterRepairSide1Rbl = measurementTuple.afterRepairMeasurement?.[`side1${line}Rbl`];
        const workingLiningSide1Rbl = measurementTuple.workingLiningMeasurement?.[`side1${line}Rbl`];
        const side1DeltaRbl = isDefined(afterRepairSide1Rbl) && isDefined(workingLiningSide1Rbl) ? afterRepairSide1Rbl - workingLiningSide1Rbl : undefined;

        const lineLabel = isSlagLine ? t('blastFurnaceRunner-report:slagLine') : t('blastFurnaceRunner-report:ironLine');

        ret.push(
          <TooltipEntry
            propertyLegend={<GraphTooltipPropertyLegend propertyType={PropertyType.Rectangle} fill={SIDE_0_LINE_COLOR} />}
            propertyName={`${lineLabel} ${side0Label}`}
            propertyValue={side0DeltaRbl}
            propertyValueUnit={UNIT_MM}
          />
        );
        ret.push(<SHorizontalDivider />);
        ret.push(
          <TooltipEntry
            propertyLegend={<GraphTooltipPropertyLegend propertyType={PropertyType.Rectangle} fill={SIDE_1_LINE_COLOR} />}
            propertyName={`${lineLabel} ${side1Label}`}
            propertyValue={side1DeltaRbl}
            propertyValueUnit={UNIT_MM}
          />
        );
        break;
      }
      case 'deltaVolumeVsRepairMixVolume': {
        const workingLiningVolume =
          isDefined(measurementTuple.workingLiningMeasurement) && isDefined(measurementTuple.workingLiningMeasurement.volume)
            ? measurementTuple.workingLiningMeasurement.volume
            : null;
        const afterRepairVolume =
          isDefined(measurementTuple.afterRepairMeasurement) && isDefined(measurementTuple.afterRepairMeasurement.volume)
            ? measurementTuple.afterRepairMeasurement.volume
            : null;
        const deltaVolume = isDefined(workingLiningVolume) && isDefined(afterRepairVolume) ? workingLiningVolume - afterRepairVolume : undefined;
        const repairMixVolume = measurementTuple.repairMixVolume;

        ret.push(
          <TooltipEntry
            propertyLegend={<GraphTooltipPropertyLegend propertyType={PropertyType.Rectangle} fill={DELTA_VOLUME_BEFORE_AFTER_REPAIR_LINE_COLOR} />}
            propertyName={t('blastFurnaceRunner-report:campaignReport.graphVolumeRepairDeltaVsMixVolume.graphBarLabelA')}
            propertyValue={deltaVolume}
            propertyValueUnit={UNIT_CUBIC_METERS}
          />
        );
        ret.push(<SHorizontalDivider />);
        ret.push(
          <TooltipEntry
            propertyLegend={<GraphTooltipPropertyLegend propertyType={PropertyType.Rectangle} fill={REPAIR_MIX_VOLUME_LINE_COLOR} />}
            propertyName={t('blastFurnaceRunner-report:campaignReport.graphVolumeRepairDeltaVsMixVolume.graphBarLabelB')}
            propertyValue={repairMixVolume}
            propertyValueUnit={UNIT_CUBIC_METERS}
          />
        );
        break;
      }
      case 'repairMixAmount': {
        const repairMixAmountLabel = t('blastFurnaceRunner-report:campaignReport.graphRepairMixAmount.yAxisLabel');
        const repairMixAmount = isDefined(measurementTuple.repairMixAmountKilograms)
          ? apiTonnageForDisplay(measurementTuple.repairMixAmountKilograms, 'tons')
          : undefined;

        ret.push(<TooltipEntry propertyName={repairMixAmountLabel} propertyValue={repairMixAmount} propertyValueUnit={UNIT_TON} />);
        break;
      }
      case 'volume': {
        const workingLiningVolume = measurementTuple.workingLiningMeasurement?.volume;
        const afterRepairVolume = measurementTuple.afterRepairMeasurement?.volume;
        const volumeLabel = t('blastFurnaceRunner-report:campaignReport.graphVolume.yAxisLabel');

        ret.push(
          <TooltipEntry
            propertyLegend={<GraphTooltipPropertyLegend propertyType={PropertyType.WorkingLining} fill={VOLUME_LINE_COLOR} />}
            propertyName={volumeLabel}
            propertyValue={workingLiningVolume}
            propertyValueUnit={UNIT_CUBIC_METERS}
          />
        );
        ret.push(<SHorizontalDivider />);
        ret.push(
          <TooltipEntry
            propertyLegend={<GraphTooltipPropertyLegend propertyType={PropertyType.AfterRepair} fill={VOLUME_LINE_COLOR} />}
            propertyName={`${volumeLabel} (${afterRepairLabel})`}
            propertyValue={afterRepairVolume}
            propertyValueUnit={UNIT_CUBIC_METERS}
          />
        );
        break;
      }
    }
    return ret;
  }, [t, measurementTuple, graphType, tIngress, side0Label, side1Label]);

  return { headingPrimary, headingSecondary, content };
}

const SHorizontalDivider = styled.div`
  height: 1px;
  background-color: ${settings.colors.Primary.Grey_2};
`;
