import { Spinner } from '@rhim/react';
import { VesselType } from '@rhim/rest';
import { useMeasurementviewSettings } from '@rhim/sdk/measurementService';
import { isDefined } from '@rhim/utils';
import { useContext } from 'react';

import AppContext from '../../../app/AppContext';
import { useSelectedVessel } from '../../../hooks/useSelectedVessel';

export function useSidesLabels() {
  const { selectedCustomer, vessels } = useContext(AppContext);
  const { selectedVesselId } = useSelectedVessel(vessels);

  const customerId = selectedCustomer.customerId as UUID;
  const { data: measurementViewSettings } = useMeasurementviewSettings(customerId, VesselType.Bfr, selectedVesselId, { configuration: { suspense: false } });

  return {
    side0Label: isDefined(measurementViewSettings) ? measurementViewSettings.side0DisplayName : <Spinner size="16" />,
    side1Label: isDefined(measurementViewSettings) ? measurementViewSettings.side1DisplayName : <Spinner size="16" />,
  };
}
