import { BoundingBox, DynamicTexture, Scene } from '@babylonjs/core';
import { ICanvasRenderingContext } from '@babylonjs/core/Engines/ICanvas';
import {
  RHIMAPOWearManagementApiClientRegionDto,
  RHIMContractsRegionLocation,
  RHIMMeasurementServiceV1ModelsMeasurementMetadataDto,
} from '@rhim/rest/measurementService';
import { assert, isDefined } from '@rhim/utils';

import { TextureCoordinatesIndex } from './materials';

export interface Region {
  name: string;
  angleStart: number;
  angleEnd: number;
  yTop: number;
  yBottom: number;
  color: string;
  isClicked?: boolean;
  isWidget?: boolean;
  shouldRotateText?: boolean;
}

interface LineRegion {
  depth: number;
  color: string;
}

export const REGIONS_DOMAIN_HEIGHT_MAX = 100;
const DYNAMIC_TEXTURE_WIDTH = 2048;
const DYNAMIC_TEXTURE_HEIGHT = 2048;
const DYNAMIC_TEXTURE_SIDE_PART_HEIGHT = DYNAMIC_TEXTURE_HEIGHT / 2;
const REGION_LINE_WIDTH = 1; // width in pixels

/**
 * Create a transparent canvas with highlighted regions and its names
 *
 * @param scene
 * @param regions
 * @returns
 */
export const createTextureWithRegions = (scene: Scene, regions: Region[]): DynamicTexture => {
  const dynamicTexture = new DynamicTexture('dynamicTexture', { width: DYNAMIC_TEXTURE_WIDTH, height: DYNAMIC_TEXTURE_HEIGHT }, scene);
  dynamicTexture.coordinatesIndex = TextureCoordinatesIndex.CylindricalCoordinates;
  const dynamicTextureContext = dynamicTexture.getContext();

  // clear canvas
  dynamicTextureContext.fillStyle = 'transparent';
  dynamicTextureContext.fillRect(0, 0, DYNAMIC_TEXTURE_WIDTH, DYNAMIC_TEXTURE_SIDE_PART_HEIGHT);

  for (const region of regions) {
    const doesAreaWrap = region.angleStart > region.angleEnd;
    dynamicTextureContext.fillStyle = region.color;
    dynamicTextureContext.strokeStyle = region.color;
    dynamicTextureContext.lineWidth = REGION_LINE_WIDTH;
    const startX = regionAngleToCanvasX(region.angleStart);
    const startY = Math.max(regionYToCanvasY(region.yTop), REGION_LINE_WIDTH + 1);
    const bottomY = regionYToCanvasY(region.yBottom);
    if (doesAreaWrap) {
      // create a shape that "wraps" around the 360 degree mark
      dynamicTextureContext.beginPath();
      dynamicTextureContext.moveTo(startX, startY);
      dynamicTextureContext.lineTo(regionAngleToCanvasX(360), startY);
      dynamicTextureContext.stroke();
      dynamicTextureContext.moveTo(0, startY);
      dynamicTextureContext.lineTo(regionAngleToCanvasX(region.angleEnd), startY);
      dynamicTextureContext.lineTo(regionAngleToCanvasX(region.angleEnd), bottomY);
      dynamicTextureContext.lineTo(0, bottomY);
      dynamicTextureContext.stroke();
      dynamicTextureContext.moveTo(regionAngleToCanvasX(360), bottomY);
      dynamicTextureContext.lineTo(startX, bottomY);
      dynamicTextureContext.lineTo(startX, startY);
      dynamicTextureContext.stroke();
    } else {
      dynamicTextureContext.strokeRect(
        startX,
        startY,
        regionAngleToCanvasX(region.angleEnd - region.angleStart),
        regionYToCanvasY(region.yBottom - region.yTop)
      );
    }
    dynamicTextureContext.clearRect(0, DYNAMIC_TEXTURE_SIDE_PART_HEIGHT, DYNAMIC_TEXTURE_WIDTH, DYNAMIC_TEXTURE_SIDE_PART_HEIGHT);
    //displayRegionName(dynamicTexture, region);
  }
  // update dynamic texture
  dynamicTexture.update();

  return dynamicTexture;
};

function drawRectBottom(regions: RHIMAPOWearManagementApiClientRegionDto[], context: ICanvasRenderingContext, vesselLength: number, segmentSize: number) {
  const { width, height } = context.canvas;
  // storing context for clipping only area for the bottom part
  context.save();
  context.beginPath();
  context.rect(0, (2 * height) / 3, width, height / 3);
  context.clip();

  for (const region of regions) {
    if (!region.area || region.area.length === 0) {
      continue;
    }

    context.beginPath();

    for (let i = 0; i < region.area.length; i++) {
      const { x, y } = region.area[i]!;
      const u = x! / vesselLength;

      let v = 1 - (y! + segmentSize / 2) / segmentSize;
      // converting the V to use 1/3 of the canvas
      v /= 3;

      // v is flipped from right to left-handed
      v = 1 - v;

      // console.log(`${x} -> ${u} | ${y} -> ${v}`);

      if (i === 0) {
        context.moveTo(u * width, v * height);
      } else {
        context.lineTo(u * width, v * height);
      }
    }

    context.closePath();
    context.stroke();
  }

  context.restore();
}

function drawRectSide0(
  regions: RHIMAPOWearManagementApiClientRegionDto[],
  context: ICanvasRenderingContext,
  vesselLength: number,
  segmentSize: number,
  zeroHeightV: number,
  lineRegions: LineRegion[] = [],
  uOffset = 0
) {
  const { width, height } = context.canvas;
  const rescaledZeroHeightV = 3 * zeroHeightV - 1;
  /**
   * Zero height corresponds to 0 by depth axis, now we need to find the border of the side0
   * When we have the boundaries, we can interpolate the points of depth back to UV map
   */
  const p0 = -rescaledZeroHeightV * segmentSize; // negative part
  const p1 = (1 - rescaledZeroHeightV) * segmentSize; // positive part

  // storing context for clipping only area for the Side0 part
  context.save();
  context.beginPath();
  context.rect(0, height / 3, width, height / 3);
  context.clip();

  for (const region of regions) {
    if (!region.area || region.area.length === 0) {
      continue;
    }

    context.strokeStyle = '#003262';
    context.beginPath();

    for (let i = 0; i < region.area.length; i++) {
      const { x, z } = region.area[i]!;
      const u = x! / vesselLength + uOffset;

      let v = 1 - (p1 - z!) / (p1 - p0);
      // moving back to the center part of the UV map
      v = (v + 1) / 3;

      // aligning right to left-handed engines
      v = 1 - v;

      // console.log(zeroHeightV, `${x} -> ${u} | ${z} -> ${v}`);

      if (i === 0) {
        context.moveTo(u * width, v * height);
      } else {
        context.lineTo(u * width, v * height);
      }
    }

    context.closePath();
    context.stroke();
  }

  for (const lineRegion of lineRegions) {
    context.strokeStyle = lineRegion.color;
    context.beginPath();
    let v = 1 - (p1 - lineRegion.depth) / (p1 - p0);
    v = (v + 1) / 3;
    v = 1 - v;
    context.moveTo(0, v * height);
    context.lineTo(width, v * height);
    context.closePath();
    context.stroke();
  }

  context.restore();
}

function drawRectSide1(
  regions: RHIMAPOWearManagementApiClientRegionDto[],
  context: ICanvasRenderingContext,
  vesselLength: number,
  segmentSize: number,
  zeroHeightV: number,
  lineRegions: LineRegion[] = [],
  uOffset = 0
) {
  const { width, height } = context.canvas;
  /**
   * Zero height of the side1 is located in the bottom of the 3'd part of the texture, meaning
   * we need to find its scaled part by `zeroHeightV - 2/3` and multiply the result by 3. As the side1
   * is mirrored, we need to invert it to apply a correct interpolation
   */
  const rescaledZeroHeightV = 1 - 3 * (zeroHeightV - 2 / 3); // inverted value as side 1 is flipped
  /**
   * Zero height corresponds to 0 by depth axis, now we need to find the border of the side0
   * When we have the boundaries, we can interpolate the points of depth back to UV map
   */
  const p0 = -rescaledZeroHeightV * segmentSize; // negative part
  const p1 = (1 - rescaledZeroHeightV) * segmentSize; // positive part

  // storing context for clipping only area for the Side1 part
  context.save();
  context.beginPath();
  context.rect(0, 0, width, height / 3);
  context.clip();

  for (const region of regions) {
    if (!region.area || region.area.length === 0) {
      continue;
    }

    context.beginPath();

    for (let i = 0; i < region.area.length; i++) {
      const { x, z } = region.area[i]!;
      const u = x! / vesselLength + uOffset;

      let v = 1 - (p1 - z!) / (p1 - p0);
      // inverting it back
      v = 1 - v;

      // moving back to the bottom part of the UV map
      v = (v + 2) / 3;

      // aligning right to left-handed engines
      v = 1 - v;

      // console.log(zeroHeightV, `${x} -> ${u} | ${z} -> ${v}`);

      if (i === 0) {
        context.moveTo(u * width, v * height);
      } else {
        context.lineTo(u * width, v * height);
      }
    }

    context.closePath();
    context.stroke();
  }

  for (const lineRegion of lineRegions) {
    context.strokeStyle = lineRegion.color;
    context.beginPath();
    let v = 1 - (p1 - lineRegion.depth) / (p1 - p0);
    v = 1 - v;
    v = (v + 2) / 3;
    v = 1 - v;
    context.moveTo(0, v * height);
    context.lineTo(width, v * height);
    context.closePath();
    context.stroke();
  }

  context.restore();
}

/**
 * UV is shared between segments 1:3 vertically
 * Upper part defines bottom part of the vessel
 * Middle part defines size0
 * Bottom part defines side1 and it is flipped
 *
 * @param scene
 * @param regions
 * @param metadata
 */
export function createTextureRectangular(
  scene: Scene,
  regions: RHIMAPOWearManagementApiClientRegionDto[],
  metadata: RHIMMeasurementServiceV1ModelsMeasurementMetadataDto,
  boundingBox?: BoundingBox
): DynamicTexture {
  const { uvAspectRatio, vesselLength, uvSegmentSize, uvZeroHeightSide0V, uvZeroHeightSide1V } = metadata;
  assert(isDefined(uvSegmentSize), 'uvSegmentSize is not defined');

  const dynamicTexture = new DynamicTexture('dynamicTexture', { width: DYNAMIC_TEXTURE_WIDTH, height: DYNAMIC_TEXTURE_HEIGHT / uvAspectRatio }, scene);
  dynamicTexture.coordinatesIndex = TextureCoordinatesIndex.CylindricalCoordinates;
  const dynamicTextureContext = dynamicTexture.getContext();

  let uOffset = 0;

  if (isDefined(boundingBox)) {
    const { minimum, maximum } = boundingBox;
    const realOffset = Math.abs(maximum.z);
    const totalLength = maximum.z - minimum.z;
    uOffset = Math.round(realOffset / totalLength);
  }

  dynamicTextureContext.lineWidth = 1;
  dynamicTextureContext.strokeStyle = 'black';

  const bottom = regions.filter((region) => region.regionLocation === RHIMContractsRegionLocation.Bottom);
  drawRectBottom(bottom, dynamicTextureContext, vesselLength, uvSegmentSize);

  const side0 = regions.filter((region) => region.regionLocation === RHIMContractsRegionLocation.Side0);
  const zeroHeightSide0 = uvZeroHeightSide0V ?? 1 / 3;
  const lineRegions: LineRegion[] = [];
  if (metadata.ironlineDepth > 0) {
    lineRegions.push({ depth: metadata.ironlineDepth, color: 'white' });
  }
  if (metadata.slaglineDepth > 0) {
    lineRegions.push({ depth: metadata.slaglineDepth, color: 'white' });
  }
  drawRectSide0(side0, dynamicTextureContext, vesselLength, uvSegmentSize, zeroHeightSide0, lineRegions, uOffset);

  const side1 = regions.filter((region) => region.regionLocation === RHIMContractsRegionLocation.Side1);
  const zeroHeightSide1 = uvZeroHeightSide1V ?? 1;
  drawRectSide1(side1, dynamicTextureContext, vesselLength, uvSegmentSize, zeroHeightSide1, lineRegions, uOffset);

  // rendering the latest context into the texture
  dynamicTexture.update();

  return dynamicTexture;
}

const regionAngleToCanvasX = (regionAngle: number): number => {
  return Math.floor((DYNAMIC_TEXTURE_WIDTH * regionAngle) / 360);
};

const regionYToCanvasY = (regionY: number): number => {
  return Math.floor((DYNAMIC_TEXTURE_SIDE_PART_HEIGHT * regionY) / REGIONS_DOMAIN_HEIGHT_MAX);
};
