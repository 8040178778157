import { AXIS_STYLES, TICK_LABEL_PROPS } from '@rhim/chart/v2/axis';
import { settings } from '@rhim/design';
import { ensure, hasElements } from '@rhim/utils';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { scaleLinear } from '@visx/scale';
import React, { useEffect, useMemo, useRef } from 'react';
import { useMeasure } from 'react-use';
import styled from 'styled-components';

import { SSVGContainer as SVGContainer } from '../styles';
import { DEFAULT_GRAPH_X_AXIS_HEIGHT_PX } from './constants';
import GraphHeader from './GraphHeader';

const GRID_COLOR = settings.colors.Primary.Grey_4;
const GRID_CELL_VALUE_COLOR = settings.colors.Primary.Grey_9;
const INDICATOR_LINE_RIGHT_EXTEND_PX = 6;

interface Props {
  yAxisLabel: string;
  plotHeight: number;
  wallplotData: number[][];
  xAxisRange: [number, number];
  yAxisRange: [number, number];
  indicators: { label: string; y: number; color: string }[];
}
const Wallplot: React.ChildlessComponent<Props> = ({ yAxisLabel, plotHeight, wallplotData, xAxisRange, yAxisRange, indicators }) => {
  const [svgContainerRef, { width }] = useMeasure<SVGSVGElement>();
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const rowCount = wallplotData.length;
  const columnCount = hasElements(wallplotData) ? ensure(wallplotData[0].length) : 0;

  const xGridScale = scaleLinear({ domain: [0, columnCount], range: [0, width] });
  const yGridScale = scaleLinear({
    domain: [0, rowCount],
    range: [0, plotHeight],
  });

  const xWallplotScale = scaleLinear({
    domain: [xAxisRange[0], xAxisRange[1]],
    range: [0, width],
  });
  const yWallplotScale = scaleLinear({
    domain: [yAxisRange[0], yAxisRange[1]],
    range: [0, plotHeight],
  });

  const rowLines = useMemo(() => {
    const ret = [];
    for (let row = 0; row < rowCount - 1; row++) {
      const rowY = yGridScale(row + 1);
      ret.push(<line x1={0} y1={rowY} x2={width} y2={rowY} stroke={GRID_COLOR} />);
    }
    return ret;
  }, [width, rowCount, yGridScale]);

  const columnLines = useMemo(() => {
    const ret = [];
    for (let column = 0; column < columnCount - 1; column++) {
      const columnX = xGridScale(column + 1);
      ret.push(<line x1={columnX} y1={0} x2={columnX} y2={plotHeight} stroke={GRID_COLOR} />);
    }
    return ret;
  }, [plotHeight, columnCount, xGridScale]);

  const plotBorder = useMemo(() => {
    const ret = [];
    ret.push(<line x1={0} y1={0} x2={width} y2={0} stroke={GRID_COLOR} />);
    ret.push(<line x1={width} y1={0} x2={width} y2={plotHeight} stroke={GRID_COLOR} />);
    ret.push(<line x1={0} y1={plotHeight} x2={width} y2={plotHeight} stroke={GRID_COLOR} />);
    ret.push(<line x1={0} y1={0} x2={0} y2={plotHeight} stroke={GRID_COLOR} />);
    return ret;
  }, [plotHeight, width]);

  const cellValues = useMemo(() => {
    const ret = [];
    const cellWidth = width / columnCount;
    const cellHeight = plotHeight / rowCount;
    for (let row = 0; row < rowCount; row++) {
      const cellCenterY = yGridScale(row) + cellHeight / 2;
      for (let column = 0; column < columnCount; column++) {
        const cellCenterX = xGridScale(column) + cellWidth / 2;
        const cellValue = wallplotData[row]![column];
        ret.push(
          <text x={cellCenterX} y={cellCenterY} textAnchor="middle" dominantBaseline="middle" fill={GRID_CELL_VALUE_COLOR}>
            {cellValue}
          </text>
        );
      }
    }
    return ret;
  }, [width, plotHeight, rowCount, columnCount, wallplotData, xGridScale, yGridScale]);

  const lineIndicators = useMemo(() => {
    const ret = [];
    for (const indicator of indicators) {
      const lineY = yWallplotScale(indicator.y);
      ret.push(<SIndicatorLine y={lineY} fill={indicator.color} label={indicator.label} />);
    }
    return ret;
  }, [yWallplotScale, indicators]);

  const xAxisTicks = useMemo(() => {
    return xWallplotScale.ticks(10);
  }, [xWallplotScale]);

  const yAxisTicks = useMemo(() => {
    return yWallplotScale.ticks(4);
  }, [yWallplotScale]);

  /**
   * Repaints canvas whenever its dimensions change
   */
  useEffect(() => {
    if (plotHeight === 0 || width === 0) {
      return;
    }
    const canvasElement = ensure(canvasRef.current);
    const ctx = ensure(canvasElement.getContext('2d'));
    const imgData = ctx.createImageData(width, plotHeight);

    for (let i = 0; i < imgData.data.length; i += 4) {
      imgData.data[i + 0] = Math.floor(Math.random() * 255);
      imgData.data[i + 1] = Math.floor(Math.random() * 255);
      imgData.data[i + 2] = 0;
      imgData.data[i + 3] = 100; // alpha
    }
    ctx.putImageData(imgData, 0, 0);
  }, [plotHeight, width]);

  const svgTotalHeight = plotHeight + DEFAULT_GRAPH_X_AXIS_HEIGHT_PX;

  return (
    <>
      <GraphHeader yAxisLabel={yAxisLabel} graphLegends={[]} />
      <SWrapper height={svgTotalHeight}>
        <SCanvas ref={canvasRef} width={width} height={plotHeight} />
        <SSVGContainer ref={svgContainerRef} height={svgTotalHeight}>
          <AxisLeft scale={yWallplotScale} tickValues={yAxisTicks} {...AXIS_STYLES} />
          <AxisBottom
            top={plotHeight}
            scale={xWallplotScale}
            tickValues={xAxisTicks}
            tickLength={AXIS_STYLES.tickLength}
            stroke={settings.colors.Primary.Grey_4}
            tickStroke={settings.colors.Primary.Grey_6}
            tickLabelProps={TICK_LABEL_PROPS}
          />
          {plotBorder}
          {rowLines}
          {columnLines}
          {cellValues}
        </SSVGContainer>
        {lineIndicators}
      </SWrapper>
    </>
  );
};

const SWrapper = styled.div<{ height: number }>`
  position: relative;
  height: ${(props) => props.height}px;
  margin-right: 80px;
`;

const SCanvas = styled.canvas<{ height: number }>`
  width: 100%;
  height: ${(props) => props.height}px;
`;

const SSVGContainer = styled(SVGContainer)`
  position: absolute;
  left: 0;
`;

const SIndicatorLine = styled.div<{ y: number; fill: string; label: string }>`
  position: absolute;
  left: 0;
  top: ${(props) => props.y}px;
  transform: translateY(-50%);
  height: 2px;
  width: calc(100% + ${INDICATOR_LINE_RIGHT_EXTEND_PX}px);
  background-color: ${(props) => props.fill};

  &::before {
    display: block;
    content: ${(props) => `"${props.label}"`};
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(100%, -50%);
    padding: 0 4px;
    background-color: ${(props) => props.fill};
    color: ${settings.colors.Monochromatic.White};
    font-size: ${settings.typography.FontSize.Small};
    font-family: ${settings.typography.FontFamily.Medium};
    line-height: 16px;
    border-radius: 3px;
  }
`;

Wallplot.whyDidYouRender = true;
export default React.memo(Wallplot);
