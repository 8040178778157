import { roundFloatToDecimalPlaces } from '@rhim/utils';
import { scaleLinear } from '@visx/scale';
import { NumberValue } from 'd3-scale';

export type MeausurementStateA = 'safetyLining' | 'workLiningUsedDefault' | 'workLiningPartial';
export type MeausurementStateB = 'workLiningInitial' | 'workLiningUsedAfterRepair' | null;

export enum MeasurementState {
  SafetylLining = 'SafetylLining',
  WorkLiningUsedDefault = 'WorkLiningUsedDefault',
  WorkLiningPartial = 'WorkLiningPartial',
  WorkLiningInitial = 'WorkLiningInitial',
  WorkLiningUsedAfterRepair = 'WorkLiningUsedAfterRepair',
}

export interface Measurement {
  id: number;
  state: MeasurementState;
}

export interface MeasurementInfo {
  atKilograms: number;
  measurements: [Measurement, Measurement | null];
  fillVolumneBefore: number;
  fillVolumneAfter: number;
  repairMixAmount: number;
  deltaVolume: number;
  repairMixVolume: number;
  deltaRBLSlagLine: number;
  deltaRBLIronLine: number;
  slagLine: {
    wearRate: number;
    thicknessBefore: number;
    thicknessAfter: number;
  };
  ironLine: {
    wearRate: number;
    thicknessBefore: number;
    thicknessAfter: number;
  };
}

export const LABEL_FONT_SIZE_PX = 12;
export const LABEL_PADDING_TOP_PX = 8;
export const LABEL_PADDING_BOTTOM_PX = 4;
const TOTAL_LABEL_HEIGHT_PX = LABEL_FONT_SIZE_PX + LABEL_PADDING_TOP_PX + LABEL_PADDING_BOTTOM_PX;
export function getPaddedDomainY(graphAreaHeight: number, graphDomainYMaximum: number) {
  const yScale = scaleLinear({
    range: [graphAreaHeight, 0],
    domain: [0, graphDomainYMaximum],
  });
  const paddedDomainValue = yScale.invert(TOTAL_LABEL_HEIGHT_PX);
  return graphDomainYMaximum + (graphDomainYMaximum - paddedDomainValue);
}

export const xAxisTickFormat = (d: NumberValue) => `${apiTonnageForDisplay(d.valueOf(), 'kiloTons')}`;

export function apiTonnageForDisplay(apiTonnageInKilograms: number, mode: 'tons' | 'kiloTons') {
  const value = mode === 'tons' ? apiTonnageInKilograms / 1000 : apiTonnageInKilograms / 1_000_000;
  return roundFloatToDecimalPlaces(value, 2);
}
