import { settings } from '@rhim/design';
import { isDefined } from '@rhim/utils';
import React from 'react';
import styled from 'styled-components';

export type GraphType =
  | 'wearRateSlagline'
  | 'wearRateIronline'
  | 'remainingThicknessSlagline'
  | 'remainingThicknessIronline'
  | 'thicknessDifferenceSlagline'
  | 'thicknessDifferenceIronline'
  | 'deltaVolumeVsRepairMixVolume'
  | 'repairMixAmount'
  | 'volume';

interface TooltipEntryProps {
  propertyLegend?: React.ReactNode;
  propertyName: string;
  propertyValue: undefined | null | number;
  propertyValueUnit: string;
}
const TooltipEntryComponent: React.ChildlessComponent<TooltipEntryProps> = ({ propertyLegend, propertyName, propertyValue, propertyValueUnit }) => {
  return (
    <STooltipEntryWrapper>
      {propertyLegend ?? null}
      {isDefined(propertyLegend) && <SMarginRight />}
      <SPropertyName>{propertyName}</SPropertyName>
      <SHorizontalSpring />
      <SPropertyValue>{propertyValue ?? '—'}</SPropertyValue>
      <SUnit>{propertyValueUnit}</SUnit>
    </STooltipEntryWrapper>
  );
};
export const TooltipEntry = React.memo(TooltipEntryComponent);

interface Referable {
  ref?: React.Ref<HTMLTableRowElement>;
}

interface Props {
  tooltipPosition: [number, number];
  headingPrimary: string;
  headingSecondary: null | string;
  children: React.ReactNode;
}
const GraphTooltip = React.forwardRef<HTMLDivElement, Props & Referable>(function GraphTooltip(
  { tooltipPosition, headingPrimary, headingSecondary, children },
  forwaredRef
) {
  return (
    <SWrapper ref={forwaredRef} tooltipPosition={tooltipPosition}>
      <SHeader>
        <SHeadingPrimary>{headingPrimary}</SHeadingPrimary>
        <SHeadingSecondary>{headingSecondary}</SHeadingSecondary>
      </SHeader>
      {children}
    </SWrapper>
  );
});

const SWrapper = styled.div<{ tooltipPosition: [number, number] }>`
  position: absolute;
  left: ${(props) => props.tooltipPosition[0]}px;
  top: ${(props) => props.tooltipPosition[1]}px;
  pointer-events: none;
  border: 1px solid ${settings.colors.Primary.Grey_3};
  border-radius: 2px;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.05);
  background-color: ${settings.colors.Monochromatic.White};
  display: flex;
  flex-direction: column;
`;

const STooltipEntryWrapper = styled.div`
  height: 22px;
  display: flex;
  align-items: center;
  padding: 0 ${settings.Spacing.Spacing_100};
`;

const SPropertyName = styled.span`
  font-family: ${settings.typography.FontFamily.Regular};
  font-size: ${settings.typography.FontSize.X_Small};
  color: ${settings.colors.Primary.Grey_8};
  white-space: nowrap;
`;

const SMarginRight = styled.span`
  margin-right: ${settings.Spacing.Spacing_100};
`;

const SUnit = styled(SPropertyName)`
  margin-left: ${settings.Spacing.Spacing_50};
`;

const SPropertyValue = styled(SPropertyName)`
  font-family: ${settings.typography.FontFamily.Bold};
`;

const SHorizontalSpring = styled.span`
  flex-grow: 1;
  margin: 0 ${settings.Spacing.Spacing_100};
`;

const SHeader = styled.div`
  background-color: ${settings.colors.Primary.Grey_2};
  padding: 0 ${settings.Spacing.Spacing_100};
  display: flex;
  justify-content: space-between;
`;

const SHeadingPrimary = styled.span`
  font-family: ${settings.typography.FontFamily.Bold};
  font-size: ${settings.typography.FontSize.X_Small};
  color: ${settings.colors.Primary.Grey_8};
`;

const SHeadingSecondary = styled.span`
  font-family: ${settings.typography.FontFamily.Regular};
  font-size: ${settings.typography.FontSize.X_Small};
  color: ${settings.colors.Primary.Grey_6};
`;

GraphTooltip.whyDidYouRender = true;
export default React.memo(GraphTooltip);
