import {
  RHIMFleetOverviewServiceV1ControllersMeasurementSequenceDto,
  RHIMFleetOverviewServiceV1ControllersSequenceMeasurementReportDto,
} from '@rhim/rest/fleetOverview';
import { isDefined } from '@rhim/utils';
import { useMemo } from 'react';

export interface MeasurementTuple {
  tonnageKilograms: number;
  repairMixAmountKilograms: null | number;
  repairMixVolume: null | number;
  workingLiningMeasurement: RHIMFleetOverviewServiceV1ControllersSequenceMeasurementReportDto | null;
  afterRepairMeasurement: RHIMFleetOverviewServiceV1ControllersSequenceMeasurementReportDto | null;
}

export function useMeasurementSequenceTuples(measurementSequence: RHIMFleetOverviewServiceV1ControllersMeasurementSequenceDto) {
  const measurementTuples: MeasurementTuple[] = useMemo(() => {
    const ret: MeasurementTuple[] = [];

    const firstMeasurement = measurementSequence.wearLiningInitialMeasurement ?? measurementSequence.permanentLiningMeasurement;
    if (isDefined(firstMeasurement)) {
      ret.push({
        tonnageKilograms: firstMeasurement.tonnage,
        repairMixAmountKilograms: null,
        repairMixVolume: null,
        workingLiningMeasurement: firstMeasurement,
        afterRepairMeasurement: null,
      });
    }
    for (const measurementTuple of measurementSequence.measurementTuples) {
      ret.push({
        tonnageKilograms: measurementTuple.tonnage,
        repairMixAmountKilograms: measurementTuple.repairMixAmount,
        repairMixVolume: measurementTuple.repairMixVolume,
        workingLiningMeasurement: measurementTuple.workingLiningMeasurement,
        afterRepairMeasurement: measurementTuple.afterRepairMeasurement,
      });
    }
    return ret;
  }, [measurementSequence]);

  return measurementTuples;
}
