import { settings } from '@rhim/design';
import { Tooltip } from '@rhim/react';
import { RHIMFleetOverviewServiceV1ControllersSequenceMeasurementReportDto } from '@rhim/rest/fleetOverview';
import React from 'react';

import TooltipTitle from './TooltipTitle';

const TOOLTIP_STYLE = { padding: 0, border: `1px solid ${settings.colors.Primary.Grey_3}`, boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.1)' };

interface Props {
  measurement: RHIMFleetOverviewServiceV1ControllersSequenceMeasurementReportDto;
  children: React.ReactNode;
}
const CustomTooltip: React.FC<Props> = ({ measurement, children }) => {
  return (
    <Tooltip title={<TooltipTitle measurement={measurement} />} placement="right" arrow={false} overlayInnerStyle={TOOLTIP_STYLE}>
      <span>{children}</span>
    </Tooltip>
  );
};
CustomTooltip.whyDidYouRender = true;
export default React.memo(CustomTooltip);
