import { i18nReact } from '@rhim/i18n';
import { RHIMFleetOverviewServiceV1ControllersMeasurementSequenceDto } from '@rhim/rest/fleetOverview';
import { ScaleLinear } from 'd3-scale';
import React, { useMemo, useRef } from 'react';

import { useMeasurementMaxValue } from '../hooks/useMaxYValue';
import { useMeasurementSequenceTuples } from '../hooks/useMeasurementSequenceTuples';
import { useMeasurementsTonnageKilograms } from '../hooks/useMeasurementsTonnage';
import { useScaleY } from '../hooks/useScaleY';
import { useTooltip } from '../hooks/useTooltip';
import { DEFAULT_GRAPH_AREA_HEIGHT, DEFAULT_GRAPH_HEIGHT_TOTAL, VOLUME_LINE_COLOR } from './constants';
import Graph from './Graph';
import { getLines } from './utils';

interface Props {
  xScale: ScaleLinear<number, number>;
  measurementSequence: RHIMFleetOverviewServiceV1ControllersMeasurementSequenceDto;
}
const GraphVolume: React.ChildlessComponent<Props> = ({ xScale, measurementSequence }) => {
  const { t } = i18nReact.useTranslation(['blastFurnaceRunner-report']);
  const measurementTuples = useMeasurementSequenceTuples(measurementSequence);
  const volumePropertyName = 'volume';
  const maxYValue = useMeasurementMaxValue(measurementTuples, [volumePropertyName]);
  const yScale = useScaleY(DEFAULT_GRAPH_AREA_HEIGHT, 0, maxYValue);
  const measurementsAtKilograms = useMeasurementsTonnageKilograms(measurementTuples);
  const volumeLine = useMemo(() => getLines(measurementTuples, volumePropertyName, xScale, yScale, VOLUME_LINE_COLOR), [measurementTuples, xScale, yScale]);
  const svgGraphAreaRef = useRef<SVGRectElement>(null);
  const { tooltipIndicator, tooltipPanel } = useTooltip(svgGraphAreaRef.current, xScale, measurementSequence, `volume`);

  return (
    <Graph
      ref={svgGraphAreaRef}
      graphHeightTotal={DEFAULT_GRAPH_HEIGHT_TOTAL}
      xScale={xScale}
      yScale={yScale}
      yAxisLabel={`${t('blastFurnaceRunner-report:campaignReport.graphVolume.yAxisLabel')} [m³]`}
      graphLegends={[]}
      measurementsAtKilograms={measurementsAtKilograms}
      tooltipPanel={tooltipPanel}
    >
      {tooltipIndicator}
      {volumeLine}
    </Graph>
  );
};

GraphVolume.whyDidYouRender = true;
export default React.memo(GraphVolume);
