import { i18nReact } from '@rhim/i18n';
import { Spinner } from '@rhim/react';
import { RHIMFleetOverviewServiceV1ControllersMeasurementSequenceDto } from '@rhim/rest/fleetOverview';
import { RHIMMeasurementServiceV1ModelsMeasurementMetadataDto } from '@rhim/rest/measurementService';
import { isDefined } from '@rhim/utils';
import { useCallback, useMemo } from 'react';

import { useMeasurementFileMetadata } from '../../../hooks';
import { UNIT_M } from '../components/constants';

export function useLinesDepth(_measurementSequence: RHIMFleetOverviewServiceV1ControllersMeasurementSequenceDto) {
  const { t } = i18nReact.useTranslation(['blastFurnaceRunner-report']);

  const lastMeasurementOfCampaignId = useMemo(() => {
    /*
    let measurement: null | RHIMFleetOverviewServiceV1ControllersSequenceMeasurementReportDto;
    if (hasElements(measurementSequence.measurementTuples)) {
      const lastTuple = last(measurementSequence.measurementTuples);
      measurement = lastTuple.workingLiningMeasurement ?? lastTuple.afterRepairMeasurement;
    } else {
      measurement = measurementSequence.wearLiningInitialMeasurement ?? measurementSequence.permanentLiningMeasurement;
    }
    /**
     * TODO HMD Remove the hardcoded measurement-id when the backend returns real - instead of fake - measurement ids
     */
    return '7672ca2f-d630-4da3-8bb0-2534968d66a2';
    //return measurement.id;
    //}, [measurementSequence]);
  }, []);

  const { data: metadata } = useMeasurementFileMetadata(lastMeasurementOfCampaignId, { configuration: { suspense: false } });

  const getLineDepthDescription = useCallback(
    (metadata: RHIMMeasurementServiceV1ModelsMeasurementMetadataDto | undefined, line: 'volumeStart' | 'slagline' | 'ironline', comparator = '=') => {
      return isDefined(metadata) ? (
        <span>{`${t('blastFurnaceRunner-report:depth')}${comparator}${metadata[`${line}Depth`]}${UNIT_M}`}</span>
      ) : (
        <Spinner size="16" />
      );
    },
    [t]
  );

  const linesDepth = useMemo(() => {
    return {
      slagLineDepth: getLineDepthDescription(metadata, 'slagline'),
      ironLineDepth: getLineDepthDescription(metadata, 'ironline'),
      volumeStartDepth: getLineDepthDescription(metadata, 'volumeStart', '>='),
    };
  }, [getLineDepthDescription, metadata]);

  return linesDepth;
}
