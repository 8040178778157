import { AXIS_STYLES } from '@rhim/chart/v2/axis';
import { settings } from '@rhim/design';
import { Line } from '@vx/shape';
import { ScaleLinear } from 'd3-scale';
import React from 'react';

import { SHAPE_RENDERING_GEOMETRIC_PRECISION } from './constants';

interface Props {
  xScale: ScaleLinear<number, number>;
  graphAreaHeight: number;
  value: number;
  isSelected: boolean;
}
const SVGVerticalLine: React.ChildlessComponent<Props> = ({ xScale, graphAreaHeight, value, isSelected }) => {
  const x = xScale(value);

  return (
    <Line
      key={value}
      from={{ x, y: 0 }}
      to={{ x, y: graphAreaHeight + AXIS_STYLES.tickLength }}
      stroke={isSelected ? settings.colors.Primary.Grey_8 : settings.colors.Primary.Grey_3}
      strokeWidth={isSelected ? 2 : 1}
      pointerEvents="none"
      shapeRendering={SHAPE_RENDERING_GEOMETRIC_PRECISION}
    />
  );
};

SVGVerticalLine.whyDidYouRender = true;
export default React.memo(SVGVerticalLine);
