import { VesselType } from '@rhim/rest';
import { useColorScaleOfType } from '@rhim/sdk/wearManagement';
import { colorScalesForGOB, hasElements, isDefined } from '@rhim/utils';
import React, { useMemo } from 'react';

import AppContext from '../../../app/AppContext';

export function useColorScale() {
  const { selectedCustomer } = React.useContext(AppContext);
  const customerId = selectedCustomer.customerId as UUID;
  const { data: bfrColorScale } = useColorScaleOfType(customerId, VesselType.Bfr, 'RBL', { configuration: { suspense: false } });

  const selectedColorScales = useMemo(() => {
    if (!isDefined(bfrColorScale)) {
      return undefined;
    }
    return hasElements(bfrColorScale.thresholdsAndColors) ? bfrColorScale.thresholdsAndColors : colorScalesForGOB;
  }, [bfrColorScale]);

  return selectedColorScales;
}
