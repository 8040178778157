import { settings } from '@rhim/design';
import { isDefined } from '@rhim/utils';
import React, { ReactElement } from 'react';
import styled, { css } from 'styled-components';

import { FieldLabel } from '../../partials';

interface Props {
  dataTestId?: string;
  className?: string;
  label?: string | React.ReactNode;
  prefixIcon?: ReactElement;
  mode?: 'singleLineSmall40' | 'singleLineMedium48' | 'textarea';
  children: ReactElement;
}
const LockedFieldInput: React.FC<React.PropsWithChildren<Props>> = ({ dataTestId, className, label, prefixIcon, mode = 'singleLineMedium48', children }) => {
  return (
    <SWrapper className={className}>
      {isDefined(label) && <FieldLabel text={label} />}
      <SContainer data-test-id={dataTestId} mode={mode}>
        {isDefined(prefixIcon) && <SIconContainer>{prefixIcon}</SIconContainer>}
        {children}
      </SContainer>
    </SWrapper>
  );
};
LockedFieldInput.whyDidYouRender = true;
export default React.memo(LockedFieldInput);

const SWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const SContainer = styled.div<Pick<Props, 'mode'>>((props) => {
  const minHeight: React.CSSProperties['height'] = (() => {
    switch (props.mode) {
      case 'singleLineSmall40':
        return '40px';
      case 'singleLineMedium48':
        return '48px';
      case 'textarea':
        return '100px';
      default:
        return '48px';
    }
  })();

  return css`
    min-height: ${minHeight};
    max-height: ${minHeight};
    overflow: auto;
    display: flex;
    align-items: center;
    border-radius: 3px;
    color: ${settings.colors.Primary.Grey_8};
    background-color: ${settings.colors.Primary.Grey_2};
    padding: 0 ${settings.Spacing.Spacing_200};
    line-height: 28px;
    ${props.mode === 'textarea' &&
    css`
      align-items: flex-start;
      padding-top: 10px;
    `};
  `;
});

const SIconContainer = styled.span`
  display: flex;
  margin-right: ${settings.Spacing.Spacing_150};
`;
