import { RHIMFleetOverviewServiceV1ControllersMeasurementSequenceDto } from '@rhim/rest/fleetOverview';
import { assert, ensure, isDefined } from '@rhim/utils';
import { bisector } from 'd3';
import { ScaleLinear } from 'd3-scale';
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';

import GraphTooltip, { GraphType } from '../components/GraphTooltip';
import { MeasurementTuple, useMeasurementSequenceTuples } from './useMeasurementSequenceTuples';
import { useTooltipContent } from './useTooltipContent';
import { useTooltipIndicator } from './useTooltipIndicator';

const MOUSE_HOVER_RADIUS_PX = 20;
const TOOLTIP_PANEL_HORIZONTAL_MARGIN_PX = 12;

const tonnageAccessor = (d: MeasurementTuple) => {
  return d.tonnageKilograms;
};

const bisectorRight = bisector<MeasurementTuple, number>(tonnageAccessor).right;
const bisectorCenter = bisector<MeasurementTuple, number>(tonnageAccessor).center;

export function useTooltip(
  svgGraphArea: null | SVGRectElement,
  xScale: ScaleLinear<number, number>,
  measurementSequence: RHIMFleetOverviewServiceV1ControllersMeasurementSequenceDto,
  graphType: GraphType
) {
  const [mouseGraphPosition, setMouseGraphPosition] = useState<[number, number]>();
  const [tooltipPosition, setTooltipPosition] = useState<[number, number]>([0, 0]);
  const measurementTuples = useMeasurementSequenceTuples(measurementSequence);
  const tooltipContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!isDefined(svgGraphArea)) {
      return;
    }

    function handleMouseMove(event: MouseEvent) {
      setMouseGraphPosition([event.offsetX, event.offsetY]);
    }

    function handleMouseOut() {
      setMouseGraphPosition(undefined);
    }

    svgGraphArea.addEventListener('mousemove', handleMouseMove);
    svgGraphArea.addEventListener('mouseout', handleMouseOut);

    return () => {
      svgGraphArea.removeEventListener('mousemove', handleMouseMove);
      svgGraphArea.removeEventListener('mouseout', handleMouseOut);
    };
  }, [svgGraphArea]);

  const isCenterBisector =
    graphType === 'remainingThicknessSlagline' ||
    graphType === 'remainingThicknessIronline' ||
    graphType === 'thicknessDifferenceSlagline' ||
    graphType === 'thicknessDifferenceIronline' ||
    graphType === 'deltaVolumeVsRepairMixVolume' ||
    graphType === 'repairMixAmount' ||
    graphType === 'volume';

  const hoveredMeasurementTuple = useMemo(() => {
    if (!isDefined(mouseGraphPosition)) {
      return undefined;
    }

    const bisector = isCenterBisector ? bisectorCenter : bisectorRight;
    const mouseGraphX = mouseGraphPosition[0];
    const tonnageAtMouseGraphX = xScale.invert(mouseGraphX);
    let measurementTupleIndex = bisector(measurementTuples, tonnageAtMouseGraphX);
    measurementTupleIndex = Math.min(measurementTupleIndex, measurementTuples.length - 1);
    const hoveredMeasurementTupple = measurementTuples[measurementTupleIndex];
    assert(isDefined(hoveredMeasurementTupple), `Measurement tuple at index ${measurementTupleIndex} not found`);
    if (isCenterBisector) {
      const hoveredMeasurementTuppleTonnageGraphX = xScale(hoveredMeasurementTupple.tonnageKilograms);
      const mouseGraphXWithinDistance = Math.abs(hoveredMeasurementTuppleTonnageGraphX - mouseGraphX) <= MOUSE_HOVER_RADIUS_PX;
      return mouseGraphXWithinDistance ? hoveredMeasurementTupple : undefined;
    } else {
      return hoveredMeasurementTupple;
    }
  }, [xScale, measurementTuples, isCenterBisector, mouseGraphPosition]);

  const previousMeasurementTuple = useMemo(() => {
    if (!isDefined(hoveredMeasurementTuple)) {
      return undefined;
    }
    if (graphType === 'remainingThicknessSlagline' || graphType === 'remainingThicknessIronline' || graphType === 'volume') {
      return undefined;
    }
    const hoveredMeausurementIndex = measurementTuples.findIndex(
      (measurementTuple) => measurementTuple.tonnageKilograms === hoveredMeasurementTuple.tonnageKilograms
    );
    return hoveredMeausurementIndex > 0 ? ensure(measurementTuples[hoveredMeausurementIndex - 1]) : undefined;
  }, [measurementTuples, hoveredMeasurementTuple, graphType]);

  const { content, headingPrimary, headingSecondary } = useTooltipContent(previousMeasurementTuple, hoveredMeasurementTuple, graphType);
  const graphAreaHeight = useMemo(() => {
    if (!isDefined(svgGraphArea)) {
      return undefined;
    }
    return svgGraphArea.getBBox().height;
  }, [svgGraphArea]);
  const indicator = useTooltipIndicator(xScale, graphAreaHeight, graphType, previousMeasurementTuple, hoveredMeasurementTuple);

  const tooltipPreliminaryPosition = useMemo(() => {
    if (!isDefined(mouseGraphPosition) || !isDefined(hoveredMeasurementTuple)) {
      return null;
    }
    if (!isCenterBisector) {
      return mouseGraphPosition;
    }
    const hoveredTonnageGraphX = xScale(hoveredMeasurementTuple.tonnageKilograms);
    const tooltipPosition: [number, number] = [hoveredTonnageGraphX, mouseGraphPosition[1]];
    return tooltipPosition;
  }, [isCenterBisector, xScale, mouseGraphPosition, hoveredMeasurementTuple]);

  useLayoutEffect(() => {
    if (!isDefined(tooltipContainerRef.current) || !isDefined(tooltipPreliminaryPosition)) {
      return;
    }
    const graphWidth = xScale(ensure(xScale.domain()[1]));
    const tooltipContainerWidth = tooltipContainerRef.current.getBoundingClientRect().width;
    const tooltipPanelRightmostEdgeX = tooltipPreliminaryPosition[0] + TOOLTIP_PANEL_HORIZONTAL_MARGIN_PX + tooltipContainerWidth;
    const hasTooltipPanelWidthOvershotGraphWidth = tooltipPanelRightmostEdgeX >= graphWidth;
    const tooltipPanelStartX = hasTooltipPanelWidthOvershotGraphWidth
      ? tooltipPreliminaryPosition[0] - (TOOLTIP_PANEL_HORIZONTAL_MARGIN_PX + tooltipContainerWidth)
      : tooltipPreliminaryPosition[0] + TOOLTIP_PANEL_HORIZONTAL_MARGIN_PX;
    setTooltipPosition([tooltipPanelStartX, tooltipPreliminaryPosition[1]]);
  }, [xScale, tooltipPreliminaryPosition]);

  const tooltipPanel = isDefined(tooltipPreliminaryPosition) ? (
    <GraphTooltip ref={tooltipContainerRef} tooltipPosition={tooltipPosition} headingPrimary={headingPrimary} headingSecondary={headingSecondary}>
      {content}
    </GraphTooltip>
  ) : null;

  const tooltipIndicator = isDefined(tooltipPreliminaryPosition) ? indicator : null;

  return {
    tooltipPanel,
    tooltipIndicator,
  };
}
