import { RHIMFleetOverviewServiceV1ControllersSequenceMeasurementReportDto } from '@rhim/rest/fleetOverview';
import { isDefined } from '@rhim/utils';
import { useMemo } from 'react';

import { MeasurementTuple } from './useMeasurementSequenceTuples';

export type MeasurementPropertyName = keyof Pick<
  RHIMFleetOverviewServiceV1ControllersSequenceMeasurementReportDto,
  'side0SlaglineRbl' | 'side0IronlineRbl' | 'side1SlaglineRbl' | 'side1IronlineRbl' | 'volume'
>;

export function useMeasurementMaxValue(measurementTuples: MeasurementTuple[], propertyNames: MeasurementPropertyName[]) {
  const maxYValue = useMemo(() => {
    let maxValue = Number.NEGATIVE_INFINITY;
    for (const measurementTuple of measurementTuples) {
      for (const propertyName of propertyNames) {
        if (isDefined(measurementTuple.workingLiningMeasurement)) {
          const value = measurementTuple.workingLiningMeasurement[propertyName];
          if (isDefined(value)) {
            maxValue = Math.max(maxValue, value);
          }
        }
        if (isDefined(measurementTuple.afterRepairMeasurement)) {
          const value = measurementTuple.afterRepairMeasurement[propertyName];
          if (isDefined(value)) {
            maxValue = Math.max(maxValue, value);
          }
        }
      }
    }
    return maxValue;
  }, [measurementTuples, propertyNames]);

  return maxYValue;
}

export function useMeasurementTupleMaxValue(measurementTuples: MeasurementTuple[], propertyName: 'repairMixAmountKilograms' | 'repairMixVolume') {
  const maxYValue = useMemo(() => {
    let maxValue = Number.NEGATIVE_INFINITY;
    for (const measurementTuple of measurementTuples) {
      const value = measurementTuple[propertyName];
      if (isDefined(value)) {
        maxValue = Math.max(maxValue, value);
      }
    }
    return maxValue;
  }, [measurementTuples, propertyName]);

  return maxYValue;
}
