import { i18nReact } from '@rhim/i18n';
import {
  RHIMFleetOverviewServiceV1ControllersMeasurementSequenceDto,
  RHIMFleetOverviewServiceV1ControllersSequenceMeasurementReportDto,
} from '@rhim/rest/fleetOverview';
import { isDefined } from '@rhim/utils';
import { scaleLinear } from '@visx/scale';
import { max } from 'd3-array';
import React, { useMemo } from 'react';
import { useMeasure } from 'react-use';

import GraphDeltaRemainingBrickLength from './components/GraphDeltaRemainingBrickLength';
import GraphRemainingThickness from './components/GraphRemainingThickness';
import GraphRepairMixAmount from './components/GraphRepairMixAmount';
import GraphVolume from './components/GraphVolume';
import GraphVolumeRepairDeltaVsMixVolume from './components/GraphVolumeRepairDeltaVsMixVolume';
import GraphWearRate from './components/GraphWearRate';
import Heading, { FontSize } from './components/Heading';
import ReportHeader from './components/ReportHeader';
import { useCampaignInfoSummary } from './hooks/useCampaignInfoSummary';
import { useLinesDepth } from './hooks/useLinesDepth';
import { VesselLine } from './types';

interface Props {
  measurementSequence: RHIMFleetOverviewServiceV1ControllersMeasurementSequenceDto;
}
const CampaignReportPanel: React.ChildlessComponent<Props> = ({ measurementSequence }) => {
  const { t } = i18nReact.useTranslation(['blastFurnaceRunner-report']);
  const [containerRef, { width: containerWidth }] = useMeasure<HTMLDivElement>();
  const linesDepth = useLinesDepth(measurementSequence);

  const allMeasurements = useMemo(() => {
    const ret: RHIMFleetOverviewServiceV1ControllersSequenceMeasurementReportDto[] = [];
    if (isDefined(measurementSequence.permanentLiningMeasurement)) {
      ret.push(measurementSequence.permanentLiningMeasurement);
    }
    if (isDefined(measurementSequence.wearLiningInitialMeasurement)) {
      ret.push(measurementSequence.wearLiningInitialMeasurement);
    }
    for (const measurement of measurementSequence.measurementTuples) {
      if (isDefined(measurement.workingLiningMeasurement)) {
        ret.push(measurement.workingLiningMeasurement);
      }
      if (isDefined(measurement.afterRepairMeasurement)) {
        ret.push(measurement.afterRepairMeasurement);
      }
    }
    return ret;
  }, [measurementSequence]);

  const tonnageMaximumValueTons = useMemo(() => {
    let maxTonnageTons = max(allMeasurements, (measurement) => measurement.tonnage);
    maxTonnageTons = maxTonnageTons ?? 0;
    return maxTonnageTons;
  }, [allMeasurements]);

  const xScale = useMemo(() => {
    return scaleLinear({ domain: [0, tonnageMaximumValueTons], range: [0, containerWidth] });
  }, [containerWidth, tonnageMaximumValueTons]);

  const campaignInfoSummary = useCampaignInfoSummary(allMeasurements);

  return (
    <div ref={containerRef}>
      <ReportHeader title={t('blastFurnaceRunner-report:tabCampaignReport')} metadata={campaignInfoSummary} />
      <Heading
        fontSize={FontSize.Size20}
        label={`${t('blastFurnaceRunner-report:campaignReport.graphWearRateTitle')} - ${t('blastFurnaceRunner-report:slagLine')}`}
        subLabel={linesDepth.slagLineDepth}
      />
      <GraphWearRate xScale={xScale} measurementSequence={measurementSequence} line={VesselLine.Slagline} average={3.5} />
      <Heading
        fontSize={FontSize.Size20}
        label={`${t('blastFurnaceRunner-report:campaignReport.graphWearRateTitle')} - ${t('blastFurnaceRunner-report:ironLine')}`}
        subLabel={linesDepth.ironLineDepth}
      />
      <GraphWearRate xScale={xScale} measurementSequence={measurementSequence} line={VesselLine.Ironline} average={3.5} />
      <Heading
        fontSize={FontSize.Size20}
        label={`${t('blastFurnaceRunner-report:campaignReport.graphRemainingThicknessTitle')} - ${t('blastFurnaceRunner-report:slagLine')}`}
        subLabel={linesDepth.slagLineDepth}
      />
      <GraphRemainingThickness xScale={xScale} measurementSequence={measurementSequence} line={VesselLine.Slagline} critical={150} />
      <Heading
        fontSize={FontSize.Size20}
        label={`${t('blastFurnaceRunner-report:campaignReport.graphRemainingThicknessTitle')} - ${t('blastFurnaceRunner-report:ironLine')}`}
        subLabel={linesDepth.ironLineDepth}
      />
      <GraphRemainingThickness xScale={xScale} measurementSequence={measurementSequence} line={VesselLine.Ironline} critical={180} />
      <Heading
        fontSize={FontSize.Size20}
        label={`${t('blastFurnaceRunner-report:campaignReport.graphRepairThicknessDeltaTitle')} - ${t('blastFurnaceRunner-report:slagLine')}`}
        subLabel={linesDepth.slagLineDepth}
      />
      <GraphDeltaRemainingBrickLength xScale={xScale} measurementSequence={measurementSequence} line={VesselLine.Slagline} />
      <Heading
        fontSize={FontSize.Size20}
        label={`${t('blastFurnaceRunner-report:campaignReport.graphRepairThicknessDeltaTitle')} - ${t('blastFurnaceRunner-report:ironLine')}`}
        subLabel={linesDepth.ironLineDepth}
      />
      <GraphDeltaRemainingBrickLength xScale={xScale} measurementSequence={measurementSequence} line={VesselLine.Ironline} />
      <GraphRepairMixAmount xScale={xScale} measurementSequence={measurementSequence} />
      <Heading
        fontSize={FontSize.Size20}
        label={t('blastFurnaceRunner-report:campaignReport.graphVolumeRepairDeltaVsMixVolumeTitle')}
        subLabel={linesDepth.volumeStartDepth}
      />
      <GraphVolumeRepairDeltaVsMixVolume xScale={xScale} measurementSequence={measurementSequence} />
      <Heading fontSize={FontSize.Size20} label={t('blastFurnaceRunner-report:campaignReport.graphVolumeTitle')} subLabel={linesDepth.volumeStartDepth} />
      <GraphVolume xScale={xScale} measurementSequence={measurementSequence} />
    </div>
  );
};

CampaignReportPanel.whyDidYouRender = true;
export default React.memo(CampaignReportPanel);
