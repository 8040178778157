import { AXIS_STYLES } from '@rhim/chart/v2/axis';
import { settings } from '@rhim/design';
import { isDefined } from '@rhim/utils';
import { ScaleLinear } from 'd3-scale';
import React, { useContext } from 'react';
import styled from 'styled-components';

import BlastFurnaceRunnerReportContext from '../BlastFurnaceRunnerReportContext';
import { apiTonnageForDisplay } from '../utils';

interface Props {
  xScale: ScaleLinear<any, any>;
  graphBottomPadding: number;
}
const SelectedXTick: React.FC<Props> = ({ xScale, graphBottomPadding }) => {
  const { selectedMeasurement } = useContext(BlastFurnaceRunnerReportContext);

  if (!isDefined(selectedMeasurement)) {
    return null;
  }

  const tickX = xScale(selectedMeasurement.tonnage);

  return (
    <SSelectedTick graphBottomPadding={graphBottomPadding} x={tickX}>
      {apiTonnageForDisplay(selectedMeasurement.tonnage, 'kiloTons')}
    </SSelectedTick>
  );
};

const SSelectedTick = styled.span<{ graphBottomPadding: number; x: number }>`
  padding: 0 4px;
  line-height: 16px;
  border-radius: 3px;
  font-family: ${settings.typography.FontFamily.Bold};
  font-size: ${settings.typography.FontSize.X_Small};
  color: ${settings.colors.Primary.Grey_1};
  background-color: ${settings.colors.Primary.Grey_8};
  position: absolute;
  left: ${(props) => props.x}px;
  bottom: ${(props) => props.graphBottomPadding - AXIS_STYLES.tickLength}px;
  transform: translate(-50%, 100%);
`;

SelectedXTick.whyDidYouRender = true;
export default React.memo(SelectedXTick);
