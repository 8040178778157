import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const OwlIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        d="M9.5727 12.8825C9.5727 14.1588 8.53807 15.1934 7.26179 15.1934C5.98551 15.1934 4.95088 14.1588 4.95088 12.8825C4.95088 11.6062 5.98551 10.5716 7.26179 10.5716C7.50048 10.5716 7.73071 10.6078 7.9473 10.675C7.90491 10.6708 7.86193 10.6687 7.81845 10.6687C7.09435 10.6687 6.50735 11.2557 6.50735 11.9798C6.50735 12.7039 7.09435 13.2909 7.81845 13.2909C8.54255 13.2909 9.12955 12.7039 9.12955 11.9798C9.12955 11.7232 9.05582 11.4838 8.92841 11.2817C9.32743 11.697 9.5727 12.2611 9.5727 12.8825Z"
        fill="#B1BBC2"
      />
      <path
        d="M16.6778 15.1934C17.954 15.1934 18.9887 14.1588 18.9887 12.8825C18.9887 12.3134 18.7829 11.7923 18.4417 11.3896C18.5313 11.567 18.5818 11.7675 18.5818 11.9798C18.5818 12.7039 17.9948 13.2909 17.2707 13.2909C16.5466 13.2909 15.9596 12.7039 15.9596 11.9798C15.9596 11.2557 16.5466 10.6687 17.2707 10.6687C17.2976 10.6687 17.3243 10.6695 17.3507 10.6711C17.1378 10.6064 16.9118 10.5716 16.6778 10.5716C15.4015 10.5716 14.3669 11.6062 14.3669 12.8825C14.3669 14.1588 15.4015 15.1934 16.6778 15.1934Z"
        fill="#B1BBC2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.44624 7.07419H9.85538C10.7935 7.07419 11.9821 9.27118 11.9821 9.27118C11.9821 9.27118 13.3138 7.07419 14.246 7.07419C16.294 7.07421 19.4353 7.0742 20.9376 7.07419L21.7237 7.07419C20.7801 8.78477 19.7016 8.78477 19.7016 8.78477H19.5976L21.9435 12.8479C22.0189 12.9785 22.0189 13.1394 21.9435 13.27L19.5085 17.4875C19.4331 17.6181 19.2937 17.6985 19.1429 17.6985H14.273C14.1222 17.6985 13.9829 17.6181 13.9075 17.4875L12.0034 14.1894L10.0925 17.4991C10.0171 17.6297 9.87777 17.7101 9.72697 17.7101H4.85706C4.70626 17.7101 4.56691 17.6297 4.49151 17.4991L2.05655 13.2816C1.98115 13.151 1.98115 12.9901 2.05655 12.8595L4.40911 8.78477H4.29853C4.29853 8.78477 3.3899 8.78477 2.44624 7.07419ZM9.41463 9.6958C9.33922 9.56521 9.19988 9.48476 9.04908 9.48476H5.47955C5.32875 9.48476 5.1894 9.56521 5.114 9.6958L3.32923 12.7871C3.25383 12.9177 3.25383 13.0786 3.32923 13.2092L5.114 16.3005C5.1894 16.4311 5.32875 16.5116 5.47955 16.5116H9.04908C9.19988 16.5116 9.33923 16.4311 9.41463 16.3005L11.1994 13.2092C11.2748 13.0786 11.2748 12.9177 11.1994 12.7871L9.41463 9.6958ZM18.4818 9.49227C18.6326 9.49227 18.7719 9.57272 18.8473 9.70331L20.632 12.7944C20.7074 12.925 20.7074 13.0859 20.632 13.2165L18.8473 16.3077C18.7719 16.4383 18.6326 16.5187 18.4818 16.5187H14.9125C14.7617 16.5187 14.6223 16.4383 14.5469 16.3077L12.7623 13.2165C12.6869 13.0859 12.6869 12.925 12.7623 12.7944L14.5469 9.70331C14.6223 9.57272 14.7617 9.49227 14.9125 9.49227H18.4818Z"
        fill="#B1BBC2"
      />
      <path d="M10.5856 17.6768L12.0314 15.3206L13.4772 17.6768L12.0316 19.9258L10.5856 17.6768Z" fill="#B1BBC2" />
      <path d="M13.4775 17.6768L12.0316 19.9258L10.5858 17.6768L12.0314 15.3206L13.4775 17.6768Z" fill="#B1BBC2" />
    </svg>
  );
};

OwlIcon.defaultProps = {
  size: '24',
  fill: settings.colors.Primary.Blue_9,
};
