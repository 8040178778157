import { settings } from '@rhim/design';
import { sectionsHeaderMeasurementView, sectionsHeatNumberLabelMeasurementView } from '@rhim/test-ids';
import { isDefined } from '@rhim/utils';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const HeatLine = () => (
  <svg width="60" height="6">
    <path fill="none" stroke="#003262" strokeWidth="3" strokeLinejoin="round" strokeLinecap="round" d="M7,2H32" />
  </svg>
);

const ReferencedHeatLine = () => (
  <svg width="60" height="6">
    <path fill="#d8dde1" stroke="#778894" strokeWidth="2" strokeDasharray="2,4" strokeLinejoin="round" strokeLinecap="round" d="M7,2H30" />
  </svg>
);

interface Props {
  heatNumber?: number;
  referenceHeat?: number;
}

const LegendComponent: FC<React.PropsWithChildren<Props>> = ({ heatNumber, referenceHeat }) => {
  const { t } = useTranslation(['visualization']);

  const referenceHeatComponent = isDefined(referenceHeat) ? (
    <>
      {t('visualization:sections.referenceHeat', { heatNumber: referenceHeat })} <ReferencedHeatLine />
    </>
  ) : null;

  const heatComponent = isDefined(heatNumber) ? (
    <>
      {t('visualization:sections.heatNumber', { heatNumber: heatNumber })} <HeatLine />
    </>
  ) : null;

  return (
    <SWrapper>
      <SHeader data-test-id={sectionsHeaderMeasurementView}>{t('visualization:legend.thicknessDifference')}</SHeader>
      <SLegend data-test-id={sectionsHeatNumberLabelMeasurementView}>
        {heatComponent}
        {referenceHeatComponent}
      </SLegend>
    </SWrapper>
  );
};

export const Legend = React.memo(LegendComponent);

const SWrapper = styled.div`
  margin-left: 90px;
  font-family: ${settings.typography.FontFamily.Regular};
  color: ${settings.colors.Primary.Grey_8};
`;

const SHeader = styled.p`
  font-size: ${settings.typography.FontSize.Medium};
  line-height: ${settings.typography.LineHeight.Line_Height_20};
`;
const SLegend = styled.p`
  font-size: ${settings.typography.FontSize.X_Small};
`;
