import { i18nReact } from '@rhim/i18n';
import React from 'react';
import styled from 'styled-components';

import { UNIT_CUBIC_METERS, UNIT_MM } from '../components/constants';
import Heading from './Heading';
import { COLUMN_HORIZONTAL_PADDING_PX, ROW_HEIGHT_PX, SCellVolume, SHorizontalDividerBold, SHorizontalDividerStandard } from './styles';
import { ColumnRblAndVolume } from './types';
import ValueWithUnit from './ValueWithUnit';

const ColumnInitial: React.ChildlessComponent<ColumnRblAndVolume> = ({
  slagLineSide0Rbl,
  slagLineSide1Rbl,
  ironLineSide0Rbl,
  ironLineSide1Rbl,
  volume,
  showSlagLineRow,
  showIronLineRow,
  showVolumeRow,
}) => {
  const { t } = i18nReact.useTranslation(['blastFurnaceRunner-report']);

  return (
    <SWrapper>
      <SCell>
        <Heading
          heading={t('blastFurnaceRunner-report:measurementReport.measurementReportPanel.headingInitial')}
          isHeadingLarge={false}
          subHeading={t('blastFurnaceRunner-report:measurementReport.measurementReportPanel.subHeadingMinimumRBL')}
        />
      </SCell>
      <SHorizontalDividerStandard />
      {showSlagLineRow && (
        <>
          <SCell>
            <ValueWithUnit value={slagLineSide0Rbl} unit={UNIT_MM} />
          </SCell>
          <SHorizontalDividerStandard />
          <SCell>
            <ValueWithUnit value={slagLineSide1Rbl} unit={UNIT_MM} />
          </SCell>
        </>
      )}
      {showIronLineRow && (
        <>
          {showSlagLineRow && <SHorizontalDividerBold />}
          <SCell>
            <ValueWithUnit value={ironLineSide0Rbl} unit={UNIT_MM} />
          </SCell>
          <SHorizontalDividerStandard />
          <SCell>
            <ValueWithUnit value={ironLineSide1Rbl} unit={UNIT_MM} />
          </SCell>
        </>
      )}
      {showVolumeRow && (
        <>
          {(showSlagLineRow || showIronLineRow) && <SHorizontalDividerStandard />}
          <SCellVolume>
            <ValueWithUnit label={t('blastFurnaceRunner-report:volume')} value={volume} unit={UNIT_CUBIC_METERS} />
          </SCellVolume>
        </>
      )}
    </SWrapper>
  );
};

const SWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const SCell = styled.div`
  display: flex;
  align-items: center;
  height: ${ROW_HEIGHT_PX}px;
  padding: 0 ${COLUMN_HORIZONTAL_PADDING_PX}px;
`;

ColumnInitial.whyDidYouRender = true;
export default React.memo(ColumnInitial);
