/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  AbstractMesh,
  Angle,
  ArcRotateCamera,
  BoundingBox,
  Color3,
  CreateBox,
  CreateCylinder,
  DynamicTexture,
  Mesh,
  Ray,
  RayHelper,
  Scene,
  StandardMaterial,
  Tags,
  Tools,
  TransformNode,
  Vector2,
  Vector3,
  VertexBuffer,
} from '@babylonjs/core';
import { useTimeAgoTextUpdatedEveryMinute, useUserSettings } from '@rhim/react';
import {
  RHIMAPOWearManagementApiClientRegionDto,
  RHIMAPOWearManagementApiClientVesselFunctionalProductDto,
  RHIMContractsRegionLocation,
  RHIMMeasurementServiceV1ModelsMeasurementMetadataDto,
} from '@rhim/rest/measurementService';
import { RHIMAPOCommonDataReportingColorScaleType } from '@rhim/rest/wearManagement';
import { isSteelVesselArea } from '@rhim/sdk/wearManagement';
import { assert, centimetersToMeters, ensure, hasElements, isDefined, parseUTC, sortGroup } from '@rhim/utils';
import {
  createTextureRectangular,
  createTextureWithRegions,
  getDefaultCameraState,
  getMeshSize,
  isFunctionalProductOnTheSideOfVessel,
  PickedPoint,
  Region,
  Scene3dApiFacade,
} from '@rhim/visualization3d';
import { isAfter, isBefore, isSameDay } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';

const isDebug = () => false;
const isPlugsAndTrunnionsPlacementDebug = () => false;

/**
 * All static color scale types which do not require backend data should be
 * listed in this ENUM
 */
export enum ColorScaleStaticTypes {
  Original = 'Original',
}

export type ColorScaleType = RHIMAPOCommonDataReportingColorScaleType | ColorScaleStaticTypes;

export const ASSUMED_VESSEL_HEIGHT_IN_METERS = 10;

export const MEASUREMENT_VIEW_3D_SCENE_TAGS = {
  PLUGS: 'tag-plugs',
  TRUNNIONS: 'tag-trunnions',
};

const convertRegionAngles = (region: Pick<Region, 'angleEnd' | 'angleStart'>): { angleStart: number; angleEnd: number } => ({
  angleStart: 360 - region.angleEnd,
  angleEnd: 360 - region.angleStart,
});

/**
 * Converts the start/end angles of regions as defined in APO WMS to match the expected placement of the regions on the 3d model :
 * e.g if in APO WMS a region is defined as having startAngle : 0 and endAngle : 10,
 * we convert them to : startAngle: 350 ( == 360 - 10) and endAngle : 0 (== 360 - 0).
 * Or another example, APO WMS region defined as having startAngle : 350 and endAngle : 360,
 * will become : startAngle : 0 ( == 360 - 360) and endAngle : 10 ( == 360 - 350)
 */
export const convertAnglesForRegions = (regions: Region[]) => regions.map((region: Region) => ({ ...region, ...convertRegionAngles(region) }));

export function displayRectRegions(
  scene3d: Scene3dApiFacade,
  regionsData: RHIMAPOWearManagementApiClientRegionDto[],
  metadata: RHIMMeasurementServiceV1ModelsMeasurementMetadataDto,
  boundingBox?: BoundingBox
): DynamicTexture {
  const texture = createTextureRectangular(scene3d.getScene(), regionsData, metadata, boundingBox);
  scene3d.setDiffuseTexture(texture);

  return texture;
}

export function displayRegions(scene3d: Scene3dApiFacade, regionsData: RHIMAPOWearManagementApiClientRegionDto[], ladleHeightInMeters: number): DynamicTexture {
  const regions: Region[] = new Array(regionsData.length);

  regionsData.forEach((region, i) => {
    const { area, name } = region;

    assert(isDefined(area), 'Area definition is missing');
    assert(isDefined(area[0]), 'Area definition is missing');
    assert(isDefined(area[1]), 'Area definition is missing');
    assert(isDefined(area[3]), 'Area definition is missing');
    assert(isDefined(name), 'Region name is missing');

    assert(isDefined(area[0].z));
    assert(isDefined(area[0].theta));
    assert(isDefined(area[1].theta));
    assert(isDefined(area[3].z));

    // the regions map is from inside part of the ladle, means we need to mirror angles to show correct regions on outside part
    const { angleStart, angleEnd } = convertRegionAngles({
      angleStart: area[0].theta,
      angleEnd: area[1].theta,
    });

    /**
     * Relative heights in %.
     */
    const yBottom = (area[0].z / ladleHeightInMeters) * 100;
    const yTop = (area[3].z / ladleHeightInMeters) * 100;

    regions[i] = {
      name: name,
      angleStart: angleStart,
      angleEnd: angleEnd,
      yTop: yTop,
      yBottom: yBottom,
      color: 'black',
    };
  });

  const texture = createTextureWithRegions(scene3d.getScene(), regions);
  scene3d.setDiffuseTexture(texture);

  return texture;
}

const MESH_ANGLE_OFFSET = -Math.PI / 2;

function getPlugCenterY(targetMesh: Mesh, distanceFromTopOfMesh: number) {
  const { max } = targetMesh.getHierarchyBoundingVectors(false);
  return max.y - distanceFromTopOfMesh;
}

function getPlugPosition(isSidePlug: boolean, plugThetaAngle: number, plugDistance: number, targetMesh: Mesh, radius: number): Vector3 {
  const isTargetPickable = targetMesh.isPickable;
  // make the target pickable so ray can hit the triangles
  targetMesh.isPickable = true;
  const { y: meshHeight } = getMeshSize(targetMesh);
  const rayLength = 2 * meshHeight;
  const meshAngleOffset = MESH_ANGLE_OFFSET;
  const scene = targetMesh.getScene();

  let rayDirection = Vector3.Zero();
  let plugPosition = Vector3.Zero();
  // casting 4 rays from positions of boung box to find the farest hit point
  const rayOrigins: [Vector3, Vector3, Vector3, Vector3] = [Vector3.Zero(), Vector3.Zero(), Vector3.Zero(), Vector3.Zero()];
  const plateRotationAngle = Tools.ToRadians(plugThetaAngle);
  const rayRotationAngle = plateRotationAngle + meshAngleOffset;

  if (isSidePlug) {
    // the plug should be positioned somewhere on the side walls of the vessel
    // "plugDistance" means : distance from the top of the mesh to the center of the plug's cylinder
    const plugCenterY = getPlugCenterY(targetMesh, plugDistance);

    rayOrigins[0].addInPlace(new Vector3(radius * Math.cos(plateRotationAngle), plugCenterY + radius, radius * Math.sin(plateRotationAngle)));
    rayOrigins[1].addInPlace(new Vector3(radius * Math.cos(plateRotationAngle), plugCenterY - radius, radius * Math.sin(plateRotationAngle)));
    rayOrigins[2].addInPlace(
      new Vector3(radius * Math.cos(plateRotationAngle + Math.PI), plugCenterY - radius, radius * Math.sin(plateRotationAngle + Math.PI))
    );
    rayOrigins[3].addInPlace(
      new Vector3(radius * Math.cos(plateRotationAngle + Math.PI), plugCenterY + radius, radius * Math.sin(plateRotationAngle + Math.PI))
    );

    if (isPlugsAndTrunnionsPlacementDebug()) {
      rayOrigins.forEach((position) => createTestBox(position, scene, Color3.Blue()));
    }

    rayDirection = new Vector3(Math.cos(rayRotationAngle), 0, Math.sin(rayRotationAngle));
    // position on a unit cylinder, hold on, we'll reposition it later on a surface
    plugPosition = new Vector3(Math.cos(rayRotationAngle), plugCenterY, Math.sin(rayRotationAngle));
  } else {
    // the plug should be positioned somewhere on the floor of the vessel
    // "plugDistance" means : distance from the center of mesh to the center of the plug's cylinder in the y-plane
    const rayOriginX = plugDistance * Math.cos(rayRotationAngle);
    const rayOriginZ = plugDistance * Math.sin(rayRotationAngle);

    rayOrigins[0].addInPlace(new Vector3(rayOriginX + radius, 0, rayOriginZ + radius));
    rayOrigins[1].addInPlace(new Vector3(rayOriginX + radius, 0, rayOriginZ - radius));
    rayOrigins[2].addInPlace(new Vector3(rayOriginX - radius, 0, rayOriginZ - radius));
    rayOrigins[3].addInPlace(new Vector3(rayOriginX - radius, 0, rayOriginZ + radius));

    if (isPlugsAndTrunnionsPlacementDebug()) {
      // display a blue cube at the origin of each ray
      rayOrigins.forEach((position) => createTestBox(position, scene, Color3.Blue()));
    }

    // rayOrigin = new Vector3(rayOriginX, -meshHeight, rayOriginZ);
    rayDirection = Vector3.Down();
    // positioning the plug in XZ space, reposition it vertically after calculating a hit point
    plugPosition = new Vector3(rayOriginX, 0, rayOriginZ);
  }

  const rays = rayOrigins.map((origin) => new Ray(origin, rayDirection, rayLength));
  if (isPlugsAndTrunnionsPlacementDebug()) {
    rays.forEach((cRay) => RayHelper.CreateAndShow(cRay, scene, Color3.Red()));
  }

  const intersections = rays.map((cRay) => targetMesh.intersects(cRay));

  if (isPlugsAndTrunnionsPlacementDebug()) {
    // display a green cube at the intersection of each ray with the mesh
    intersections.forEach((intersection) => {
      if (isDefined(intersection.pickedPoint)) {
        createTestBox(intersection.pickedPoint, scene, Color3.Green());
      }
    });
  }

  // return pickable value to the initial state
  targetMesh.isPickable = isTargetPickable;

  // find the intersection point (if any) that is closer to the center of the mesh
  let distance = Number.POSITIVE_INFINITY;
  let hitPoint: Vector3 | undefined;
  intersections.forEach((intersection) => {
    // exclude rays which did not intersect with the mesh at all (e.g due to holes in the mesh)
    if (isDefined(intersection.pickedPoint)) {
      if (intersection.distance < distance) {
        distance = intersection.distance;
        hitPoint = intersection.pickedPoint;
      }
    }
  });

  if (isSidePlug) {
    if (distance === Number.POSITIVE_INFINITY) {
      // none of the 4 casted rays intersected the mesh (probably due to holes in the mesh).
      // Guesstimate a distance by taking the average distance of the other vertices around the mesh at that y
      const verticesSortedByY = getVerticesDistancesFromCenterAtYPlane(targetMesh);
      const plugCenterY = getPlugCenterY(targetMesh, plugDistance);
      for (const [index, vertexInfo] of verticesSortedByY.entries()) {
        if (vertexInfo.y >= plugCenterY) {
          // Collect all vertices of same y and get their average distance
          distance = getAverageDistanceAtHeight(verticesSortedByY, index);
          break;
        }
      }
    }
    // we have already projected position on X and Y axis, now just move it on the side by multiplying on distance
    plugPosition.x *= distance;
    plugPosition.z *= distance;
  } else {
    // purge-plug at the bottom of the mesh
    if (distance === Number.POSITIVE_INFINITY) {
      // none of the 4 casted rays intersected the mesh
      // (probably because the "Distance from center of Vessel" set in cockpit had a value larger than the mesh's radius)
      // in this case just position the purge-plug at the center of the mesh
      plugPosition = Vector3.Zero();
    } else {
      assert(isDefined(hitPoint), 'Hitpoint not set');
      plugPosition.y = hitPoint.y;
    }
  }

  return plugPosition;
}

const getMeshMaterial = (scene: Scene, color: Color3): StandardMaterial => {
  const mat = new StandardMaterial('plugMaterial', scene);
  mat.diffuseColor = color;
  mat.backFaceCulling = false;
  if (isDebug()) {
    // in debug mode make the plug's material semi-transparent to allow the various helping nodes to show through
    mat.alpha = 0.7;
  }
  return mat;
};

function createTestBox(position: Vector3, scene: Scene, fillColor?: Color3): void {
  const cube = CreateBox('test', { size: 0.05 }, scene);
  cube.isPickable = false;
  cube.material = new StandardMaterial('test', scene);
  (cube.material as StandardMaterial).diffuseColor = fillColor ?? Color3.Red();
  cube.position = position;
}

/**
 * Create a plug system and position it according to the plugOptions
 *
 * @param targetMesh
 * @param plugOptions
 */
export function addPlug(targetMesh: Mesh, plugOptions: APO.MeasurementView.PlugV2): void {
  const scene = targetMesh.getScene();
  if (isDebug()) {
    // slow down the mouse wheel zoom in debug mode to make it easier to zoom into details on the mesh
    (scene.activeCamera as ArcRotateCamera).wheelPrecision = 1500;
  }

  // calculate the point where the plug should be positioned
  const plugPosition = getPlugPosition(plugOptions.isSidePlug, plugOptions.theta, plugOptions.distance, targetMesh, plugOptions.circleRadius);
  // if the plug is rotated, we need to adjust length to hit the surface
  const adjustedLength = Math.min(plugOptions.primaryCylinderHeight, plugOptions.circleRadius * Math.tan(Tools.ToRadians(plugOptions.tilt)));
  const pipeSystem = createPlugMeshSystem(plugOptions, scene, adjustedLength);
  Tags.AddTagsTo(pipeSystem, MEASUREMENT_VIEW_3D_SCENE_TAGS.PLUGS);

  pipeSystem.rotation.x = Tools.ToRadians(plugOptions.tilt);
  pipeSystem.rotation.y = Tools.ToRadians(-plugOptions.theta);
  pipeSystem.position = plugPosition;
  pipeSystem.parent = targetMesh;
}

/**
 * Create the system of plugs and wrap it into a TransformNode to position everything together
 *
 * @param plugOptions
 * @param scene
 * @returns
 */
function createPlugMeshSystem(plugOptions: APO.MeasurementView.PlugV2, scene: Scene, adjustedLength: number): TransformNode {
  const { isSidePlug, primaryCylinderHeight, circleRadius, secondaryCylinderHeight, secondaryCylinderRadius } = plugOptions;
  const defaultRotation = isSidePlug ? -Math.PI / 2 : Math.PI;
  const plugSystem = new TransformNode('plugSystem', scene);
  const primaryCylinder = CreateCylinder(
    'primaryCylinder',
    {
      height: primaryCylinderHeight + adjustedLength,
      diameter: 2 * circleRadius,
    },
    scene
  );
  primaryCylinder.setParent(plugSystem);
  primaryCylinder.position.y = (primaryCylinderHeight - adjustedLength) / 2;
  primaryCylinder.rotation.x = defaultRotation;
  primaryCylinder.setPivotPoint(primaryCylinder.getAbsolutePivotPoint().subtract(primaryCylinder.position));

  const secondaryCylinder = CreateCylinder(
    'secondaryCylinder',
    {
      height: secondaryCylinderHeight,
      diameter: 2 * secondaryCylinderRadius,
    },
    scene
  );
  secondaryCylinder.setParent(plugSystem);
  secondaryCylinder.position.y = primaryCylinderHeight + secondaryCylinderHeight / 2;
  secondaryCylinder.rotation.x = defaultRotation;
  secondaryCylinder.setPivotPoint(secondaryCylinder.getAbsolutePivotPoint().subtract(secondaryCylinder.position));

  const plugMaterial = getMeshMaterial(scene, plugOptions.color);
  primaryCylinder.material = plugMaterial;
  secondaryCylinder.material = plugMaterial;

  return plugSystem;
}

export function addTapholesAndPlugs(mesh: Mesh, tapHolesAndPlugs: RHIMAPOWearManagementApiClientVesselFunctionalProductDto[]) {
  tapHolesAndPlugs.forEach((plug) => {
    log.debug('Adding a plug to the mesh:', plug);

    assert(isDefined(plug.area), `Expected area to be defined on the plug, got ${plug.area} instead`);
    assert(isSteelVesselArea(plug.area));
    assert(isDefined(plug.area.midPoint), `Expected area midPoint to be defined on the plug, got ${plug.area.midPoint} instead`);
    assert(isDefined(plug.area.midPoint.theta), `Expected area midPoint theta to be defined on the plug, got ${plug.area.midPoint.theta} instead`);
    assert(isDefined(plug.area.circleRadius), `Expected area circleRadius to be defined on the plug, got ${plug.area.circleRadius} instead`);
    assert(isDefined(plug.tiltAngle), `Expected tiltAngle to be defined on the plug, got ${plug.tiltAngle} instead`);

    const isSidePlug = isDefined(plug.type) && isFunctionalProductOnTheSideOfVessel(plug.type);
    let distance;
    if (isSidePlug) {
      // For side-plugs , we expect the "z" value of the midpoint to be set : this tells us the distance of the plug from the top of the vessel
      assert(isDefined(plug.area.midPoint.z), "Expected area's midpoint z to be set");
      distance = plug.area.midPoint.z;
    } else {
      // For bottom-plugs , we expect the "r" value of the midpoint to be set : this tells us the distance of the plug from the center of the vessel
      assert(isDefined(plug.area.midPoint.r), "Expected area's midpoint r to be set");
      distance = plug.area.midPoint.r;
    }
    const { x, y, z } = getMeshSize(mesh);
    const cylinderLength = isSidePlug ? Math.max(x, z) / 10 : y / 20;

    addPlug(mesh, {
      isSidePlug,
      theta: -plug.area.midPoint.theta,
      distance,
      tilt: plug.tiltAngle,
      primaryCylinderHeight: cylinderLength,
      circleRadius: plug.area.circleRadius,
      secondaryCylinderHeight: cylinderLength / 4,
      secondaryCylinderRadius: (16 / 15) * plug.area.circleRadius, // 16/15 is an arbitrary constant
      color: new Color3(0.302, 0.302, 0.302),
    });
  });
}

export function addTrunnions(mesh: Mesh, trunnions: RHIMAPOWearManagementApiClientVesselFunctionalProductDto[]) {
  trunnions.forEach((trunnion) => {
    assert(isDefined(trunnion.area));
    assert(isSteelVesselArea(trunnion.area));
    assert(isDefined(trunnion.area.midPoint.theta));
    assert(isDefined(trunnion.area.midPoint.z));
    const { x, z } = getMeshSize(mesh);
    const cylinderLength = Math.max(x, z) / 20;
    addTrunnionPair(mesh, {
      theta: -trunnion.area.midPoint.theta,
      y: trunnion.area.midPoint.z,
      cylinderRadius: trunnion.area.circleRadius,
      cylinderLength: cylinderLength,
      cylinderColor: new Color3(0.396, 0.42, 0.447),
    });
  });
}

interface TrunnionOptions {
  theta: number;
  /**
   * in meters
   */
  y: number;
  /**
   * in meters
   */
  cylinderRadius: number;
  /**
   * in meters
   */
  cylinderLength: number;
  cylinderColor: Color3;
}
function addTrunnionPair(targetMesh: Mesh, trunnionOptions: TrunnionOptions): void {
  const scene = targetMesh.getScene();
  if (isDebug()) {
    // slow down the mouse wheel zoom in debug mode to make it easier to zoom into details on the mesh
    (scene.activeCamera as ArcRotateCamera).wheelPrecision = 1500;
  }

  // calculate the point where trunnionA should be positioned
  const trunnionsSystem = new TransformNode('trunnionsSystem', scene);
  Tags.AddTagsTo(trunnionsSystem, MEASUREMENT_VIEW_3D_SCENE_TAGS.TRUNNIONS);
  const trunnionMaterial = getMeshMaterial(scene, trunnionOptions.cylinderColor);

  const trunnionPositionA = getPlugPosition(true, trunnionOptions.theta, trunnionOptions.y, targetMesh, trunnionOptions.cylinderRadius);
  createTrunnion(trunnionsSystem, scene, 'trunnionCylinderA', trunnionMaterial, trunnionOptions, trunnionPositionA);
  const trunnionPositionB = getPlugPosition(true, trunnionOptions.theta + 180, trunnionOptions.y, targetMesh, trunnionOptions.cylinderRadius);
  createTrunnion(trunnionsSystem, scene, 'trunnionCylinderB', trunnionMaterial, trunnionOptions, trunnionPositionB);

  trunnionsSystem.parent = targetMesh;
}

/**
 * Creates 2 trunnion-cylinders and wraps them into a TransformNode to position everything together
 *
 * @param plugOptions
 * @param scene
 * @returns
 */
function createTrunnion(
  parent: TransformNode,
  scene: Scene,
  name: string,
  material: StandardMaterial,
  trunnionOptions: TrunnionOptions,
  position: Vector3
): TransformNode {
  const cylinder = CreateCylinder(
    name,
    {
      height: trunnionOptions.cylinderLength,
      diameter: 2 * trunnionOptions.cylinderRadius,
    },
    scene
  );
  cylinder.rotation.x = Math.PI / 2;
  cylinder.rotation.y = Tools.ToRadians(-trunnionOptions.theta);
  cylinder.position = position;

  // the cylinder is currently centered around the ray's intersection point with the mesh.
  // "push" it a bit further out ( by half the cylinder's height )
  let offset = position.clone();
  offset.y = 0;
  offset.normalize();
  offset = offset.scale(trunnionOptions.cylinderLength / 2);
  cylinder.position.addInPlace(offset);

  cylinder.material = material;
  cylinder.setParent(parent);

  return cylinder;
}

// https://en.wikipedia.org/wiki/Linear_interpolation
export function linearInterpolation(
  sourceDomainMin: number,
  sourceDomainMax: number,
  sourceDomainValue: number,
  targetDomainMin: number,
  targetDomainMax: number
): number {
  return (
    (targetDomainMin * (sourceDomainMax - sourceDomainValue) + targetDomainMax * (sourceDomainValue - sourceDomainMin)) / (sourceDomainMax - sourceDomainMin)
  );
}

export interface RegionAnnotationsInfo {
  meshWidth: number;
  meshHeight: number;
  regionAnnotations: RegionAnnotation[];
}

export interface RegionAnnotation {
  regionId: string;
  region3dCenter: Vector3;
  /**
   * Region name
   */
  label: string;
  /**
   * if specified, it will be rendered within the annotation's body
   */
  children?: React.ReactNode;
}

interface RegionArea {
  id: string;
  label: string;
  angleCenter: number;
  yCenter: number;
}

function getRegionArea(meshBoundingBox: BoundingBox, region: RHIMAPOWearManagementApiClientRegionDto, topPosition: number | null): RegionArea {
  // Calculate angle of region's center
  const regionAngleStart = region.area![0]!.theta!;
  let regionAngleEnd = region.area![1]!.theta!;
  if (regionAngleEnd < regionAngleStart) {
    regionAngleEnd += 360;
  }
  // the regions map is from inside part of the ladle, means we need to mirror angles to show correct regions on outside part
  const { angleStart, angleEnd } = convertRegionAngles({
    angleStart: regionAngleStart,
    angleEnd: regionAngleEnd,
  });
  const regionAngleCenter = Tools.ToRadians(angleStart + (angleEnd - angleStart) / 2) + MESH_ANGLE_OFFSET;

  // Calculate y coordinate (height) of of region's center
  const regionStartY = region.area![2]!.z!;
  const regionEndY = region.area![0]!.z!;
  const regionCenterY = regionStartY + (regionEndY - regionStartY) / 2;

  let regionCenterWorldY: number;
  if (isDefined(topPosition)) {
    regionCenterWorldY = topPosition - regionCenterY;
  } else {
    // BOFs still use the constant height, there for we still need to interpolate the position of the regions from [0 to ASSUMED_VESSEL_HEIGHT_IN_METERS]
    const x = regionCenterY;
    const x0 = 0;
    const x1 = ASSUMED_VESSEL_HEIGHT_IN_METERS;
    const y0 = meshBoundingBox.maximumWorld.y;
    const y1 = meshBoundingBox.minimumWorld.y;
    regionCenterWorldY = linearInterpolation(x0, x1, x, y0, y1);
  }

  assert(isDefined(region.id), 'Region.id not set');

  return {
    id: region.id,
    label: region.name!,
    angleCenter: regionAngleCenter,
    yCenter: regionCenterWorldY,
  };
}

function findIntersectionWithMesh(mesh: Mesh, ray: Ray): Vector3 | null {
  if (isDebug()) {
    const scene = mesh.getScene();
    RayHelper.CreateAndShow(ray, scene, new Color3(1, 0, 0));
  }
  const pickingInfo = mesh.intersects(ray);
  return isDefined(pickingInfo.pickedPoint) ? pickingInfo.pickedPoint : null;
}

export function getRegionAnnotationsRectInfo(mesh: Mesh, regions: RHIMAPOWearManagementApiClientRegionDto[]): RegionAnnotationsInfo {
  const meshSize = getMeshSize(mesh);
  const topOffset = mesh.metadata?.shiftOfOriginOnLoad?.z ?? 0;

  const regionAnnotations: RegionAnnotation[] = regions.map((region) => {
    const { area } = region;
    const modifiedArea = area!
      .reduce((acc: Vector3, point) => {
        const { x: length, y: distFromCenter, z: depth } = point;
        const x = isDefined(distFromCenter) ? -distFromCenter : 0;
        let y = isDefined(depth) ? -depth : 0;
        y -= topOffset;
        const z = isDefined(length) ? -length : 0;
        return acc.add(new Vector3(x, y, z));
      }, Vector3.Zero())
      .scale(1 / area!.length);

    switch (region.regionLocation) {
      case RHIMContractsRegionLocation.Side0: {
        const origin = new Vector3(0, modifiedArea.y, modifiedArea.z);
        const intersection = findIntersectionWithMesh(mesh, new Ray(origin, new Vector3(-1, 0, 0)));
        if (intersection !== null) {
          modifiedArea.copyFrom(intersection);
        } else {
          modifiedArea.x = -1.5;
        }
        break;
      }
      case RHIMContractsRegionLocation.Side1: {
        const origin = new Vector3(0, modifiedArea.y, modifiedArea.z);
        const intersection = findIntersectionWithMesh(mesh, new Ray(origin, new Vector3(1, 0, 0)));
        if (intersection !== null) {
          modifiedArea.copyFrom(intersection);
        } else {
          modifiedArea.x = 1;
        }
        break;
      }
      case RHIMContractsRegionLocation.Bottom: {
        const origin = new Vector3(modifiedArea.x, 0, modifiedArea.z);
        const intersection = findIntersectionWithMesh(mesh, new Ray(origin, new Vector3(0, -1, 0)));
        if (intersection !== null) {
          modifiedArea.copyFrom(intersection);
        } else {
          modifiedArea.y = -0.5;
        }
        break;
      }
      default:
        break;
    }

    return {
      regionId: region.id,
      region3dCenter: modifiedArea,
      label: region.name,
    } as RegionAnnotation;
  });

  return {
    meshWidth: meshSize.z,
    meshHeight: meshSize.y,
    regionAnnotations,
  };
}

export const getRegionAnnotationsInfo = (mesh: Mesh, regions: RHIMAPOWearManagementApiClientRegionDto[], topPosition: number | null): RegionAnnotationsInfo => {
  const scene = mesh.getScene();
  const { x: meshWidth } = getMeshSize(mesh);
  const RAY_LENGTH = 2 * meshWidth;
  const regionAnnotations: RegionAnnotation[] = [];
  const regionsWithHolesAtCenter: RegionArea[] = [];

  const regionAreas: RegionArea[] = [];
  for (const region of regions) {
    regionAreas.push(getRegionArea(mesh.getBoundingInfo().boundingBox, region, topPosition));
  }

  for (const regionArea of regionAreas) {
    const rayOrigin = new Vector3(0, regionArea.yCenter, 0);
    const rayDirection = new Vector3(Math.cos(regionArea.angleCenter), 0, Math.sin(regionArea.angleCenter));
    const ray = new Ray(rayOrigin, rayDirection, RAY_LENGTH);
    if (isDebug()) {
      RayHelper.CreateAndShow(ray, scene, new Color3(1, 0, 0));
    }
    const pickingInfo = mesh.intersects(ray);

    if (isDefined(pickingInfo.pickedPoint)) {
      if (isDebug()) {
        createTestBox(pickingInfo.pickedPoint, scene);
      }
      regionAnnotations.push({ regionId: regionArea.id, region3dCenter: pickingInfo.pickedPoint, label: regionArea.label });
    } else {
      // There is a hole at the center of this region
      regionsWithHolesAtCenter.push(regionArea);
    }
  }

  if (hasElements(regionsWithHolesAtCenter)) {
    const regionsWithHolesAnnotations: RegionAnnotation[] = getRegionsWithHolesAnnotations(mesh, regionsWithHolesAtCenter);
    regionsWithHolesAnnotations.forEach((regionWithHolesAnnotations) => regionAnnotations.push(regionWithHolesAnnotations));
  }
  const meshSize = getMeshSize(mesh);
  return { meshWidth: meshSize.x, meshHeight: meshSize.y, regionAnnotations };
};

interface VertexInfo {
  y: number;
  distanceFromCenter: number;
}

function getVerticesDistancesFromCenterAtYPlane(mesh: Mesh): VertexInfo[] {
  const verticesInfo: VertexInfo[] = [];
  const verticesCount = mesh.getTotalVertices();
  const verticesPositions = mesh.getVerticesData(VertexBuffer.PositionKind)!;
  for (let index = 0; index < verticesCount; index++) {
    const vertex = Vector3.FromArray(verticesPositions, 3 * index);
    const meshCenterAtSameHeight = new Vector3(0, vertex.y, 0);
    const distanceFromCenter = Vector3.Distance(vertex, meshCenterAtSameHeight);
    verticesInfo.push({ y: vertex.y, distanceFromCenter });
  }
  // Sort them by y coord
  const verticesSortedByY = sortGroup(verticesInfo, ['y']);

  return verticesSortedByY;
}

function getRegionsWithHolesAnnotations(mesh: Mesh, regionsHavingHoles: RegionArea[]): RegionAnnotation[] {
  // Calculate distances from center of mesh ( at the right height ) for all vertices
  const regionsWithHolesAnnotations: RegionAnnotation[] = [];
  const verticesSortedByY = getVerticesDistancesFromCenterAtYPlane(mesh);

  for (const regionHavingHoles of regionsHavingHoles) {
    for (const [index, vertexInfo] of verticesSortedByY.entries()) {
      if (vertexInfo.y >= regionHavingHoles.yCenter) {
        // Collect all vertices of same y and get their average distance
        const averageDistance = getAverageDistanceAtHeight(verticesSortedByY, index);
        const regionWithHoleCenter = new Vector3(
          averageDistance * Math.cos(regionHavingHoles.angleCenter),
          regionHavingHoles.yCenter,
          averageDistance * Math.sin(regionHavingHoles.angleCenter)
        );
        regionsWithHolesAnnotations.push({
          regionId: regionHavingHoles.id,
          region3dCenter: regionWithHoleCenter,
          label: regionHavingHoles.label,
        });
        break;
      }
    }
  }

  return regionsWithHolesAnnotations;
}

function getAverageDistanceAtHeight(verticesSortedByY: VertexInfo[], startingIndex: number): number {
  let distanceSum = 0;
  let verticesCount = 0;
  const observedVertexY = verticesSortedByY[startingIndex]!.y;
  for (let index = startingIndex; index < verticesSortedByY.length; index++) {
    const vertexInfo = verticesSortedByY[index]!;
    if (vertexInfo.y !== observedVertexY) {
      break;
    }
    distanceSum += vertexInfo.distanceFromCenter;
    verticesCount++;
  }
  return distanceSum / verticesCount;
}

export const doesMeasurementPassDateRangeFilter = (measurementCreatedOnUTC: string, filterDateStart: Date | null, filterDateEnd: Date | null) => {
  if (filterDateStart || filterDateEnd) {
    const measurementCreatedOnDatetime = parseUTC(measurementCreatedOnUTC);
    const rangeStart = filterDateStart ? filterDateStart : measurementCreatedOnDatetime;
    const rangeEnd = filterDateEnd ? filterDateEnd : measurementCreatedOnDatetime;
    return (
      (isSameDay(measurementCreatedOnDatetime, rangeStart) || isAfter(measurementCreatedOnDatetime, rangeStart)) &&
      (isSameDay(measurementCreatedOnDatetime, rangeEnd) || isBefore(measurementCreatedOnDatetime, rangeEnd))
    );
  }
  return true;
};

/**
 * @param measurement The measurement whose sibling measurement we want to check
 * @param siblingMeasurementToCheck "earlier" or "later"
 * @param measurements - A list of measurements that is inversely sorted by creation data. The first element refers to the newest measurement, the last one to the oldest
 * @returns true if the earlier/later sibling measurement has the same heat number as the measurement in question, false if not or if the measurement cannot be found in the list of shown measurements
 */
interface SiblingMeasurement {
  id: string;
  heat: number;
}
export const isSiblingMeasurementOfSameHeat = (
  measurement: SiblingMeasurement,
  siblingMeasurementToCheck: 'earlier' | 'later',
  measurements: SiblingMeasurement[]
) => {
  const index = measurements.findIndex((measurementItem) => measurementItem.id === measurement.id);
  if (index === -1) {
    return false;
  }
  const measurementToCheck = siblingMeasurementToCheck === 'earlier' ? measurements[index - 1] : measurements[index + 1];
  if (!isDefined(measurementToCheck) || !isDefined(measurementToCheck.heat) || !isDefined(measurement.heat)) {
    return false;
  }
  return measurementToCheck.heat === measurement.heat;
};

interface TimeAgoTextForTargetTimezoneProps {
  targetTimezone: string;
  datetime: string;
}
export const TimeAgoTextForTargetTimezone: React.ChildlessComponent<TimeAgoTextForTargetTimezoneProps> = ({ targetTimezone, datetime }) => {
  const {
    userSettings: { locale },
  } = useUserSettings();

  const timeAgoSpan = useTimeAgoTextUpdatedEveryMinute({ utcTime: zonedTimeToUtc(datetime, targetTimezone).toISOString(), targetTimezone, locale });

  return timeAgoSpan;
};

// Given a point on the mesh it returns the angle between that point and the center of the mesh in the y plane in degrees
export function getMeshPointAngle(point: Vector3): number {
  // Calculate the angle of the point from the mesh center in the y plane.
  // (so, picked point has coords [x,y,z] and mesh center is at [0,y,0])
  let angleFromCenterInPlaneY = Math.atan2(point.z, point.x);
  // Take into account camera rotation alpha
  angleFromCenterInPlaneY -= getDefaultCameraState().alpha;
  // Convert to a clockwise rotation which we use for the compass at the bottom of the mesh
  angleFromCenterInPlaneY = -angleFromCenterInPlaneY;
  // Map to a (0 to 2*PI) range
  angleFromCenterInPlaneY = (angleFromCenterInPlaneY + 2 * Math.PI) % (2 * Math.PI);
  // Convert to degrees
  angleFromCenterInPlaneY = Angle.FromRadians(angleFromCenterInPlaneY).degrees();

  return angleFromCenterInPlaneY;
}

export function getRectangularRegionAtPoint(regions: RHIMAPOWearManagementApiClientRegionDto[], pickedPoint: PickedPoint) {
  const { mesh, barycentricVolumes, indices, pickedPoint: point } = pickedPoint;

  const depthShift = mesh.metadata?.shiftOfOriginOnLoad?.z ?? 0;

  const uvs = mesh.getVerticesData(VertexBuffer.UV2Kind);

  if (!isDefined(uvs)) {
    return null;
  }

  // searching in which part of UV map the point is
  const uv0 = Vector2.FromArray(uvs, 2 * indices.x);
  const uv1 = Vector2.FromArray(uvs, 2 * indices.y);
  const uv2 = Vector2.FromArray(uvs, 2 * indices.z);
  const { y: u } = uv0.scale(barycentricVolumes.x).add(uv1.scale(barycentricVolumes.y)).add(uv2.scale(barycentricVolumes.z));

  // knowing the V position we can say to which region location it corresponds
  const regionLocation = u > 2 / 3 ? RHIMContractsRegionLocation.Side1 : u < 1 / 3 ? RHIMContractsRegionLocation.Bottom : RHIMContractsRegionLocation.Side0;

  /**
   * To find a region we need to look in all corresponding regions and find its bounding box
   * Having the bounding box we need to aline the actual coordinate with its real value, like Z axis corresponds to the length, but it points
   * to the opposite direction, X axis defines the vertical position of the point on the bottom but it is also flipped, Y axis represents the depth, but it is
   * shifted while parsing the mesh. After applying all the corrections we just need to check if point is insyde of the rectangle.
   * Traversing is finished when the first region is found.
   */
  const correspondingRegion = regions.find((region) => {
    // TODO refactor it to support new types of regions
    if (region.regionLocation !== regionLocation || !isDefined(region.area)) {
      return false;
    }

    assert(isDefined(region.area));
    assert(isDefined(region.area[0]));
    assert(isDefined(region.area[1]));
    assert(isDefined(region.area[2]));

    if (regionLocation === RHIMContractsRegionLocation.Bottom) {
      const regionBB = region.area.reduce(
        (acc, { x, y }) => {
          acc.min.u = Math.min(acc.min.u, x!);
          acc.min.v = Math.min(acc.min.v, y!);
          acc.max.u = Math.max(acc.max.u, x!);
          acc.max.v = Math.max(acc.max.v, y!);
          return acc;
        },
        { min: { u: Infinity, v: Infinity }, max: { u: -Infinity, v: -Infinity } }
      );

      const length = -point.z;
      const distFromCenter = -point.x;

      if (length < regionBB.min.u || length > regionBB.max.u || distFromCenter < regionBB.min.v || distFromCenter > regionBB.max.v) {
        return false;
      }
      return true;
    }

    // in case of other region location even though UV map is flipped, the absolute values are the same for left and right sides
    const regionBB = region.area.reduce(
      (acc, { x, z }) => {
        acc.min.u = Math.min(acc.min.u, x!);
        acc.min.v = Math.min(acc.min.v, z!);
        acc.max.u = Math.max(acc.max.u, x!);
        acc.max.v = Math.max(acc.max.v, z!);
        return acc;
      },
      { min: { u: Infinity, v: Infinity }, max: { u: -Infinity, v: -Infinity } }
    );

    const length = -point.z;
    const depth = -point.y - depthShift;

    if (length < regionBB.min.u || length > regionBB.max.u || depth < regionBB.min.v || depth > regionBB.max.v) {
      return false;
    }

    return true;
  });

  return correspondingRegion;
}

/**
 * Given a list of regions, a mesh and a point, returns the region that contains that point ( undefined otherwise ).
 */
export function getRegionAtPoint(
  regions: RHIMAPOWearManagementApiClientRegionDto[],
  mesh: AbstractMesh,
  point: Vector3,
  topPosition: number | null
): RHIMAPOWearManagementApiClientRegionDto | undefined {
  const angleFromCenterInPlaneY = getMeshPointAngle(point);

  let pointYToMeters: number;

  if (isDefined(topPosition)) {
    pointYToMeters = topPosition - point.y;
  } else {
    const { y: meshHeight } = getMeshSize(mesh);
    pointYToMeters = meshYToMeters(meshHeight, point.y);
  }

  // Loop through the regions and find which one - if any - contains the given point in its area
  const correspondingRegion = regions.find((region) => {
    // TODO refactor it to support new types of regions
    if (!isDefined(region.area)) {
      return false;
    }

    assert(isDefined(region.area));
    assert(isDefined(region.area[0]));
    assert(isDefined(region.area[1]));
    assert(isDefined(region.area[2]));

    const regionAngleStart = ensure(region.area[0].theta);
    const regionAngleEnd = ensure(region.area[1].theta);
    const doesRegionAngleWrap = regionAngleEnd < regionAngleStart;
    let isAngleWithinRegion = false;
    if (doesRegionAngleWrap) {
      isAngleWithinRegion = angleFromCenterInPlaneY >= regionAngleStart || angleFromCenterInPlaneY <= regionAngleEnd;
    } else {
      isAngleWithinRegion = angleFromCenterInPlaneY >= regionAngleStart && angleFromCenterInPlaneY <= regionAngleEnd;
    }

    const regionBottomZInMeters = ensure(region.area[2].z);
    const regionTopZInMeters = ensure(region.area[0].z);
    const isPointYWithinRegion = pointYToMeters >= regionBottomZInMeters && pointYToMeters <= regionTopZInMeters;

    // If the given point's angle from the center of the mesh at the y-plane is within the region's area angle-range and
    // if the given point's y coordinate is within the region's area height-range then we have a winner.
    return isAngleWithinRegion && isPointYWithinRegion;
  });
  return correspondingRegion;
}

/**
 * Converts a y-coordinate on the mesh to meters.
 * The mesh is presummed to be centered at its y axis.
 * (its bottom is at y=-MESH_HEIGHT/2 and its top is at y=MESH_HEIGHT/2).
 * The values in meters are considered to start from 0 at the very top of the mesh and
 * reach ASSUMED_VESSEL_HEIGHT_IN_METERS at its very bottom.
 *
 * @param meshHeight the total mesh height
 * @param meshY a y coordinate on the y-centered mesh
 * @returns the corresponding interpolated value in meters
 */
const meshYToMeters = (meshHeight: number, meshY: number) => {
  return ASSUMED_VESSEL_HEIGHT_IN_METERS - ((ASSUMED_VESSEL_HEIGHT_IN_METERS * meshY) / meshHeight + ASSUMED_VESSEL_HEIGHT_IN_METERS / 2);
};

export const isThicknessMapValid = (thicknessMap: Float32Array) => thicknessMap.length > 1;

export function convertFunctionalProductsUnitsToMeters(
  products: RHIMAPOWearManagementApiClientVesselFunctionalProductDto[]
): RHIMAPOWearManagementApiClientVesselFunctionalProductDto[] {
  return products.map((product) => {
    assert(isDefined(product.area), 'Functional product area is not set');
    assert(isSteelVesselArea(product.area), 'Functional product area is not of SteelVessel type');
    assert(isDefined(product.type), 'Functional product type is not set');
    const isSidePlug = isFunctionalProductOnTheSideOfVessel(product.type);
    let distance;
    if (isSidePlug) {
      // For side-plugs , we expect the "z" value of the midpoint to be set : this tells us the distance of the plug from the top of the vessel
      assert(isDefined(product.area.midPoint.z), "Functional product area's midpoint z is not set");
      distance = product.area.midPoint.z;
    } else {
      // For bottom-plugs , we expect the "r" value of the midpoint to be set : this tells us the distance of the plug from the center of the vessel
      assert(isDefined(product.area.midPoint.r), "Functional product area's midpoint r  is not set");
      distance = product.area.midPoint.r;
    }
    distance = centimetersToMeters(distance);

    return {
      ...product,
      area: {
        ...product.area,
        circleRadius: centimetersToMeters(product.area.circleRadius),
        midPoint: { ...product.area.midPoint, ...(isSidePlug ? { z: distance } : { r: distance }) },
      },
    };
  });
}
