import 'd3-transition';

import { sectionsHorizontalSliceWrapperMeasurementView } from '@rhim/test-ids';
import { assert, isDefined } from '@rhim/utils';
import { drag } from 'd3-drag';
import { scaleLinear } from 'd3-scale';
import { select } from 'd3-selection';
import { arc, line } from 'd3-shape';
import type { FunctionComponent, RefObject } from 'react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useResizeAware from 'react-resize-aware';
import styled from 'styled-components';
import type { CylindricalSectionCutsMetadata, WallProps } from 'typings/internal/sections';

import { SvgFilters } from './SvgFilters';
import { SvgNodata } from './SvgNodata';
import { SvgRblValue, TextPosition } from './SvgRblValue';
import { showFixedNumber } from './utils';

interface HorizontalSliceProps {
  slicedData: Array<WallProps>;
  meta: CylindricalSectionCutsMetadata;
  setHorizontalIndex: (currentAngle: number) => void;
  heatNumber: number;
}

type AngleMarkers = { angle: number; text: string };

const radiansInDegree = Math.PI / 180;
const rotationIndicator = arc();
const pathGenerator = line<{ radius: number | null; angle: number }>()
  .defined((d) => isDefined(d.radius))
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  .x((d) => (d.radius! || 0) * Math.cos(d.angle * radiansInDegree - Math.PI / 2))
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  .y((d) => (d.radius! || 0) * Math.sin(d.angle * radiansInDegree - Math.PI / 2));

const TAU = 2 * Math.PI;

// making marks on each 45 degree. Creating and array with length = [360 / 45 = 8]
const angleMarksOnLadle: AngleMarkers[] = [...Array(8)].map((_, i) => {
  const angle = (i * Math.PI) / 4;
  return {
    angle: angle,
    text: i % 2 > 0 ? '' : Math.round(angle / radiansInDegree) + '˚',
  };
});

const HorizontalSliceComponent: FunctionComponent<React.PropsWithChildren<HorizontalSliceProps>> = (props) => {
  const { slicedData, setHorizontalIndex, meta } = props;
  const svgRef: RefObject<SVGSVGElement> = useRef<SVGSVGElement>(null);
  const { t } = useTranslation(['visualization']);
  // angle in degrees
  const [angle, setAngle] = useState<number>(0);
  const [indexLeft, setIndexLeft] = useState<number>(0);
  const [resizeListener, sizes] = useResizeAware();

  const angleNumber = useMemo(() => slicedData.length, [slicedData]);
  const sliceWidth = useMemo(() => Math.min(sizes.width ?? 500, sizes.height ?? 500), [sizes.width, sizes.height]);
  const radiusOfElement = useMemo(() => sliceWidth / 2, [sliceWidth]);
  const outerRadius = useMemo(() => 0.7 * radiusOfElement, [radiusOfElement]);
  // positioning markers on the circle
  const positionMagnitude = useMemo(() => outerRadius + 30, [outerRadius]);
  const indicatorLineHalfWidth = useMemo(() => 0.012 * radiusOfElement, [radiusOfElement]);
  const lineOutOfCircleLevel = useMemo(() => 0.08 * radiusOfElement, [radiusOfElement]);
  const lineOffset = useMemo(() => radiusOfElement - outerRadius - lineOutOfCircleLevel, [radiusOfElement, outerRadius, lineOutOfCircleLevel]);

  // const availableAnglesOnTheScale = useMemo(() => slicedData.map((section) => section.angle), [slicedData]);

  const radiusScale = useMemo(() => {
    // increasing max radius by 20% to get better paddings on the graph
    const maxScale = meta.maxRadius * 1.2;
    // scaling data u
    return scaleLinear().domain([0, maxScale]).range([0, outerRadius]);
  }, [meta.maxRadius, outerRadius]);

  // uncomment and adapt when we have a reference data
  // useEffect(() => {
  // radialLineGenerator.radius((d) => (d.radius ? radiusScale(d.radius) : outerRadius));
  // diffArea
  //   .outerRadius((d) => (d.radius ? radiusScale(d.radius) : outerRadius))
  //   .innerRadius((d) => (d.radius ? radiusScale(d.radius) : outerRadius) - 10 + 10 * (Math.random() - 0.7)); // TODO get values from extra JSON
  // }, [radiusScale, outerRadius]);

  useEffect(() => {
    const rotateButton = select(svgRef.current).select<SVGCircleElement>('#rotation-button');

    const rotationHandler = drag<SVGCircleElement, unknown>().on('drag', (d) => {
      // the initial Dragger position is on 90 degrees, that's why we need to subtract it
      const dx = d.sourceEvent.offsetX - radiusOfElement;
      const dy = radiusOfElement - d.sourceEvent.offsetY;
      const angleInRad = (Math.atan2(dx, dy) + TAU) % TAU;

      const step = TAU / angleNumber;
      const rotationFactor = (angleInRad % step) / step;
      if (rotationFactor > 0.8) {
        const nextAngle = Math.ceil(angleInRad / step) * step;
        setAngle(nextAngle);
      }
    });

    rotationHandler(rotateButton);

    const markers = select(svgRef.current).select('#angle-markers').selectAll('.marker').data(angleMarksOnLadle).enter().append('g').attr('class', 'marker');

    markers
      .append('path')
      .attr('stroke', '#d8dde1')
      .attr('stroke-width', '1')
      .attr('d', (d) => {
        const startX = outerRadius * Math.sin(Math.PI - d.angle);
        const startY = outerRadius * Math.cos(Math.PI - d.angle);

        const endX = (outerRadius + 10) * Math.sin(Math.PI - d.angle);
        const endY = (outerRadius + 10) * Math.cos(Math.PI - d.angle);

        return `M${startX} ${startY}L${endX} ${endY}`;
      });

    markers
      .append('text')
      .attr('class', 'marker')
      .attr('x', (d) => positionMagnitude * Math.sin(Math.PI - d.angle))
      .attr('y', (d) => positionMagnitude * Math.cos(Math.PI - d.angle))
      .attr('dominant-baseline', 'middle')
      .attr('text-anchor', 'middle')
      .attr('opacity', 0.5)
      .text((d) => d.text);

    return () => {
      markers.remove();
    };
  }, [angleNumber, outerRadius, positionMagnitude, radiusOfElement]);

  useEffect(() => {
    select(svgRef.current)
      .select('#rotation-group')
      .attr('transform', `rotate(${angle / radiansInDegree}, ${radiusOfElement}, ${radiusOfElement})`);
    select(svgRef.current)
      .select('#rotation-group-upper-layer')
      .attr('transform', `rotate(${angle / radiansInDegree}, ${radiusOfElement}, ${radiusOfElement})`);

    const rotationPath = rotationIndicator({
      innerRadius: outerRadius - indicatorLineHalfWidth,
      outerRadius: outerRadius + indicatorLineHalfWidth,
      startAngle: 0,
      endAngle: angle < TAU ? angle : 0,
    });
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    select(svgRef.current).select<SVGPathElement>('#rotation-indicator').attr('d', rotationPath!);

    /**
     * 0 degree is located on top but we show the horizontal slice
     * it means we need to get the data from 270 degree rotation
     */
    const sliceOffset = 1.5 * Math.PI;
    const rotationAngleAfterOffset = (sliceOffset - angle + TAU) % TAU;
    const normalizedValue = rotationAngleAfterOffset / TAU;
    const index = Math.round(normalizedValue * angleNumber);
    setHorizontalIndex(index);
    setIndexLeft(index);

    const angleGrad = Math.round(rotationAngleAfterOffset / radiansInDegree);
    select(svgRef.current)
      .select('#rotation-value text')
      .text(angleGrad + '˚');
  }, [angle, setHorizontalIndex, angleNumber, indicatorLineHalfWidth, outerRadius, radiusOfElement]);

  useEffect(() => {
    select(svgRef.current)
      .select('#angle-markers')
      .selectAll<SVGTextElement, AngleMarkers>('.marker text')
      .attr('transform', (d) => {
        const x = positionMagnitude * Math.sin(Math.PI - d.angle);
        const y = positionMagnitude * Math.cos(Math.PI - d.angle);
        return `rotate(${-angle / radiansInDegree}, ${x}, ${y})`;
      });
  }, [angle, positionMagnitude]);

  useEffect(() => {
    const svg = select(svgRef.current);
    const pointerLeft = svg.select('#pointer-left');
    const pointerRight = svg.select('#pointer-right');
    const indexRight = (indexLeft + slicedData.length / 2) % slicedData.length;
    const dataLeft = slicedData[indexLeft];
    const dataRight = slicedData[indexRight];
    const bottomMaxRadius = radiusScale(angleNumber);

    assert(isDefined(dataLeft), 'HorizontalSliceComponent: dataLeft not defined');
    assert(isDefined(dataRight), 'HorizontalSliceComponent: dataRight not defined');

    if (isDefined(dataLeft.radius)) {
      pointerLeft.select('#no-data-left').attr('opacity', 0);
      pointerLeft
        .select('#pointer-left-value')
        .attr('opacity', 1)
        .attr('transform', `translate(${-radiusScale(dataLeft.radius)}, 0)`);
      const text = isDefined(dataLeft.rbl) ? t('visualization:sections.mm', { size: showFixedNumber(dataLeft.rbl.min, 2) }) : '-';
      pointerLeft.select('text').text(text);
    } else {
      pointerLeft.select('#no-data-left').attr('opacity', 1).attr('transform', `translate(${-bottomMaxRadius}, 0)`);
      pointerLeft.select('#pointer-left-value').attr('opacity', 0);
    }

    if (isDefined(dataRight.radius)) {
      pointerRight.select('#no-data-right').attr('opacity', 0);
      pointerRight
        .select('#pointer-right-value')
        .attr('opacity', 1)
        .attr('transform', `translate(${radiusScale(dataRight.radius)}, 0)`);
      const text = isDefined(dataRight.rbl) ? t('visualization:sections.mm', { size: showFixedNumber(dataRight.rbl.min, 2) }) : '-';
      pointerRight.select('text').text(text);
    } else {
      pointerRight.select('#no-data-right').attr('opacity', 1).attr('transform', `translate(${bottomMaxRadius}, 0)`);
      pointerRight.select('#pointer-right-value').attr('opacity', 0);
    }
  }, [indexLeft, radiusScale, slicedData, t, angleNumber]);

  useEffect(() => {
    const graphGroup = select(svgRef.current).select<SVGGElement>('#graph-group');

    const commonPath = slicedData.map((p) => {
      return {
        radius: isDefined(p.radius) ? radiusScale(p.radius) : null,
        angle: p.angle,
      };
    });

    let commonAVGPath = pathGenerator(commonPath);

    if (isDefined(commonAVGPath)) {
      const lastId = commonPath.length - 1;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      if (isDefined(commonPath[0]!.radius) && isDefined(commonPath[lastId])) {
        // in case we have values in first and last segments, the path should be closed
        commonAVGPath += 'Z';
      }

      graphGroup
        .append('path')
        .attr('class', 'slice-path')
        .attr('fill', 'none')
        .attr('stroke', '#003262')
        .attr('stroke-width', '2')
        .attr('stroke-line-join', 'round')
        .attr('stroke-linecap', 'round')
        .attr('d', commonAVGPath);
    }

    return () => {
      graphGroup.selectAll('.slice-path').remove();
    };
  }, [slicedData, radiusScale]);

  return (
    <SWrapper data-test-id={sectionsHorizontalSliceWrapperMeasurementView}>
      {resizeListener}
      <svg ref={svgRef} width="100%" height="100%" preserveAspectRatio="xMinYMin meet">
        <SvgFilters />
        <g id="rotation-group">
          <g transform={`translate(${radiusOfElement}, ${radiusOfElement})`}>
            <g id="angle-markers"></g>
            <circle stroke="#d8dde1" fill="none" r={outerRadius} />
          </g>
          <g transform={`translate(${radiusOfElement}, ${radiusOfElement})`} id="graph-group">
            <path id="diff-area" stroke="#778894" strokeWidth="2" strokeDasharray="2,4" strokeLinejoin="round" strokeLinecap="round" fill="#d8dde1" />
          </g>
        </g>
        <path id="rotation-indicator" fill="#ccdaee" transform={`translate(${radiusOfElement}, ${radiusOfElement})`} />
        <g transform={`translate(${lineOffset}, ${radiusOfElement})`}>
          <path fill="none" stroke="#b1bbc2" strokeWidth="1" strokeLinecap="round" d={`M0,0H${2 * outerRadius + lineOutOfCircleLevel}`} />
          <path fill="none" stroke="#1dc1e1" strokeWidth="3" strokeLinecap="round" d={`M0,0H${outerRadius + lineOutOfCircleLevel}`} />

          <g id="rotation-value" transform="translate(-20, 0)">
            <rect width="52" height="32" fill="white" strokeWidth="1" stroke="#d8dde1" rx="3" transform="translate(-26, -16)" />
            <text dominantBaseline="middle" textAnchor="middle"></text>
          </g>
        </g>
        <g transform={`translate(${radiusOfElement}, ${radiusOfElement})`}>
          <g id="pointer-left">
            <SvgRblValue id="pointer-left-value" textPosition={TextPosition.Right} />
            <SvgNodata id="no-data-left" />
          </g>
          <g id="pointer-right">
            <SvgRblValue id="pointer-right-value" textPosition={TextPosition.Left} />
            <SvgNodata id="no-data-right" />
          </g>
        </g>
        <g id="rotation-group-upper-layer">
          <g id="rotation-button" cursor="pointer" transform={`translate(${radiusOfElement}, ${radiusOfElement - outerRadius})`}>
            <circle r="18" fill="#003262" stroke="#ffffff" strokeWidth="2" filter="url(#drop-shadow)" />
            <path
              fill="#487CB4"
              fillRule="evenodd"
              transform="scale(0.9) rotate(90, 12, 0)"
              d="M14.293 17.293 13 18.586V5.414l1.293 1.293a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414l-3-3a.999.999 0 0 0-1.414 0l-3 3a.999.999 0 1 0 1.414 1.414L11 5.414v13.172l-1.293-1.293a.999.999 0 1 0-1.414 1.414l3 3a.997.997 0 0 0 1.414 0l3-3a.999.999 0 1 0-1.414-1.414"
            />
          </g>
        </g>
      </svg>
    </SWrapper>
  );
};

HorizontalSliceComponent.whyDidYouRender = true;

export const HorizontalSlice = React.memo(HorizontalSliceComponent);

const SWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;
