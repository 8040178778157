import { settings } from '@rhim/design';
import { i18nReact } from '@rhim/i18n';
import { RHIMFleetOverviewServiceV1ControllersMeasurementSequenceDto } from '@rhim/rest/fleetOverview';
import { ensure, isDefined, roundFloatToDecimalPlaces } from '@rhim/utils';
import { Line } from '@vx/shape';
import { ScaleLinear } from 'd3-scale';
import React, { useMemo, useRef } from 'react';
import styled from 'styled-components';

import { useScaleY } from '../hooks/useScaleY';
import { useSidesLegends } from '../hooks/useSidesLegends';
import { useTooltip } from '../hooks/useTooltip';
import { SDashedLine } from '../styles';
import { VesselLine } from '../types';
import { getPaddedDomainY, LABEL_FONT_SIZE_PX, LABEL_PADDING_BOTTOM_PX } from '../utils';
import {
  AVERAGE_LINE_COLOR,
  DEFAULT_GRAPH_AREA_HEIGHT,
  DEFAULT_GRAPH_HEIGHT_TOTAL,
  SHAPE_RENDERING_GEOMETRIC_PRECISION,
  SIDE_0_LINE_COLOR,
  SIDE_1_LINE_COLOR,
} from './constants';
import Graph from './Graph';

interface WearRate {
  tonnageKilograms: number;
  side0WearRate: null | number;
  side1WearRate: null | number;
}

function getColor(isSide0: boolean) {
  return isSide0 ? SIDE_0_LINE_COLOR : SIDE_1_LINE_COLOR;
}

function getLineFor(xScale: ScaleLinear<number, number>, yScale: ScaleLinear<number, number>, wearRates: WearRate[], side: 'side0' | 'side1', index: number) {
  if (index === 0) {
    return null;
  }
  const wearRatePrevious = ensure(wearRates[index - 1]);
  const wearRateCurrent = ensure(wearRates[index]);

  const currentValue = side === 'side0' ? wearRateCurrent.side0WearRate : wearRateCurrent.side1WearRate;
  const currentSegmentX = xScale(wearRateCurrent.tonnageKilograms);
  const currentSegmentY = isDefined(currentValue) ? yScale(currentValue) : null;

  const previousValue = side === 'side0' ? wearRatePrevious.side0WearRate : wearRatePrevious.side1WearRate;
  const previousSegmentX = xScale(wearRatePrevious.tonnageKilograms);
  const previousSegmentY = isDefined(previousValue) ? yScale(previousValue) : currentSegmentY;

  let nearestSegmentX: undefined | number;
  let nearestSegmentY: undefined | number;
  if (isDefined(currentValue) && !isDefined(previousValue) && index > 0) {
    for (let i = index - 1; i > 0; i--) {
      const item = ensure(wearRates[i]);
      const nearestDefinedValue = side === 'side0' ? item.side0WearRate : item.side1WearRate;
      if (isDefined(nearestDefinedValue)) {
        nearestSegmentX = xScale(item.tonnageKilograms);
        nearestSegmentY = yScale(nearestDefinedValue);
        break;
      }
    }
  }

  const lineColor = getColor(side === 'side0');
  return (
    <React.Fragment key={`${side}-${index}`}>
      {/* THE CURRENT SEGMENT'S MAIN HORIZONTAL LINE */}
      {isDefined(currentSegmentY) && (
        <SLine
          key={`${side}-primary-${wearRateCurrent.tonnageKilograms}-${index}`}
          from={{ x: previousSegmentX, y: currentSegmentY }}
          to={{ x: currentSegmentX, y: currentSegmentY }}
          stroke={lineColor}
        />
      )}
      {/* CONNECT THIS SEGMENT'S LEFTMOST POINT TO THE PREVIOUS SEGMENT'S RIGHTMOST POINT WITH A VERTICAL LINE */}
      {isDefined(currentSegmentY) && isDefined(previousSegmentY) && (
        <SLine
          key={`${side}-verticalConnection-${wearRateCurrent.tonnageKilograms}-${index}`}
          from={{ x: previousSegmentX, y: previousSegmentY }}
          to={{ x: previousSegmentX, y: currentSegmentY }}
          stroke={lineColor}
        />
      )}
      {/* CONNECT THIS SEGMENT WITH THE MOST RECENT DEFINED SEGMENT WITH A DASHED LINE*/}
      {isDefined(previousSegmentY) && isDefined(nearestSegmentX) && isDefined(nearestSegmentY) && (
        <Line
          key={`${side}-dashedConnection-${wearRateCurrent.tonnageKilograms}-${index}`}
          from={{ x: previousSegmentX, y: previousSegmentY }}
          to={{ x: nearestSegmentX, y: nearestSegmentY }}
          stroke={settings.colors.Primary.Grey_4}
          strokeDasharray={2}
          shapeRendering={SHAPE_RENDERING_GEOMETRIC_PRECISION}
        />
      )}
    </React.Fragment>
  );
}

function getLinesFor(xScale: ScaleLinear<number, number>, yScale: ScaleLinear<number, number>, wearRates: WearRate[], side: 'side0' | 'side1') {
  const ret = [];
  for (let index = 0; index < wearRates.length; index++) {
    ret.push(getLineFor(xScale, yScale, wearRates, side, index));
  }
  const id = `group-wearRate-${side}`;
  return (
    <g key={id} id={id}>
      {ret}
    </g>
  );
}

function getLargestValuesTexts(xScale: ScaleLinear<number, number>, yScale: ScaleLinear<number, number>, wearRates: WearRate[]) {
  const ret = [];

  for (const [index, wearRate] of wearRates.entries()) {
    if (index === 0) {
      continue;
    }
    if (!(isDefined(wearRate.side0WearRate) && isDefined(wearRate.side1WearRate))) {
      continue;
    }
    const previousMeasurement = ensure(wearRates[index - 1]);
    const measurementSegmentTonnageAtMiddle = previousMeasurement.tonnageKilograms + (wearRate.tonnageKilograms - previousMeasurement.tonnageKilograms) / 2;
    const hasSide0TheLargestValue = wearRate.side0WearRate >= wearRate.side1WearRate;
    const largestValue = Math.max(wearRate.side0WearRate, wearRate.side1WearRate);
    const y = yScale(largestValue) - LABEL_PADDING_BOTTOM_PX - LABEL_FONT_SIZE_PX;
    ret.push(
      <text
        key={`largestValueText-${index}`}
        x={xScale(measurementSegmentTonnageAtMiddle)}
        y={y}
        textAnchor="middle"
        dominantBaseline="hanging"
        fontFamily={settings.typography.FontFamily.Regular}
        fontSize={LABEL_FONT_SIZE_PX}
        fill={getColor(hasSide0TheLargestValue)}
      >
        {roundFloatToDecimalPlaces(largestValue, 1)}
      </text>
    );
  }
  const id = `group-largestValueTexts`;
  return (
    <g key={id} id={id}>
      {ret}
    </g>
  );
}

interface Props {
  xScale: ScaleLinear<number, number>;
  measurementSequence: RHIMFleetOverviewServiceV1ControllersMeasurementSequenceDto;
  line: VesselLine;
  average: number;
}
const GraphWearRate: React.ChildlessComponent<Props> = ({ xScale, measurementSequence, line, average }) => {
  const { t } = i18nReact.useTranslation(['blastFurnaceRunner-report', 'shared']);
  const sidesLegends = useSidesLegends();
  const svgGraphAreaRef = useRef<SVGRectElement>(null);
  const { tooltipIndicator, tooltipPanel } = useTooltip(svgGraphAreaRef.current, xScale, measurementSequence, `wearRate${line}`);

  const wearRates = useMemo(() => {
    const ret: WearRate[] = [];
    let firstTonnageKilograms = 0;
    if (isDefined(measurementSequence.permanentLiningMeasurement)) {
      firstTonnageKilograms = measurementSequence.permanentLiningMeasurement.tonnage;
    } else if (isDefined(measurementSequence.wearLiningInitialMeasurement)) {
      firstTonnageKilograms = measurementSequence.wearLiningInitialMeasurement.tonnage;
    }
    ret.push({
      tonnageKilograms: firstTonnageKilograms,
      side0WearRate: null,
      side1WearRate: null,
    });
    for (const measurement of measurementSequence.measurementTuples) {
      if (isDefined(measurement.workingLiningMeasurement)) {
        ret.push({
          tonnageKilograms: measurement.workingLiningMeasurement.tonnage,
          side0WearRate:
            line === VesselLine.Slagline
              ? measurement.workingLiningMeasurement.side0SlaglineWearRate
              : measurement.workingLiningMeasurement.side0IronlineWearRate,
          side1WearRate:
            line === VesselLine.Slagline
              ? measurement.workingLiningMeasurement.side1SlaglineWearRate
              : measurement.workingLiningMeasurement.side1IronlineWearRate,
        });
      } else {
        ret.push({
          tonnageKilograms: measurement.tonnage,
          side0WearRate: null,
          side1WearRate: null,
        });
      }
    }
    return ret;
  }, [measurementSequence, line]);

  const maxYValue = useMemo(() => {
    let maxValue = Number.NEGATIVE_INFINITY;
    for (const wearRate of wearRates) {
      if (isDefined(wearRate.side0WearRate)) {
        maxValue = Math.max(maxValue, wearRate.side0WearRate);
      }
      if (isDefined(wearRate.side1WearRate)) {
        maxValue = Math.max(maxValue, wearRate.side1WearRate);
      }
    }
    const paddedMaxValue = getPaddedDomainY(DEFAULT_GRAPH_AREA_HEIGHT, maxValue);
    return paddedMaxValue;
  }, [wearRates]);

  const yScale = useScaleY(DEFAULT_GRAPH_AREA_HEIGHT, 0, maxYValue);

  const measurementsAtKilograms = wearRates.map((wearRate) => wearRate.tonnageKilograms);

  const side0Line = useMemo(() => getLinesFor(xScale, yScale, wearRates, 'side0'), [xScale, yScale, wearRates]);
  const side1Line = useMemo(() => getLinesFor(xScale, yScale, wearRates, 'side1'), [xScale, yScale, wearRates]);
  const largestValueTexts = useMemo(() => getLargestValuesTexts(xScale, yScale, wearRates), [xScale, yScale, wearRates]);

  const graphLegends = useMemo(() => {
    if (!isDefined(sidesLegends)) {
      return undefined;
    }
    return [
      ...sidesLegends,
      {
        label: t('shared:common.average.abbreviated'),
        color: AVERAGE_LINE_COLOR,
        isSolidLine: false,
      },
    ];
  }, [t, sidesLegends]);

  return (
    <Graph
      ref={svgGraphAreaRef}
      graphHeightTotal={DEFAULT_GRAPH_HEIGHT_TOTAL}
      xScale={xScale}
      yScale={yScale}
      yAxisLabel={`↑${t('blastFurnaceRunner-report:campaignReport.graphWearRate.yAxisLabel')} [mm/t]}`}
      graphLegends={graphLegends}
      measurementsAtKilograms={measurementsAtKilograms}
      tooltipPanel={tooltipPanel}
    >
      {tooltipIndicator}
      {side0Line}
      {side1Line}
      {largestValueTexts}
      <SDashedLine
        key="wear-rate-average-line"
        from={{ x: 0, y: yScale(average) }}
        to={{ x: xScale.range()[1], y: yScale(average) }}
        stroke={AVERAGE_LINE_COLOR}
      />
    </Graph>
  );
};

const SLine = styled(Line)`
  stroke-width: 2;
  shape-rendering: crispedges;
  pointer-events: none;
`;

GraphWearRate.whyDidYouRender = true;
export default React.memo(GraphWearRate);
