import { settings } from '@rhim/design';
import { HeatIcon } from '@rhim/icons/16';
import { MeasurementIcon } from '@rhim/icons/24';
import { getFormattedDate, Tag } from '@rhim/react';
import { Tooltip } from '@rhim/react';
import { dateMeasurementDropdownOption, heatValueMeasurementDropdownOption, measurementsDropdownOptions, measurementTypeDropdownOption } from '@rhim/test-ids';
import { assert, isDefined } from '@rhim/utils';
import React, { useCallback } from 'react';
import { useHover } from 'react-aria';
import styled from 'styled-components';

import {
  getItemRowColors,
  HeatLineConnector,
  ItemRow,
  PrimaryInfo,
  PrimaryLabel,
  SecondaryInfo,
} from '../../pages/MeasurementView/MeasurementViewStrip/shared';
import { TimeAgoTextForTargetTimezone } from '../../pages/MeasurementView/utils';
import { useVesselLiningAndConditionLabel } from '../DataUploadPanel/QckLightPointCloudScanData/utils';
import { Mode } from './types';

interface Referable {
  ref?: React.Ref<HTMLTableRowElement>;
}

export interface DropdownMeasurement {
  id: string;
  heat: number;
  measurementTaken: string;
  notes?: string | null;
  vesselLining?: string;
  liningCondition?: string;
}

export function getHeatLabel(heat: number, mode: Mode) {
  return `${heat}${mode === 'tonnageMeasurement' ? 'kt' : ''}`;
}

interface Props {
  variant?: 'medium-48' | 'small-40';
  mode?: Mode;
  measurement: DropdownMeasurement;
  showMeasurementIcon?: boolean;
  showTimeAgo?: boolean;
  isEarlierMeasurementOfSameHeat: boolean;
  isLaterMeasurementOfSameHeat: boolean;
  isSelectedMeasurement: boolean;
  onMeasurementClicked: (measurementId: string) => void;
  customerTimezone: string;
}
const MeasurementSelectionItem = React.forwardRef<HTMLTableRowElement, Props & Referable>(function MeasurementSelectionItem(
  {
    variant = 'medium-48',
    mode = 'heatMeasurement',
    measurement,
    showMeasurementIcon = true,
    showTimeAgo = true,
    isEarlierMeasurementOfSameHeat,
    isLaterMeasurementOfSameHeat,
    isSelectedMeasurement,
    onMeasurementClicked,
    customerTimezone,
  },
  ref
) {
  const { hoverProps, isHovered } = useHover({});
  const handleMeasurementClicked = useCallback(() => {
    assert(isDefined(measurement.id), 'MeasurementSelectionItem, measurement id not set');
    onMeasurementClicked(measurement.id);
  }, [measurement, onMeasurementClicked]);

  const { rowPrimaryInfo } = getItemRowColors({ isSelected: isSelectedMeasurement, isHover: isHovered });
  const formattedTimestamp = isDefined(measurement.measurementTaken)
    ? getFormattedDate({
        datetime: measurement.measurementTaken as Date.ISO_8601,
        showTime: true,
      })
    : undefined;
  const tag = useVesselLiningAndConditionLabel(measurement.vesselLining, measurement.liningCondition);

  return (
    <Tooltip title={isDefined(measurement.notes) ? measurement.notes : undefined}>
      <ItemRow
        variant={variant}
        ref={ref}
        data-test-id={measurementsDropdownOptions}
        {...hoverProps}
        style={{ backgroundColor: rowPrimaryInfo.backgroundColor }}
        onClick={handleMeasurementClicked}
      >
        {showMeasurementIcon && (
          <td>
            <SPrimaryInfoMeasurementIcon style={rowPrimaryInfo}>
              <MeasurementIcon fill={rowPrimaryInfo.fill} />
            </SPrimaryInfoMeasurementIcon>
          </td>
        )}
        {mode === 'heatMeasurement' && (
          <td>
            <SPrimaryInfoHeatContainer style={rowPrimaryInfo} variant={variant}>
              <HeatIcon fill={rowPrimaryInfo.fill} />
              {isEarlierMeasurementOfSameHeat && <HeatLineConnector isConnectingAbove={true} />}
              {isLaterMeasurementOfSameHeat && <HeatLineConnector isConnectingAbove={false} />}
            </SPrimaryInfoHeatContainer>
          </td>
        )}
        <td>
          <PrimaryLabel data-test-id={heatValueMeasurementDropdownOption} style={rowPrimaryInfo}>
            {getHeatLabel(measurement.heat, mode)}
          </PrimaryLabel>
        </td>
        <SCell>
          <SecondaryInfo data-test-id={dateMeasurementDropdownOption}>{formattedTimestamp ?? <span>-</span>}</SecondaryInfo>
        </SCell>
        {showTimeAgo && (
          <SCell>
            <SecondaryInfo>
              {isDefined(measurement.measurementTaken) ? (
                <TimeAgoTextForTargetTimezone targetTimezone={customerTimezone} datetime={measurement.measurementTaken} />
              ) : (
                '-'
              )}
            </SecondaryInfo>
          </SCell>
        )}
        {isDefined(tag) && (
          <td>
            <SVesselStateTag
              data-test-id={measurementTypeDropdownOption}
              color={settings.colors.Primary.Grey_8}
              fill={settings.colors.Primary.Blue_2}
              size="small-16"
            >
              {tag}
            </SVesselStateTag>
          </td>
        )}
        <td>
          <SMarginRight />
        </td>
      </ItemRow>
    </Tooltip>
  );
});

export default React.memo(MeasurementSelectionItem);

const SVesselStateTag = styled(Tag)`
  margin-left: ${settings.Spacing.Spacing_150};
`;

const SPrimaryInfoMeasurementIcon = styled(PrimaryInfo)`
  margin-left: ${settings.Spacing.Spacing_200};
`;

const SPrimaryInfoHeatContainer = styled(PrimaryInfo)<{ variant: 'medium-48' | 'small-40' }>`
  margin-left: ${settings.Spacing.Spacing_150};
  position: relative;
  height: ${(props) => (props.variant === 'medium-48' ? 48 : 40)}px;
`;

const SCell = styled.td`
  width: 50%;
  padding-left: ${settings.Spacing.Spacing_150};
`;

const SMarginRight = styled.span`
  margin-right: ${settings.Spacing.Spacing_200};
`;
