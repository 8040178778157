import { settings } from '@rhim/design';
import { i18nReact } from '@rhim/i18n';
import { scaleLinear } from '@visx/scale';
import React, { useMemo } from 'react';

import { SSVGContainer } from '../../styles';
import { VERTICAL_SECTION_HORIZONTAL_MARGIN, VERTICAL_SECTIONS_CONTAINER_HORIZONTAL_MARGIN, VERTICAL_SECTIONS_COUNT } from './constants';

const GRAPH_HEIGHT = 22;
const LABELS_AREA_HEIGHT = 18;
const TOTAL_GRAPH_HEIGHT = GRAPH_HEIGHT + LABELS_AREA_HEIGHT;

const VALUE_MAX = 25;

interface Props {
  className?: string;
  verticalSectionWidth: number;
  position: 'top' | 'bottom';
}
const GraphBarCharts: React.ChildlessComponent<Props> = ({ className, verticalSectionWidth, position }) => {
  const { t } = i18nReact.useTranslation(['blastFurnaceRunner-report']);

  const yScale = useMemo(() => {
    return scaleLinear({
      range: [0, GRAPH_HEIGHT],
      domain: [0, VALUE_MAX],
    });
  }, []);

  const bars = useMemo(() => {
    const ret = [];
    // Graph label at its leftmost side
    const graphLabelY = position === 'top' ? GRAPH_HEIGHT + LABELS_AREA_HEIGHT / 2 : LABELS_AREA_HEIGHT / 2;
    ret.push(
      <text
        key="repairDeltaLabel"
        x={0}
        y={graphLabelY}
        textAnchor="end"
        alignmentBaseline="middle"
        fontFamily={settings.typography.FontFamily.Regular}
        fontSize={settings.typography.FontSize.X_Small}
        fill={settings.colors.Primary.Grey_6}
      >
        {`${t('blastFurnaceRunner-report:measurementReport.repairDelta')} [mm]`}
      </text>
    );
    for (let index = 0; index < VERTICAL_SECTIONS_COUNT; index++) {
      const centerX = index * verticalSectionWidth + verticalSectionWidth / 2;
      const width = verticalSectionWidth - 2 * VERTICAL_SECTION_HORIZONTAL_MARGIN;
      const value = Math.ceil(Math.random() * VALUE_MAX);
      const height = yScale(value);
      const y = position === 'top' ? GRAPH_HEIGHT - height : LABELS_AREA_HEIGHT;
      const fill = settings.colors.Primary.Blue_9;
      // Vertical bar
      ret.push(<rect key={`rect-${index}`} x={centerX - width / 2} y={y} width={width} height={height} fill={fill} />);
      // Vertical bar value label
      const labelY = position === 'top' ? TOTAL_GRAPH_HEIGHT - LABELS_AREA_HEIGHT / 2 : LABELS_AREA_HEIGHT / 2;
      ret.push(
        <text
          key={`value-${index}`}
          x={centerX}
          y={labelY}
          textAnchor="middle"
          dominantBaseline="middle"
          fontFamily={settings.typography.FontFamily.Regular}
          fontSize={settings.typography.FontSize.X_Small}
          fill={settings.colors.Primary.Grey_6}
        >
          {value}
        </text>
      );
    }
    return ret;
  }, [t, verticalSectionWidth, yScale, position]);

  return (
    <SSVGContainer height={TOTAL_GRAPH_HEIGHT} className={className}>
      <g transform={`translate(${VERTICAL_SECTIONS_CONTAINER_HORIZONTAL_MARGIN} 0)`}>{bars}</g>
    </SSVGContainer>
  );
};

GraphBarCharts.whyDidYouRender = true;
export default React.memo(GraphBarCharts);
