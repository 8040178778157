import { GetMeasurementsequenceMeasurementsVesselidCampaign200ResponseInner } from '@rhim/rest/fleetOverview';
import { createContext } from 'react';

export interface IBlastFurnaceRunnerReportContext {
  selectedMeasurement: undefined | GetMeasurementsequenceMeasurementsVesselidCampaign200ResponseInner;
}

const BlastFurnaceRunnerReportContext = createContext<IBlastFurnaceRunnerReportContext>({ selectedMeasurement: undefined });

export default BlastFurnaceRunnerReportContext;
