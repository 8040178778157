import { settings } from '@rhim/design';
import { Property } from 'csstype';
import React from 'react';

import { isDefined } from '../utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  size?: Property.Height;
  fill?: Property.Color;
  title?: string;
  role?: string;
}

export const PointcloudIcon: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ size, fill, title, role, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" role={role} {...rest}>
      {isDefined(title) ? <title>{title}</title> : null}
      <path
        fill={fill}
        fillRule="evenodd"
        d="M9 1C9 0.447715 8.55229 0 8 0C7.44772 0 7 0.447715 7 1L7 8C7 8.05561 7.00454 8.11017 7.01327 8.16331L0.599976 11.866C0.121683 12.1422 -0.0421923 12.7538 0.23395 13.2321C0.510092 13.7103 1.12168 13.8742 1.59998 13.5981L8.0141 9.89489L14.4282 13.5981C14.9065 13.8742 15.5181 13.7103 15.7942 13.2321C16.0704 12.7538 15.9065 12.1422 15.4282 11.866L8.98907 8.14838C8.99627 8.09997 9 8.05042 9 8V1ZM1 5C1.55228 5 2 4.55228 2 4C2 3.44772 1.55228 3 1 3C0.447715 3 0 3.44772 0 4C0 4.55228 0.447715 5 1 5ZM16 4C16 4.55228 15.5523 5 15 5C14.4477 5 14 4.55228 14 4C14 3.44772 14.4477 3 15 3C15.5523 3 16 3.44772 16 4ZM1 9C1.55228 9 2 8.55229 2 8C2 7.44772 1.55228 7 1 7C0.447715 7 0 7.44772 0 8C0 8.55229 0.447715 9 1 9ZM6 15C6 15.5523 5.55228 16 5 16C4.44772 16 4 15.5523 4 15C4 14.4477 4.44772 14 5 14C5.55228 14 6 14.4477 6 15ZM15 9C15.5523 9 16 8.55229 16 8C16 7.44772 15.5523 7 15 7C14.4477 7 14 7.44772 14 8C14 8.55229 14.4477 9 15 9ZM12 15C12 15.5523 11.5523 16 11 16C10.4477 16 10 15.5523 10 15C10 14.4477 10.4477 14 11 14C11.5523 14 12 14.4477 12 15ZM4 3C4.55228 3 5 2.55228 5 2C5 1.44772 4.55228 1 4 1C3.44772 1 3 1.44772 3 2C3 2.55228 3.44772 3 4 3ZM13 2C13 2.55228 12.5523 3 12 3C11.4477 3 11 2.55228 11 2C11 1.44772 11.4477 1 12 1C12.5523 1 13 1.44772 13 2ZM4 7C4.55228 7 5 6.55228 5 6C5 5.44772 4.55228 5 4 5C3.44772 5 3 5.44772 3 6C3 6.55228 3.44772 7 4 7ZM13 6C13 6.55228 12.5523 7 12 7C11.4477 7 11 6.55228 11 6C11 5.44772 11.4477 5 12 5C12.5523 5 13 5.44772 13 6ZM8 14C8.55229 14 9 13.5523 9 13C9 12.4477 8.55229 12 8 12C7.44772 12 7 12.4477 7 13C7 13.5523 7.44772 14 8 14Z"
      />
    </svg>
  );
};

PointcloudIcon.defaultProps = {
  size: '16',
  fill: settings.colors.Primary.Blue_9,
};
