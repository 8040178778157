import { Color4, Curve3, DynamicTexture, LinesMesh, Mesh, MeshBuilder, Scene, StandardMaterial, Tools, Vector3 } from '@babylonjs/core';
import { RHIMAPOReportingWearManagementApiV1ModelsOrientationLabelDto } from '@rhim/rest/wearManagement';
import { isDefined } from '@rhim/utils';

import { DEFAULT_3D_MODEL_ROTATION_AXIS_Y } from '../meshUtils';
import { getDefaultCameraRadius } from '../sceneUtils';

const COMPASS_SETUP = {
  LABELS_DYNAMIC_TEXTURE_SIZE: 2048,
  STROKE_WIDTH: (scene: Scene) => {
    const { min, max } = scene.getWorldExtends();
    // Adjust the stroke-width of the compass lines based on the camera's radius (distance from 3d mesh).
    // The furthest away is the camera from the 3d mesh, the bolder the lines should be to remain legible.
    return getDefaultCameraRadius(min, max) / 5;
  },
  STROKE_COLOR: new Color4(0, 0, 0, 1),
  DEGREE_LABEL_HEIGHT_PERCENTAGE: 0.07,
  ORIENTATION_LABEL_MARGIN_FROM_DEGREE_LABEL_PERCENTAGE: 0.01,
  ORIENTATION_LABEL_HEIGHT_PERCENTAGE: 0.1,
};

// Creates a circle shape as a 3d curve
export function create3dCompassCircle(scene: Scene, radius: number, y: number): LinesMesh {
  const firstArcPoint = new Vector3(radius, y, 0);
  const secondArcPoint = new Vector3(-radius, y, 0);
  const thirdArcPoint = new Vector3(0, y, radius);
  const arc = Curve3.ArcThru3Points(firstArcPoint, secondArcPoint, thirdArcPoint, 64, false, true);
  const circleLine = MeshBuilder.CreateLines('arc', { points: arc.getPoints() }, scene);
  circleLine.enableEdgesRendering();
  circleLine.edgesWidth = COMPASS_SETUP.STROKE_WIDTH(scene);
  circleLine.edgesColor = COMPASS_SETUP.STROKE_COLOR;

  return circleLine;
}

// Creates multiple 3d lines from the center of the compass to its perimeter at different angles
export function create3dCompassAngleLines(
  scene: Scene,
  orientationLabels: NonEmptyArray<RHIMAPOReportingWearManagementApiV1ModelsOrientationLabelDto>,
  lineLength: number,
  y: number
): NonEmptyArray<LinesMesh> {
  const linesMeshes = orientationLabels.map((orientationLabel) => {
    const angleRadians = -(Tools.ToRadians(orientationLabel.angle) + DEFAULT_3D_MODEL_ROTATION_AXIS_Y);
    const angleLinePoints = [new Vector3(0, y, 0), new Vector3(Math.cos(angleRadians) * lineLength, y, Math.sin(angleRadians) * lineLength)];
    const line = MeshBuilder.CreateLines('orientationCompassAngleLines', { points: angleLinePoints }, scene);
    line.enableEdgesRendering();
    line.edgesWidth = COMPASS_SETUP.STROKE_WIDTH(scene);
    line.edgesColor = COMPASS_SETUP.STROKE_COLOR;
    return line;
  });
  return linesMeshes;
}

// Creates a 3d plane with a dynamic texture on which we paint the angles & orientation-labels
export const create3dCompassPlane = (
  scene: Scene,
  orientationLabels: NonEmptyArray<RHIMAPOReportingWearManagementApiV1ModelsOrientationLabelDto>,
  degreeLinePaddingHeight: number,
  compassRadius: number,
  y: number
): Mesh => {
  const degreeLabelHeight = compassRadius * COMPASS_SETUP.DEGREE_LABEL_HEIGHT_PERCENTAGE;
  const orientationLabelMarginFromDegreeLabelHeight = compassRadius * COMPASS_SETUP.ORIENTATION_LABEL_MARGIN_FROM_DEGREE_LABEL_PERCENTAGE;
  const orientationLabelHeight = compassRadius * COMPASS_SETUP.ORIENTATION_LABEL_HEIGHT_PERCENTAGE;
  const compassPlaneRadius =
    compassRadius + 2 * degreeLinePaddingHeight + degreeLabelHeight + orientationLabelMarginFromDegreeLabelHeight + orientationLabelHeight;

  // Prepare a dynamic-texture on which we will paint the angle & orientation labels
  const labelsDynamicTexture = new DynamicTexture('orientationCompassDynamicTexture', COMPASS_SETUP.LABELS_DYNAMIC_TEXTURE_SIZE, scene);
  labelsDynamicTexture.hasAlpha = true;
  const labelsDynamicTextureCtx = labelsDynamicTexture.getContext() as CanvasRenderingContext2D;
  labelsDynamicTextureCtx.textAlign = 'center';
  labelsDynamicTextureCtx.textBaseline = 'top';

  const absoluteToDynamicTexture = (value: number) => {
    return (value * COMPASS_SETUP.LABELS_DYNAMIC_TEXTURE_SIZE) / (2 * compassPlaneRadius);
  };

  const getFontSizeThatFitsHeight = (worldHeight: number, textToFit: string, fontSize = 1): number => {
    const TEXT_PADDING_WEIGHT = 0.9;
    const worldHeightToPixels = absoluteToDynamicTexture(worldHeight) * TEXT_PADDING_WEIGHT;
    labelsDynamicTextureCtx.font = `${fontSize}px sans-serif`;
    const metrics = labelsDynamicTextureCtx.measureText(textToFit);
    const textHeight = metrics.fontBoundingBoxAscent + metrics.fontBoundingBoxDescent;
    if (textHeight < worldHeightToPixels) {
      return getFontSizeThatFitsHeight(worldHeight, textToFit, ++fontSize);
    }
    return fontSize - 1;
  };

  // Paints the angle labels (e.g "90" or "180") and the orientation-labels (e.g "TAPHOLE" or "CABIN") on the dynamic-texture
  const paintLabels = () => {
    const center = COMPASS_SETUP.LABELS_DYNAMIC_TEXTURE_SIZE / 2;

    const angleLabelDistanceFromCenter = compassRadius + 2 * degreeLinePaddingHeight;
    for (const orientationLabel of orientationLabels) {
      labelsDynamicTextureCtx.save();
      labelsDynamicTextureCtx.translate(center, center);
      labelsDynamicTextureCtx.rotate(Tools.ToRadians(orientationLabel.angle));

      // Paint angle label
      labelsDynamicTextureCtx.fillStyle = 'black';
      const angleLabel = `${orientationLabel.angle.toString()}°`;
      const fontSizeThatFits = getFontSizeThatFitsHeight(degreeLabelHeight, angleLabel);
      labelsDynamicTextureCtx.font = `${fontSizeThatFits}px sans-serif`;
      labelsDynamicTextureCtx.fillText(angleLabel, 0, absoluteToDynamicTexture(angleLabelDistanceFromCenter));

      // Paint orientation label
      if (isDefined(orientationLabel.displayText) && orientationLabel.displayText !== '') {
        const orientationLabelDistanceFromCenter =
          compassRadius + 2 * degreeLinePaddingHeight + degreeLabelHeight + orientationLabelMarginFromDegreeLabelHeight;
        const orientationLabelText = orientationLabel.displayText;
        const fontSizeThatFits = getFontSizeThatFitsHeight(orientationLabelHeight, orientationLabelText);
        labelsDynamicTextureCtx.font = `${fontSizeThatFits}px sans-serif`;
        labelsDynamicTextureCtx.fillText(orientationLabelText, 0, absoluteToDynamicTexture(orientationLabelDistanceFromCenter));
      }

      labelsDynamicTextureCtx.restore();
    }
  };

  // Clear the dynamic-texture
  labelsDynamicTextureCtx.fillStyle = 'transparent';
  labelsDynamicTextureCtx.fillRect(0, 0, COMPASS_SETUP.LABELS_DYNAMIC_TEXTURE_SIZE, COMPASS_SETUP.LABELS_DYNAMIC_TEXTURE_SIZE);
  // Paint angle & orientation labels on the dynamic-texture
  paintLabels();
  labelsDynamicTexture.update();

  // Create a new material and assign to it the dynamic-texture on which we painted the angle & orientation labels on
  const compassPlaneMaterial = new StandardMaterial('orientationCompassLabelsPlaneMaterial', scene);
  compassPlaneMaterial.disableLighting = true;
  compassPlaneMaterial.backFaceCulling = false;
  compassPlaneMaterial.diffuseTexture = labelsDynamicTexture;

  // Create a 3d plane and assign to it the material
  const planeSize = 2 * compassPlaneRadius;
  const compassPlane = MeshBuilder.CreatePlane('orientationCompassAngleLabelPlane', { width: planeSize, height: planeSize }, scene);
  compassPlane.position = new Vector3(0, y, 0);
  compassPlane.rotation = new Vector3(Math.PI / 2, 0, 0);
  compassPlane.material = compassPlaneMaterial;

  return compassPlane;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function throttle(func: any, timeout = 125) {
  let isBlocked = false;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (...args: any[]) => {
    if (!isBlocked) {
      func(...args); // only invoke the callback every $timeout miliseconds
      isBlocked = true;
      setTimeout(() => {
        isBlocked = false;
        func(...args); // invoke the callback to reflect the latest state event after $timeout miliseconds
      }, timeout);
    }
  };
}

/**
 * Debugging is easier when visualizing the base vectors of the scene
 * @param scene
 */
export function createBaseVectors(scene: Scene, origin = Vector3.Zero()): () => void {
  const edgeWidth = 2;
  // Create X axis
  const xAxis = MeshBuilder.CreateLines(
    'xAxis',
    {
      points: [origin, origin.add(new Vector3(1, 0, 0))],
      colors: [new Color4(1, 0, 0, 1), new Color4(1, 0, 0, 1)],
    },
    scene
  );
  xAxis.enableEdgesRendering();
  xAxis.edgesWidth = edgeWidth;
  xAxis.edgesColor = new Color4(1, 0, 0, 1);

  // Create Y axis
  const yAxis = MeshBuilder.CreateLines(
    'yAxis',
    {
      points: [origin, origin.add(new Vector3(0, 1, 0))],
      colors: [new Color4(0, 1, 0, 1), new Color4(0, 1, 0, 1)],
    },
    scene
  );
  yAxis.enableEdgesRendering();
  yAxis.edgesWidth = edgeWidth;
  yAxis.edgesColor = new Color4(0, 1, 0, 1);

  // Create Z axis
  const zAxis = MeshBuilder.CreateLines(
    'zAxis',
    {
      points: [origin, origin.add(new Vector3(0, 0, 1))],
      colors: [new Color4(0, 0, 1, 1), new Color4(0, 0, 1, 1)],
    },
    scene
  );
  zAxis.enableEdgesRendering();
  zAxis.edgesWidth = edgeWidth;
  zAxis.edgesColor = new Color4(0, 0, 1, 1);

  return () => {
    xAxis.dispose();
    yAxis.dispose();
    zAxis.dispose();
  };
}
