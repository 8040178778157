import { settings } from '@rhim/design';
import { RHIMFleetOverviewServiceV1ModelsCampaignDto } from '@rhim/rest/fleetOverview';
import { assert, isDefined } from '@rhim/utils';
import React from 'react';
import styled from 'styled-components';

import AppContext from '../../../app/AppContext';
import CampaignSelection, { ColorTheme } from '../../../components/CampaignSelectionDropdown/CampaignSelection';
import MeasurementSelection from '../../../components/MeasurementSelectionDropdown/MeasurementSelection';
import { Measurement } from '../../../components/MeasurementSelectionDropdown/types';
import VesselSelection from '../../../components/VesselSelectionDropdown/VesselSelection';
import { useCustomerDetails } from '../../../hooks';

interface Props {
  vessels: APO.VesselV2[];
  selectedVesselId: UUID | null;
  campaigns: undefined | RHIMFleetOverviewServiceV1ModelsCampaignDto[];
  selectedCampaignId: number;
  measurements: undefined | Measurement[];
  selectedMeasurementId: null | string;
  onVesselSelected: (vesselId: UUID) => void;
  onCampaignSelected: (campaign: number) => void;
  onMeasurementSelected: (measurementId: string) => void;
}
const MeasurementStrip: React.ChildlessComponent<Props> = ({
  vessels,
  selectedVesselId,
  campaigns,
  selectedCampaignId,
  measurements,
  selectedMeasurementId,
  onVesselSelected,
  onCampaignSelected,
  onMeasurementSelected,
}) => {
  const { selectedCustomer } = React.useContext(AppContext);
  const customerId = selectedCustomer.customerId as UUID;
  const { data: customerDetails } = useCustomerDetails(customerId);
  assert(isDefined(customerDetails), 'MeasurementStrip, in Suspense mode data (customerDetails) should always be defined, according to react-query docs.');
  assert(isDefined(customerDetails.timeZoneName), 'MeasurementStrip, timeZoneName not set in customerDetails');

  return (
    <SStripContainer>
      <VesselSelection vessels={vessels} isLoading={!isDefined(vessels)} selectedVesselId={selectedVesselId} onVesselSelected={onVesselSelected} />
      <CampaignSelection
        colorTheme={ColorTheme.Dark}
        isLoading={!isDefined(campaigns)}
        campaigns={campaigns}
        selectedCampaignId={selectedCampaignId}
        onCampaignSelected={(campaignNr: number | null) => {
          if (isDefined(campaignNr)) {
            onCampaignSelected(campaignNr);
          }
        }}
      />
      <MeasurementSelection
        mode="tonnageMeasurement"
        isLoading={!isDefined(measurements)}
        measurements={measurements}
        selectedMeasurementId={selectedMeasurementId}
        customerTimezone={customerDetails.timeZoneName}
        onMeasurementSelected={onMeasurementSelected}
      />
    </SStripContainer>
  );
};

const SStripContainer = styled.div`
  height: 48px;
  background-color: ${settings.colors.Primary.Grey_10};
  flex-shrink: 0;
  display: flex;
`;

MeasurementStrip.whyDidYouRender = true;
export default React.memo(MeasurementStrip);
