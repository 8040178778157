import { RHIMFleetOverviewServiceV1ControllersMeasurementSequenceDto } from '@rhim/rest/fleetOverview';
import { assert, isDefined, specific } from '@rhim/utils';
import { QueryFunctionContext, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { ExpressiveQueryKey } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';

import { API } from '../../../api/fleetOverview';
import { CAMPAIGN_ID_NOT_SET_ID } from '../../../hooks';

type Payload = RHIMFleetOverviewServiceV1ControllersMeasurementSequenceDto;

export function useMeasurementSequence(
  vesselId?: string,
  campaign?: number,
  options?: {
    configuration?: Omit<UseQueryOptions<Payload, unknown, Payload, ReturnType<typeof getKey>>, 'queryKey' | 'queryFn'>;
    axiosConfiguration?: AxiosRequestConfig;
  }
) {
  return useQuery(getKey(vesselId, campaign), (context) => queryFn(context, options?.axiosConfiguration), {
    ...options?.configuration,
    /**
     * Dependent query : to get the measurement-sequence of we have to have :
     * 1) a defined vesselId
     * 2) a defined campaign
     */
    enabled: isDefined(vesselId) && isDefined(campaign) && campaign !== CAMPAIGN_ID_NOT_SET_ID,
  });
}

const getKey = (vesselId?: string, campaign?: number) =>
  specific<ExpressiveQueryKey>()([
    {
      domain: 'measurementService',
      scope: 'vessel',
      entity: 'detail',
      vesselId,
      campaign,
    },
  ]);

const queryFn = ({ queryKey: [{ vesselId, campaign }], signal }: QueryFunctionContext<ReturnType<typeof getKey>>, axiosConfiguration?: AxiosRequestConfig) => {
  assert(isDefined(vesselId), 'This should be called only when `vesselId` is defined. See the `enabled` option of useMeasurementSequence');
  assert(isDefined(campaign), 'This should be called only when `campaign` is defined. See the `enabled` option of useMeasurementSequence');
  return API.measurementSequenceApi
    .getMeasurementsequenceVesselidCampaign(vesselId, campaign, { signal, ...axiosConfiguration })
    .then((response) => response.data);
};

useMeasurementSequence.getKey = getKey;
useMeasurementSequence.queryFn = queryFn;
